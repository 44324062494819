import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import WarehouseOrderQueueDialogContainer from './WarehouseOrderQueueDialogContainer';
import WarehouseScreen from './WarehouseScreen';
import { nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';

const SCREEN_ID = 'warehousescreen';
const PANEL_ID = 'warehouseOrdering';
class WarehouseScreenContainer extends Component {
	render() {
		return (
			<WarehouseScreen {...this.props}
				screenId={this.props.screenId || SCREEN_ID}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	const user = storeUtilities.getUser();
	const division = storeUtilities.getValue([screenId, 'division'], undefined);
	const warehouseSkuData = storeUtilities.getValue([screenId, 'warehouseSkuData'], undefined);
	const whsNum = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const queueCount = storeUtilities.getValue([screenId, panelId, 'QueueCount'], 0);
	
	const vendorValidationErrors = storeUtilities.getValidationErrors([screenId, 'VendorCode']);
	const warehouseValidationErrors = storeUtilities.getValidationErrors([screenId, 'whsNum']);
	const leadTimeValidationErrors = storeUtilities.getValidationErrors([screenId, 'leadtime']);
	const searchButtonDisabled = 
		(vendorValidationErrors !== undefined 
			&& vendorValidationErrors.errors !== undefined 
			&& Array.isArray(vendorValidationErrors.errors) 
			&& vendorValidationErrors.errors.length > 0) 
		|| (warehouseValidationErrors !== undefined 
			&& warehouseValidationErrors.errors !== undefined 
			&& warehouseValidationErrors.errors.length > 0) 
		|| (leadTimeValidationErrors !== undefined 
			&& leadTimeValidationErrors.errors !== undefined 
			&& leadTimeValidationErrors.errors.length > 0) ;
		
	return {
		user,
		division,
		warehouseSkuData,
		whsNum,
		queueCount,
		searchButtonDisabled
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	return {
		onLoad: () => {
			const user = storeUtilities.getUser();
			const args = { poUser: user.rtgID };
			dispatch(updateDefaultValuesAction([screenId], props.defaultValues));
			dispatch(rpcAction({
				args: args,
				nodeRoute: nodeRoutes.IFRServiceNET,
				method: 'GET',
				endpoint: '/Clearance/QueuedClearanceOrdersCount',
				showLoadingMask: false,
				callback: (data) => {
					if(data)
					{
						const queueCount = numberUtilities.getNumberOrDefault(data.count);
						storeUtilities.updateValue(dispatch, [screenId, panelId, 'QueueCount'], queueCount);
					}
				}
			}));
		},
		handleWarehouseChanged: (value) => {
			dispatch(rpcAction({
				args: { store: value }, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				endpoint: '/Clearance/Skus', 
				callback: (data) => {
					if(data && Array.isArray(data))
					{
						storeUtilities.updateValue(dispatch, [screenId, 'warehouseSkuData'], data);
					}
				}
			}));
		},
		handleDivisionChanged: () => {
			storeUtilities.updateValue(dispatch, [screenId, 'whsNum'], '');
		},
		handleSearchClicked: () => {
			const args = {
				WhsNum: storeUtilities.getValue([screenId, 'whsNum']),
				BeginSalesDate: moment().add(-30, 'd').format('YYYY-MM-DD'),
				EndSalesDate: moment().format('YYYY-MM-DD'),
				vendorlist: storeUtilities.getValue([screenId, 'VendorCode']).replace(/\s+/g, ''),
				LeadTime: storeUtilities.getValue([screenId, 'leadtime']),
				DaysToStock: '0',
				Threshold: '0',
				ProdTime: '0',
				TransitTime: '0'
			};

			dispatch(rpcAction({
				args: args,
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: '/Warehouse/Skus',
				method: 'GET',
				showLoadingMask: true,
				callback: (rowData) => {
					if(rowData && Array.isArray(rowData)){
						storeUtilities.updateValue(dispatch, [screenId, 'warehouseSkuData'], rowData);
					}
				}
			}));

		},
		viewPoOrderQueue: () => {
			dispatch(showDialogAction(
				<WarehouseOrderQueueDialogContainer
					screenId={screenId}
				/>, 
				500, 
				800, 
				()=>{},
				true,
				undefined,
				true,
				false));
		}, 
		addSku: () => {},
		queueStoreOrders: (gridRefs) => {
			const orderArray = [];
			const division = storeUtilities.getValue([screenId, 'division'], undefined);
			if(gridRefs !== undefined && Array.isArray(gridRefs) && gridRefs.length > 0){
				gridRefs.forEach((gridRef)=>{
					if(gridRef !== undefined && gridRef.current !== undefined){
						gridRef.current.api.forEachNode((node) => {
							const rowModel = sharedUtilities.getValueFromObject(node, ['data', 'ROWMODEL']);
							if(rowModel !== undefined){//value exists in path
								orderArray.push({
									sku: rowModel.SKU,
									qty: numberUtilities.getNumberOrDefault(rowModel.ORD, 0),
									whs: rowModel.WHS,
									div: division,
									vendor: rowModel.VENDOR
								});
							}
						});
					}
				});
			}
			if(orderArray.length > 0){
				const user = storeUtilities.getUser();
				dispatch(rpcAction({
					args: { orders: orderArray, poUser: user.rtgID },
					nodeRoute: nodeRoutes.IFRServiceNET,
					method: 'POST',
					endpoint: '/Clearance/QueueClearance',
					callback: () => {
						const args = { poUser: user.rtgID };
						dispatch(rpcAction({
							args: args,
							nodeRoute: nodeRoutes.IFRServiceNET,
							method: 'GET',
							endpoint: '/Clearance/QueuedClearanceOrdersCount',
							showLoadingMask: false,
							callback: (data) => {
								if (data) {
									const queueCount = numberUtilities.getNumberOrDefault(data.count);
									storeUtilities.updateValue(dispatch, [screenId, panelId, 'QueueCount'], queueCount);
								}
							}
						}))
					},
					showLoadingMask: true
				}))
			}
		}
	};
};

WarehouseScreenContainer.propTypes = {
	user: PropTypes.object,
	warehouseSkuData: PropTypes.array,
	whsNum: PropTypes.string,
	onLoad: PropTypes.func,
	queueCount: PropTypes.number,
	handleSearchClicked: PropTypes.func,
	searchButtonDisabled: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(WarehouseScreenContainer);

connectedComponent.defaultProps = {
	defaultValues: {
		leadtime:'90'
	}
};

export default connectedComponent;
