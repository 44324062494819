import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../../utilities/commonValidators';
import * as altSkuUtilities from './AltSkuUtilities';
import CheckBoxContainer from '../../../formelements/CheckBoxContainer';
import { 
	ButtonContainer,
	DataGridContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import withID from '../../../../higherordercomponents/WithID';
import store from '../../../../configureStore';
const LABEL_COLUMN_WIDTH = 160;
const VALUE_COLUMN_WIDTH = 100;

class SingleSkuAltSkuDialog extends Component { 
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	stopPropagation = (e) => {
		e.stopPropagation();
	};
	onLoadSkuClicked = () => {
		if(this.props.onLoadSkuClicked && this.props.altSkuIsValid){
			this.props.onLoadSkuClicked();
		}
	};
	onAltSkuChanged = (newValue) => {
		if(this.props.onAltSkuChanged){
			this.props.onAltSkuChanged(newValue);
		}
	};
	onOkClicked = () => {
		if(this.props.onOkClicked){
			this.props.onOkClicked();
		}
	};
	onCancelClicked = () => {
		if(this.props.onCancelClicked){
			this.props.onCancelClicked();
		}
	};
	onChkCombinationChanged = (value) => {
		if(this.props.onChkCombinationChanged){
			this.props.onChkCombinationChanged(value);
		}
	};
	onChkSubstituteChanged = (value) => {
		if(this.props.onChkSubstituteChanged){
			this.props.onChkSubstituteChanged(value);
		}
	};
	onRemoveAltSkuClicked = () => {
		if(this.props.onRemoveAltSkuClicked){
			this.props.onRemoveAltSkuClicked();
		}
	};
	render() {
		return (
			<Card className={'h-100'} id={this.props.id} data-testid={this.props.id}>
				<Card.Header className={'dlgdraggable'}>
					<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Alt-SKU</h5>
				</Card.Header>
				<Row className={'h-100 m-0 p-0'} noGutters={true}>
					<Col style={{borderRight:'1px solid gainsboro'}}>
						<Card className={'h-100'}>
							<Card.Header className={'d-flex justify-content-center'}>
								<h5>{'SKU: ' + this.props.orderLine.SKU}</h5>
							</Card.Header>
							<Row className={'h-100'}>
								<Col>
									<DataGridContainer 
										className={'h-100'}
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedSkuGrid'}
										columnDefs={[
											{
												headerName: 'Label',
												field: 'LABEL',
												colId: 'LABEL',
												width: LABEL_COLUMN_WIDTH
											},
											{
												headerName: 'Value',
												field: 'VALUE',
												colId: 'VALUE',
												type: 'numericColumn',
												width: VALUE_COLUMN_WIDTH
											}
										]}
										hideHeaders
										sizeColumnsToFit
										rowData={altSkuUtilities.getRowData(this.props.orderLine)}
										store={store}
									/>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col style={{borderRight:'1px solid gainsboro'}}>
						<Card className={'h-100'}>
							<Card.Header className={'d-flex justify-content-center'}>
								<Row className={'ml-1 mr-1 mt-0 mb-0 p-0'} noGutters={true}>
									<Col className={'pr-1'} xs={8} sm={8} md={8} lg={8} xl={8}>
										<TextBoxContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'altSkuTextBox'}
											overridePath={[...this.props.path, 'altSkuTextBox']}
											onEnterPressed={this.onLoadSkuClicked}
											height={(this.props.height - 5) + 'px'}
											tooltipPlacement={'left'}
											onChange={this.onAltSkuChanged}
											validators={[(args) => validators.required({...args, message:'SKU is required'}), (args) => validators.stringLengthIsValid({...args, message:'SKU length is invalid', stringLengthChecker: (value)=>{
												return value && value.trim && value.trim() !== '' && value.trim().length === 8;
											}})]}
											showRequiredAsterisk
											store={store} 
										/>				
									</Col>
									<Col xs={4} sm={4} md={4} lg={4} xl={4}>
										<ButtonContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'btnLoadSku'}
											overridePath={[...this.props.path, 'btnLoadSku']}
											text={'Load SKU'}
											mainComponentBootstrapSize={1}
											onClick={this.onLoadSkuClicked}
											disabled={!this.props.altSkuIsValid}
											store={store}
											fill/>
									</Col>
								</Row>
							</Card.Header>
							<Row className={'h-100'}>
								<Col>
									{
										this.props.altSkuIsValid && this.props.altOrderLine !== undefined
											? (
												<DataGridContainer 
													className={'h-100'}
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'altSkuGrid'}
													columnDefs={[
														{
															headerName: 'Label',
															field: 'LABEL',
															colId: 'LABEL',
															width: LABEL_COLUMN_WIDTH
														},
														{
															headerName: 'Value',
															field: 'VALUE',
															colId: 'VALUE',
															type: 'numericColumn',
															width: VALUE_COLUMN_WIDTH
														}
													]}
													hideHeaders
													sizeColumnsToFit
													noRowsMessage={''}
													rowData={altSkuUtilities.getRowData(this.props.altOrderLine)}
													store={store}
												/>
											) 
											: (
												<div className={'h-100 d-flex justify-content-center align-items-center'}>
													{this.props.altSkuIsValid ? 'Click Load SKU' : 'Enter a SKU and click Load SKU'}
												</div>
											)
									}	
								</Col>
							</Row>
						</Card>
					</Col>
					<Col>
						<Card className={'h-100'}>
							<Card.Header className={'d-flex justify-content-center'}>
								<Row className={'w-100 ml-1 mr-1 mt-0 mb-0 p-0'} noGutters={true}>
									<Col>
										<CheckBoxContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'chkSubstitute'}
											overridePath={[...this.props.path, 'chkSubstitute']}
											label={'Substitute'}
											mainComponentBootstrapSize={2}
											disabled={this.props.altOrderLine === undefined || !this.props.altSkuIsValid}
											onChange={this.onChkSubstituteChanged}
											style={{position:'relative', left: '-30px'}}
										/>
									</Col>
									<Col>
										<CheckBoxContainer
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'chkCombination'}
											overridePath={[...this.props.path, 'chkCombination']}
											label={'Combination'}
											mainComponentBootstrapSize={2}
											disabled={this.props.altOrderLine === undefined || !this.props.altSkuIsValid}
											onChange={this.onChkCombinationChanged}
											style={{position:'relative', left: '-17px'}}
											tooltips={this.props.chkCombinationTooltips}
										/>
									</Col>
								</Row>
							</Card.Header>
							<Row className={'h-100'}>
								<Col>
									{
										this.props.altSkuIsValid && this.props.altOrderLine !== undefined && (this.props.substituteChecked === true || this.props.combinationChecked === true)
											? (
												<DataGridContainer 
													className={'h-100'}
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'altSkuGrid'}
													columnDefs={[
														{
															headerName: 'Label',
															field: 'LABEL',
															colId: 'LABEL',
															width: LABEL_COLUMN_WIDTH
														},
														{
															headerName: 'Value',
															field: 'VALUE',
															colId: 'VALUE',
															type: 'numericColumn',
															width: VALUE_COLUMN_WIDTH
														}
													]}
													hideHeaders
													sizeColumnsToFit
													rowData={altSkuUtilities.getCalcRowData(this.props.orderLine, this.props.altOrderLine, this.props.substituteChecked, this.props.combinationChecked)}
													store={store}
												/>
											)
											: (<></>)
									}
								</Col>
							</Row>
							<Row className={'m-0 p-0'} style={{height:'62px'}}>
								<Col className={'m-0 p-0'}>
									{
										this.props.altSkuIsValid && this.props.altOrderLine !== undefined && (this.props.substituteChecked === true || this.props.combinationChecked === true)
											? (
												<DataGridContainer 
													className={'h-100'}
													screenId={this.props.screenId}
													panelId={this.props.panelId}
													fieldId={'resultsGrid'}
													columnDefs={[
														{
															headerName: 'Label',
															field: 'LABEL',
															colId: 'LABEL',
															width: LABEL_COLUMN_WIDTH
														},
														{
															headerName: 'Value',
															field: 'VALUE',
															colId: 'VALUE',
															type: 'numericColumn',
															width: VALUE_COLUMN_WIDTH
														}
													]}
													hideHeaders
													sizeColumnsToFit
													height={'62px'}
													rowData={altSkuUtilities.getResultRowData(this.props.orderLine, this.props.altOrderLine, this.props.substituteChecked, this.props.combinationChecked)}
													store={store}
												/>
											)
											: (<></>)
									}
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Card.Footer>
					<Row noGutters>
						<Col className={'p-0 mt-0 mb-0 ml-0 mr-1'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnCancel'} 
								text={'Cancel'} 
								className={'mb-2'}
								onClick={this.onCancelClicked}
								variant={'light'}
								disabled={this.props.okButtonDisabled}
								store={store}
								fill />
						</Col>
						<Col className={'p-0 mt-0 mb-0 ml-0 mr-1'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnRemoveAltSku'} 
								text={'Remove Alt SKU and Close'} 
								className={'mb-2'}
								onClick={this.onRemoveAltSkuClicked}
								variant={'light'}
								disabled={this.props.okButtonDisabled}
								store={store}
								fill />
						</Col>
						<Col className={'p-0 m-0'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnOK'} 
								text={'Accept Changes and Close'} 
								className={'mb-2'}
								onClick={this.onOkClicked}
								disabled={this.props.okButtonDisabled}
								store={store}
								fill />
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		);
	}
}
SingleSkuAltSkuDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onAltSkuChanged: PropTypes.func,
	validateAltSku: PropTypes.func,
	onLoadSkuClicked: PropTypes.func,
	orderLine: PropTypes.object.isRequired,
	altOrderLine: PropTypes.object,
	onSkuTypeChanged: PropTypes.func,
	onLoad: PropTypes.func,
	path: PropTypes.array.isRequired,
	onChkCombinationChanged: PropTypes.func,
	onChkSubstituteChanged: PropTypes.func,
	usePDMPieceCountInUse: PropTypes.bool,
	chkCombinationTooltips: PropTypes.array
};

export default withID(SingleSkuAltSkuDialog);
