import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { 
	ButtonContainer,
	//CheckBoxCellRendererContainer, 
	DataGridContainer,
	DatePickerContainer,
	//DeleteColumnHeader, 
	DropdownContainer,
	TextBoxContainer 
} from '@rtg/shared-react-components';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import * as validators from '../../../utilities/commonValidators';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';
class ClearanceOrderQueueDialog extends Component {
	constructor(props){
		super(props);
		this.gridApi = undefined;
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onGridReady = (params) => {
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
	};
	onModelUpdated = (params) => {
		params.api.sizeColumnsToFit();
	};
	onDeleteRowClicked = (gridApi, node, value) => {
		if(this.props.onDeleteRowClicked){
			this.props.onDeleteRowClicked(gridApi, node, value);
		}
	};
	handleDeleteClicked = () => {
		if(this.gridApi && this.props.handleDeleteClicked){
			this.props.handleDeleteClicked(this.gridApi);
		}
	};
	handleCloseClicked = () => {
		if(this.props.handleCloseClicked){
			this.props.handleCloseClicked();
		}
	};
	handleCreatePurchaseOrdersClicked = () => {
		if(this.gridApi && this.props.handleCreatePurchaseOrdersClicked){
			this.props.handleCreatePurchaseOrdersClicked(this.gridApi);
		}
	};
	onRowSelected = (e) => {
		if(this.props.onRowSelected){
			this.props.onRowSelected(e);
		}
	};
	onSelectAllClicked = () => {
		if(!this.gridApi){
			return;
		}
		this.gridApi.selectAll();
	};
	onDeselectAllClicked = () => {
		if(!this.gridApi){
			return;
		}
		this.gridApi.deselectAll();
	};
	handleKeyDown = (e) => {
		if(e.ctrlKey && e.which === 65){
			e.preventDefault();
			this.gridApi.selectAll();
		}
	};
	render() {
		const gridName = 'clearanceOrderQueueGrid';
		return (        
			<Card 
				className={'h-100 w-100'} 
				style={{width: this.props.width, height: this.props.height}}
				onKeyDown={this.handleKeyDown}>
				<Card.Header className={'dlgdraggable'}>
					<Row noGutters={true}>
						<Col>
							<h4>Order Queue</h4>
						</Col>
						<Col>
							&nbsp;
						</Col>
						<Col>
							<div className={'d-flex ml-auto'} style={{paddingLeft:'4px'}}>
								<div className={'w-100 mr-2'}>
									<ButtonContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'btnSelectAll'}
										text={'Select All'}
										onClick={this.onSelectAllClicked}
										fill
										store={store}
									/>
								</div>
								<div className={'w-100'}>
									<ButtonContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'btnDeselectAll'}
										text={'Clear Selections'}
										onClick={this.onDeselectAllClicked}
										fill
										store={store}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Header>
				<Container fluid={true} className={'h-100 w-100 m-0 p-0'}>
					<Row noGutters={true} className={'h-100 m-0 p-0'}>
						<Col xs={5} sm={5} md={5} lg={5} xl={5} className={'m-0 p-0'} style={{borderRight:'1px solid gainsboro'}}>
							<Row className={'mb-1 mr-0 ml-0 mt-2'}>
								<Col>
									<TextBoxContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'BuyerInitials']}
										fieldId={'BuyerInitials'}
										label={'Buyer Initials'}
										validators={[validators.required]} 
										showRequiredAsterisk
										persistValue
										mainComponentBootstrapSize={5}
										trimValue
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DropdownContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'Terms']}
										fieldId={'Terms'}
										label={'Terms'}
										options={[{value:'WIRE',name:'WIRE'},{value:'NET',name:'NET'}]}
										hasBlankOption
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DropdownContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ShipVia']}
										fieldId={'ShipVia'}
										label={'Ship Via'}
										options={[{value:'TRUCK',name:'TRUCK'},{value:'OCEAN',name:'OCEAN'},{value:'OTHER',name:'OTHER'}]}
										hasBlankOption
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ShipDate']}
										fieldId={'ShipDate'}
										label={'Ship Date'}
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<DatePickerContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'ArrivalDate']}
										fieldId={'ArrivalDate'}
										label={'Arrival Date'}
										validators={[validators.required]} 
										showRequiredAsterisk
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<CheckBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										overridePath={[this.props.screenId, this.props.panelId, 'SendToBvFaxQueue']}
										fieldId={'SendToBvFaxQueue'}
										label={'Send to BluVista Fax Queue'}
										mainComponentBootstrapSize={5}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<CheckBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'reqConfirmation'}
										overridePath={[this.props.screenId, this.props.panelId, 'reqConfirmation']}
										label={'Request Email Confirmation'}
										mainComponentBootstrapSize={5}
									/>
								</Col>
							</Row>
							<Row className={'mb-1 mr-0 ml-0 mt-0'}>
								<Col>
									<TextBoxContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'confirmationEmailAddress'}
										overridePath={[this.props.screenId, this.props.panelId, 'confirmationEmailAddress']}
										label={'Confirmation Email Address'}
										showRequiredAsterisk
										validators={[validators.required, validators.isValidEmail]}
										mainComponentBootstrapSize={5}
										store={store}
									/>
								</Col>
							</Row>
						</Col>
						<Col className={'m-0 p-0'} xs={7} sm={7} md={7} lg={7} xl={7}>
							<DataGridContainer
								fieldId={gridName} 
								panelId={this.props.panelId}
								screenId={this.props.screenId} 
								defaultColDef={{resizable:true}}
								columnDefs={[
									{
										headerName: 'SKU',
										field: 'sku',
										colId: 'sku',
										sortable: true,
										width: 65,
										minWidth: 65
									},
									{
										headerName: 'Vendor',
										field: 'vendor',
										colId: 'vendor',
										sortable: true,
										width: 40,
										minWidth: 40
									},
									{
										headerName: 'Store',
										field: 'storeId',
										colId: 'storeId',
										sortable: true,
										width: 50,
										minWidth: 50
									},
									{
										headerName: 'Order',
										field: 'quantity',
										colId: 'quantity',
										sortable: true,
										width: 40,
										minWidth: 40,
										type: 'numericColumn'
									}
								]}
								rowSelection={'multiple'}
								rowData={this.props.rowData}
								onGridReady={this.onGridReady}
								onModelUpdated={this.onModelUpdated}
								onRowSelected={this.onRowSelected}
								store={store}
							/>
						</Col>
					</Row>
				</Container>
				<Card.Footer>
					<Row noGutters={true}>
						<Col className={'pr-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnClose'} 
								text={'Close'} 
								variant={'light'} 
								onClick={this.handleCloseClicked}
								fill
								store={store}
							/>
						</Col>
						<Col className={'pr-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnDelete'}
								text={'Remove from Queue'}
								variant={'light'}
								onClick={this.handleDeleteClicked}
								fill
								store={store}
								disabled={this.props.removeFromQueueButtonDisabled === true}
							/>
						</Col>
						<Col>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'createPurchaseOrdersButton'}
								text={'Create POs'}
								disabled={this.props.createPurchaseOrdersButtonDisabled === true}
								onClick={this.handleCreatePurchaseOrdersClicked}
								fill 
								store={store}
							/>
						</Col>
					</Row>
				</Card.Footer>
			</Card>);
	}
}
ClearanceOrderQueueDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	handleCloseClicked: PropTypes.func,
	onDeleteRowClicked: PropTypes.func,
	handleDeleteClicked: PropTypes.func,
	handleCreatePurchaseOrdersClicked: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	onLoad: PropTypes.func
};
export default ClearanceOrderQueueDialog;