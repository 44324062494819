import { goBack } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { v4 as guid } from 'uuid';
import rpcAction from '../../../actions/rpcAction.jsx';
import closeDialogAction from '../../../actions/closeDialogAction.jsx';
import showDialogAction from '../../../actions/showDialogAction.jsx';
import updateGlobalAppStateValueAction from '../../../actions/updateGlobalAppStateValueAction.jsx';
import * as constants from '../../../constants.jsx';
import * as numberUtilities from '../../../utilities/numberUtilities.jsx';
import * as sharedUtilities from '../../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../../utilities/storeUtilities.jsx';
import * as orderUtilities from './OrderProductScreenUtilities.jsx';
import SuggestedPOsPanel from './SuggestedPOsPanel.jsx';
import { nodeRoutes } from '../../../constants';
import POsCreatedDialog from '../../dialogs/POsCreatedDialog.jsx';
const CREATE = 'createPOsJsonBody';
const QUEUE = 'PurchaseOrderQueue';

class SuggestedPOsPanelContainer extends Component {
	constructor(props){
		super(props);
		this.state = {windowSize: undefined};
	}
	render() {
		return (
			<Media queries={{
				xs: '(min-width: 0px)',
				sm: '(min-width: 576px)',
				md: '(min-width: 768px)',
				lg: '(min-width: 992px)',
				xl: '(min-width: 1200px)'
			}}>
				{ matches => {
					let viewPort = 'xl';
					if(matches.xs === true){
						viewPort = 'xs';
					}
					if(matches.sm === true){
						viewPort = 'sm';
					}
					if(matches.md === true){
						viewPort = 'md';
					}
					if(matches.lg === true){
						viewPort = 'lg';
					}
					if(matches.xl === true){
						viewPort = 'xl';
					}
					return (
						<SuggestedPOsPanel {...this.props}
							disablePanel={this.props.disableSuggestedPOsPanel}
							viewPort={viewPort}
							windowSize={this.state.windowSize}//only meant to trigger a new render
						/>
					);
				}}
			</Media>
		);
	}
}

const mapStateToProps = (state, props) => {
	const whsNum = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
	const poCount = Object.entries(storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'purchaseOrders', whsNum], {})).length;
	const disableSuggestedPOsPanel = storeUtilities.getValue([props.screenId, 'disableSuggestedPOsPanel'], true);
	const user = storeUtilities.getUser();
	const isReadOnlyUser = !user.hasAdminAccess() && !user.hasBuyerAccess() && user.hasReadOnlyAccess();

	const reqConfirmation = storeUtilities.getValue([props.screenId, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const failsValidation = 
	'ddlShipVia' in validationErrors
      || 'ddlTerms' in validationErrors
      || 'ddlPOFlag' in validationErrors
      || ('confirmationEmailAddress' in validationErrors && reqConfirmation === true)
	  || getSuggestedPOsErrors(props);
	const qtyCellsValidationErrorExists = storeUtilities.getValue([props.screenId, 'QTYCELLSVALIDATIONERROR'], false);
	const deletedPOsCount = orderUtilities.getDeletedPOsCount(props.screenId);
	const disable = poCount <= 0 || failsValidation === true || qtyCellsValidationErrorExists === true || isReadOnlyUser;
	const totalCost = storeUtilities.getValue([props.screenId, props.panelId, 'totalCost'], 0);
	return {
		whsNum,
		poCount,
		disableSuggestedPOsPanel: disableSuggestedPOsPanel,
		disableBtnCreatePOs: disable,
		disableConfirmationEmail: reqConfirmation === false,
		deletedPOsCount,
		totalCost
	};
};

const getSuggestedPOsErrors = (props) => {
	let errorsFound = false;
	const whs = storeUtilities.getValue([props.screenId, 'whsNumInUse']);
	const poArray = getPOArray(props.screenId);
	for(let i = 0; i < poArray.length; i++){
		const validationErrors = storeUtilities.getValidationErrors(['orderproductsscreen', 'SuggestedPOs', 'purchaseOrders', whs, i + 1]);
		const failsValidation = 'shipDate' in validationErrors || 'arrivalDate' in validationErrors;
		if(failsValidation === true){
			errorsFound = true;
			break;
		}
	}
	return errorsFound;
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			storeUtilities.updateValue(dispatch, [props.screenId, 'confirmationEmailAddress'], userEmail, true, false);
			const recipients=sharedUtilities.getRecipients(props.vendorEmailInfos);
			storeUtilities.updateValue(dispatch, [props.screenId, 'recipients'], recipients);
			orderUtilities.setFlowURLFromAppConfig(dispatch, [props.screenId, 'flowURL']);
			orderUtilities.setFlowAllURLFromAppConfig(dispatch, [props.screenId, 'flowAllURL']);
			orderUtilities.setValueFromAppConfig(dispatch, [props.screenId, 'showAdditionalTextListOfCountries'], nodeRoutes.ShowAdditionalTextListOfCountries, 'data');
		},
		createPurchaseOrders: () => {
			CreatePOs(dispatch, props.screenId, props.panelId, props.vendor, props.vendorEmailInfos, 'Are you sure you want to create these POs?', CREATE);
		},
		queuePurchaseOrders: () => {
			QueuePOs(dispatch, props.screenId, props.panelId, props.vendor, props.vendorEmailInfos, 'Are you sure you want to add these POs to the Purchase Order Queue?', QUEUE);
		},
		previewInFlow: () => {
			const OkCancelDialogContainer = React.lazy(() => import('../../dialogs/OkCancelDialogContainer.jsx'));
			const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah']);
			
			if(whsIsSavannah === true){
				dispatch(showDialogAction(
					<Suspense fallback={<div>Loading...</div>}>
						<OkCancelDialogContainer
							screenId={props.screenId + '_previewInFlowForSavannahComingSoon'}
							title={'INFO'}
							message={'Functionality Coming Soon!'}
							handleOkClicked={()=>{}}>
						PreviewInFlow has not been implemented for Savannah yet.
						</OkCancelDialogContainer>
					</Suspense>,
					200,
					325,
					() => { }));
				return;
			}
			const poArray = getPOArray(props.screenId);
			if(poArray === undefined){
				return;
			}
			const previewInFlowArray = [];
			for(let i = 0; i < poArray.length; i++){
				for(let j = 0; j < poArray[i].skus.length; j++){
					const o = {
						poNum: (i + 1).toString(),
						sku: poArray[i].skus[j].sku,
						whs: poArray[i].deliverTo === undefined ? '' : poArray[i].deliverTo.split('_')[1],
						div: props.divisions[0],
						buyerInitials: storeUtilities.getValue([props.screenId, 'txtBuyerInitials']),
						qty: poArray[i].skus[j].quantity,
						needByDate: moment(poArray[i].skus[j].NeedByDate).format('YYYY-MM-DD'),
						shipDate: moment(poArray[i].shipDate).format('YYYY-MM-DD'),
						arrivalDate: moment(poArray[i].arrivalDate).format('YYYY-MM-DD')
					};
					previewInFlowArray.push(o);
				}
			}
			dispatch(rpcAction({
				args: previewInFlowArray,
				nodeRoute: constants.nodeRoutes.IFRServiceNET,
				endpoint: 'PreviewFlow/SetFlowData',
				method: 'PUT',
				showLoadingMask: true,
				callback: (data)=>{
					if(data && data.scenarioID && data.scenarioID.length > 0){
						const flowURL = storeUtilities.getValue([props.screenId, 'flowURL']);
						window.open(flowURL + data.scenarioID, '_blank');
					}
				}
			}));
		},
		formatCurrency:(value) => {
			return sharedUtilities.formatCurrency(value);
		},
		onOrderClicked: () => {
			orderUtilities.showOnOrderDialog(dispatch, props.screenId, props.vendor);
		},
		onSuggestedPosTotalCostChange: (costDiff) => {
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost + costDiff, true);
		},
		checkAllQtyCells: (gridRefs) => {
			disableCreatePOsButtonIfNecessary(dispatch, props.screenId, gridRefs);
		}
	};
};
const QueuePOs = (dispatch, screenId, panelId, vendor, vendorEmailInfos, mainMessage, endpoint) => {
	const { useWarningMessage, headerMessage, warningMessages } = getWarningMessages(screenId, panelId);
	showPOsConfirmationDialog(dispatch, screenId, mainMessage, useWarningMessage ? warningMessages : [], headerMessage, () => { 
		callQueuePOs(dispatch, screenId, vendor, vendorEmailInfos, endpoint); 
	});
};
const CreatePOs = (dispatch, screenId, panelId, vendor, vendorEmailInfos, mainMessage) => {
	const { useWarningMessage, headerMessage, warningMessages } = getWarningMessages(screenId, panelId);
	showPOsConfirmationDialog(dispatch, screenId, mainMessage, useWarningMessage ? warningMessages : [], headerMessage, () => { 
		callCreatePOs(dispatch, screenId, vendor, vendorEmailInfos); 
	});
};
const showPOsConfirmationDialog = (dispatch, screenId, mainMessage, warningMessages, heading, callback) => {
	const OkCancelDialogContainer = React.lazy(() => import('../../dialogs/OkCancelDialogContainer.jsx'));
	const warningMessage = warningMessages.map ? <ul>{warningMessages.map((message, idx) => {
		return <li key={idx}><div>{message}</div></li>;
	})}</ul> : warningMessages;
	dispatch(showDialogAction(
		<Suspense fallback={<div>Loading...</div>}>
			<OkCancelDialogContainer
				screenId={screenId} 
				title={heading}
				message={mainMessage}
				handleOkClicked={()=>{
					dispatch(closeDialogAction());
					callback();
				}}
				hasCancelButton
				okButtonText={'Yes'}
				cancelButtonText={'No'}>
				<h5>{warningMessage}</h5>
			</OkCancelDialogContainer>
		</Suspense>,
		300, 
		500, 
		()=>{},
		true,
		undefined,
		true,
		false,
		true));
};
const getWarningMessages = (screenId, panelId) => {
	const warningsToRender = [];
	const discontinuedSkus = [];
	const inactiveSkus = [];
	const toBeDropped = [];

	const selectedRows = storeUtilities.getValue([screenId, 'selectedRows'], []);
	const usingCubes = orderUtilities.useCubes(screenId);
	const warehousePOs = storeUtilities.getValue([screenId, panelId, 'purchaseOrders'], {});
	const maxValue = usingCubes 
		? numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'poCube'], 0), 0, (x) => { return x >= 0; })
		: numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
	const checkPOFunction = usingCubes ? isOverMaxPOCubes : isOverMaxPOQty;
	const checkSKUsFunction = (po) => {
		po.rowData.forEach(row => {
			const matches = selectedRows.filter(x => x.sku === row.SKU);
			if(matches.length === 1){
				const selectedRow = matches[0];
				if(selectedRow.discontinuedSku === 'Y' && discontinuedSkus.indexOf(selectedRow.sku) === -1){
					discontinuedSkus.push(selectedRow.sku);
				}
				if(selectedRow.inactiveSku === 'Y' && inactiveSkus.indexOf(selectedRow.sku) === -1){
					inactiveSkus.push(selectedRow.sku);
				}
				if(selectedRow.toBeDropped === 'Y' && toBeDropped.indexOf(selectedRow.sku) === -1){
					toBeDropped.push(selectedRow.sku);
				}
			}
		});
	};
	Object.entries(warehousePOs).forEach(([, purchaseOrders]) => {
		Object.entries(purchaseOrders).forEach(([key, po]) => {
			if(!isNaN(key) && po !== undefined){
				checkSKUsFunction(po);
				const warning = checkPOFunction(po, maxValue);
				if(warning !== undefined && warningsToRender.indexOf(warning) === -1){
					warningsToRender.push(warning);
				}
			}
		});
	});
	if(discontinuedSkus.length > 0){
		const discontinuedSkusWarning = 'The following SKUs are DISCONTINUED: ' + discontinuedSkus.join(', ') + '.';
		if(warningsToRender.indexOf(discontinuedSkusWarning) === -1){
			warningsToRender.push(discontinuedSkusWarning);
		}
	}
	if(inactiveSkus.length > 0){
		const inactiveSkusWarning = 'The following SKUs are INACTIVE: ' + inactiveSkus.join(', ') + '.';
		if(warningsToRender.indexOf(inactiveSkusWarning) === -1){
			warningsToRender.push(inactiveSkusWarning);
		}
	}
	if(toBeDropped.length > 0){
		const toBeDroppedWarning = 'The following SKUs are set TO BE DROPPED: ' + toBeDropped.join(', ') + '.';
		if(warningsToRender.indexOf(toBeDroppedWarning) === -1){
			warningsToRender.push(toBeDroppedWarning);
		}
	}
	return {
		useWarningMessage: warningsToRender.length > 0,
		headerMessage: warningsToRender.length > 0 ? 'WARNING!' : 'Confirmation',
		warningMessages: warningsToRender
	};
};
const isOverMaxPOCubes = (po, maxValue) => {
	if(po.cubes !== undefined && po.deleted !== true){
		const cubes = numberUtilities.getNumberOrDefault(po.cubes, 0);
		return cubes > maxValue ? 'One or more Purchase Orders exceed the PO Cube value set on the Merchandise panel.' : undefined;
	}
};
const isOverMaxPOQty = (po, maxValue) => {
	if(po.rowData !== undefined){
		let qtySum = 0;
		Object.entries(po.rowData).forEach(([rowKey, row]) => {
			if(!isNaN(rowKey) && row.QTY !== undefined){
				qtySum += numberUtilities.getNumberOrDefault(row.QTY, 0);
			}
		});
		return qtySum > maxValue ? 'One or more Purchase Orders exceed the Max PO Quantity value set on the Merchandise panel.' : undefined;
	}
};
/**
 * constuct PO array to pass to other to other systems
 * @param {string} screenId - Screen ID where this component will appear
 */
const getPOArray = (screenId) =>{
	const whs = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const pos = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], 0);
	const poCount = Object.values(pos).length;
	const poArray = [];
	let requestedPONumber = 1;
	for(let poNum = 1; poNum <= poCount; poNum++){
		const deleted = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'deleted'], true);
		if(deleted){  
			continue;  
		}
		const rowData = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'rowData'], []);
		if(!Array.isArray(rowData) || rowData.length === 0){
			continue;
		}
		let skusArray = [];
		let rowIdx = 0;
		for(rowIdx = 0; rowIdx < rowData.length; rowIdx++){
			const qty = parseInt(rowData[rowIdx].QTY);
			if(isNaN(qty) || qty <= 0){
				continue;
			}
			const m = moment(rowData[rowIdx].NEEDBYDATE, constants.cfQueryDateFormat);
			const needByDate = m.format('L');
			if(needByDate && needByDate.toLowerCase && needByDate.toLowerCase() === 'invalid date'){
				sharedUtilities.showError('Invalid date', 'Needed By Date, ' + rowData[rowIdx].NEEDBYDATE + ', is not in the expected format. Expected Format: ' + constants.cfQueryDateFormat);
				return undefined;
			}
			const skuObj = {
				sku: rowData[rowIdx].SKU,
				quantity: qty,
				description: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.VendorDescription : '',
				altQty: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.CalcOrder : 0,
				orderQty: rowData[rowIdx].QTY,
				cube: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Cube : 0,
				AverageDailySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyMean : 0,
				WeeklySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeklySales : 0,
				TotalSales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.TotalSales : 0,
				qoh: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVQOH : 0,
				inbound: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				available: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.LTAVAIL : 0,
				WeeksOfStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeksOfStock : 0,
				StandardDeviation: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyStdDev : 0,
				SafetyStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.SafetyStock : 0,
				ReplenishmentStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ReplenishmentStock : 0,
				OrderPoint: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Trigger : 0,
				WeeksOfStockOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WOSOH : 0,
				AvailableToday: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				QtyToWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyToWHS : 0,
				QtyFromWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyFromWHS : 0,
				StoreQuantityOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.STOREQTY : 0,
				PieceCount: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PieceCount : 0,
				AvailableAfterOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.AvailableAfterOrder : 0,
				OrderRatio: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Ratio : 0,
				Intradivisional: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.IntraDivisional : 0,
				DaysToNextOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DaysToNextOrder : 0,
				PromoAvail: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PromoAvail : 0,
				NeedByDate: needByDate,
				FirstCost: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.FIRSTCOST : 0,
				UserDaysToNextOrder: 0,
				Alias: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ALIAS : '',
				Color: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Color : '',
				Factory: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.FACTORY : ''
			};
			skusArray.push(skuObj);
		}
		const shipDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'shipDate'], new Date())).format('L');
		const arrivalDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'arrivalDate'], new Date())).format('L');
		const deliverTo = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'deliverTo']);
		const skusObj = {
			skus: skusArray,
			instructions: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'instructions'], ''), 
			shipDate: shipDate,
			arrivalDate: arrivalDate,
			shipVia: storeUtilities.getValue([screenId, 'ddlShipVia']),
			terms: storeUtilities.getValue([screenId, 'ddlTerms']),
			fob: rowData[0].ORDERLINE.FOB ? rowData[0].ORDERLINE.FOB : '',
			calcArrivalDate: arrivalDate,
			poFlag: storeUtilities.getValue([screenId, 'ddlPOFlag'], false),
			deliverTo,
			requestedPONumber,
			overAllocation: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'overAllocatedWarning'], false)
		};
		poArray.push(skusObj);
		requestedPONumber++;
	}
	return poArray;
};

/**
 * constuct PO array to pass to other to other systems  
 * @param {string} screenId - Screen ID where this component will appear
 */
const buildPOArray = (screenId) => {
	const whs = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const pos = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], 0);
	const poCount = Object.values(pos).length;
	const poArray = [];
	const selectedSKUs = storeUtilities.getValue([screenId, 'selectedRows'], []);
	
	const showAdditionalTextListOfCountriesTemp = storeUtilities.getValue([screenId, 'showAdditionalTextListOfCountries'], []);
	const showAdditionalTextListOfCountries = showAdditionalTextListOfCountriesTemp.split(',');
	let showAdditionalText = false;
	if (sharedUtilities.indexOfCaseInsensitive(showAdditionalTextListOfCountries, selectedSKUs[0].country) >= 0) {
		showAdditionalText = true;
	}

	for(let poNum = 1; poNum <= poCount; poNum++){
		const deleted = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'deleted'], true);
		if(deleted){  
			continue;  
		}
		const rowData = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'rowData'], []);
		if(!Array.isArray(rowData) || rowData.length === 0){
			continue;
		}
		let skusArray = [];
		let rowIdx = 0;
		for(rowIdx = 0; rowIdx < rowData.length; rowIdx++){
			const qty = parseInt(rowData[rowIdx].QTY);
			if(isNaN(qty) || qty <= 0){
				continue;
			}
			const m = moment(rowData[rowIdx].NEEDBYDATE, constants.cfQueryDateFormat);
			const needByDate = m.format('YYYY-MM-DD');
			if(needByDate && needByDate.toLowerCase && needByDate.toLowerCase() === 'invalid date'){
				sharedUtilities.showError('Invalid date', 'Needed By Date, ' + rowData[rowIdx].NEEDBYDATE + ', is not in the expected format. Expected Format: ' + constants.cfQueryDateFormat);
				return undefined;
			}
			const selectedSKU = selectedSKUs.find((sku) => sku.SKU = rowData[rowIdx].SKU) ? selectedSKUs.find((sku) => sku.SKU = rowData[rowIdx].SKU) : '';
			const skuObj = {
				sku: rowData[rowIdx].SKU,
				quantity: qty,
				alternateQuantity: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.CalcOrder : 0,
				orderQuantity: rowData[rowIdx].QTY,
				cube: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Cube : 0,
				averageDailySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyMean : 0,
				weeklySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeklySales : 0,
				totalSales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.TotalSales : 0,
				quantityOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVQOH : 0,
				inbound: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				available: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.LTAVAIL : 0,
				weeksOfStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeksOfStock : 0,
				standardDeviation: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyStdDev : 0,
				safetyStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.SafetyStock : 0,
				replenishmentStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ReplenishmentStock : 0,
				orderPoint: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Trigger : 0,
				weeksOfStockOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WOSOH : 0,
				availableToday: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				quantityToWarehouse: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyToWHS : 0,
				quantityFromWarehouse: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyFromWHS : 0,
				storeQuantityOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.STOREQTY : 0,
				pieceCount: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PieceCount : 0,
				availableAfterOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.AvailableAfterOrder : 0,
				orderRatio: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Ratio : 0,
				intradivisional: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.IntraDivisional : 0,
				daysToNextOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DaysToNextOrder : 0,
				promotionAvailable: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PromoAvail : 0,
				style: selectedSKU.style,
				needByDate: needByDate,
				description: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.VendorDescription : '',
				color: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Color : '',
				finish: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Finish : '',
				price: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.FIRSTCOST : 0
			};
			skusArray.push(skuObj);
		}
		const shipDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'shipDate'], new Date())).format('YYYY-MM-DD');
		const arrivalDate = moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'arrivalDate'], new Date())).format('YYYY-MM-DD');
		const whsArray = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'deliverTo']).split('_');
		const skusObj = {
			shipDate: shipDate,
			arrivalDate: arrivalDate,
			calculatedArrivalDate: arrivalDate,
			poCube: 0,
			status: '',
			overAllocation: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'overAllocatedWarning'], false),
			destinationDivision: whsArray[0],
			destinationWarehouseId: whsArray[1],
			freightOnBoard: rowData[0].ORDERLINE.FOB ? rowData[0].ORDERLINE.FOB : '',
			instructions: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs, poNum, 'instructions'], ''),
			totalPurchaseOrderValue: 0,
			showAdditionalText: showAdditionalText,
			skus: skusArray
		};
		poArray.push(skusObj);
	}
	return poArray;
};

const getPOArgs = (screenId, vendor, vendorEmailInfos, poArray) => {
	const args = {};
	const user = storeUtilities.getUser();
	const whsArray = storeUtilities.getValue([screenId, 'whsNum'], undefined).split('_');
	args.buyerInitials = storeUtilities.getValue([screenId, 'txtBuyerInitials']);
	args.confirmationRequested = storeUtilities.getValue([screenId, 'reqConfirmation'], false);
	args.daysToStock = storeUtilities.getValue([screenId, 'daysToStock'], 0);
	args.destinationDivision = whsArray[0];
	args.destinationWarehouseId = whsArray[1];
	args.emailAddress = sharedUtilities.getRecipients(vendorEmailInfos);
	args.confirmationEmail = storeUtilities.getValue([screenId, 'confirmationEmailAddress']);
	args.salesBeginDate = moment(storeUtilities.getValue([screenId, 'beginSalesDate'], moment())).format('YYYY-MM-DD');
	args.salesEndDate = moment(storeUtilities.getValue([screenId, 'endSalesDate'], moment())).format('YYYY-MM-DD');
	args.systemUser = user.rtgID;
	args.buyerName = user.fullName;
	args.requestType = constants.RequestTypes.PRODUCT;
	args.group = storeUtilities.getValue([screenId, 'merchGroup']);
	args.leadTime = storeUtilities.getValue([screenId, 'txtTotalDays'], 0);
	args.marketAdjustment = storeUtilities.getValue([screenId, 'marketAdjustment'], '');
	args.maxCube = storeUtilities.getValue([screenId, 'maximizeCube'], false);
	args.numberOfSalesDays = storeUtilities.getValue([screenId, 'salesDays'], 0);
	args.productionLeadTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	args.sendAsFax = storeUtilities.getValue([screenId, 'sendToFaxQueue'], false);
	args.showPromotionAvailable = storeUtilities.getValue([screenId, 'showPromoAvail'], false);
	args.transportationLeadTime = storeUtilities.getValue([screenId, 'transitTime'], 0);
	args.userDaysToNextOrder = storeUtilities.getValue([screenId, 'daysToNextOrder'], 0);
	args.vendor = vendor;
	args.isQueued = false;
	args.terms = storeUtilities.getValue([screenId, 'ddlTerms']);
	args.shipVia = storeUtilities.getValue([screenId, 'ddlShipVia']);
	args.flag = storeUtilities.getValue([screenId, 'ddlPOFlag'], '');
	args.comboWharehouse= false;
	args.warehouses = storeUtilities.getValue([screenId, 'combineWhsList'], [whsArray[1]]);
	args.purchaseOrders = poArray;

	return args;
};
const buildPOArgs = (screenId, vendor, vendorEmailInfos) => {
	const requestedPOArray = [];
	requestedPOArray.push(...buildPOArray(screenId));
	return getPOArgs(screenId, vendor, vendorEmailInfos, requestedPOArray);
};
const getQueuePOArgs = (screenId, vendor, vendorEmailInfos, args, requestedPOArray) => {
	const whs = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const pos = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], 0);
	const combineWhsList = storeUtilities.getValue([screenId, 'combineWhsList'], [whs]);
	const strCombineWhsList = combineWhsList.join ? combineWhsList.join(',') : '';
	const rtgID = storeUtilities.getUser().rtgID;
	let requestedPONumber = 1;
	const batchKey = guid();
	Object.entries(pos).forEach(([, value]) => {
		if (value.deleted !== true) {
			const fob = value.rowData[0].ORDERLINE.FOB ? value.rowData[0].ORDERLINE.FOB : '';
			const arg = {
				arrivalDate:  value.arrivalDate,
				batchKey,
				beginSalesDate: moment(storeUtilities.getValue([screenId, 'beginSalesDate'], moment())).toDate(),
				buyerInitials: storeUtilities.getValue([screenId, 'txtBuyerInitials'], ''),
				combineWhsList: strCombineWhsList,
				confirmationEmail: storeUtilities.getValue([screenId, 'confirmationEmailAddress'], ''),
				dateQueuedUTC:  moment.utc().toDate(),
				daysToStock: storeUtilities.getValue([screenId, 'daysToStock'], 0),
				deliverTo: value.deliverTo,
				emailAddress: storeUtilities.getValue([screenId, 'confirmationEmailAddress'], ''),
				emailRecipients: sharedUtilities.getRecipients(vendorEmailInfos),
				endSalesDate: moment(storeUtilities.getValue([screenId, 'endSalesDate'], moment())).toDate(),
				fob,
				grouping: storeUtilities.getValue([screenId, 'merchGroup'], ''),
				includeIntradivisional: storeUtilities.getValue([screenId, 'intraOrder'], false),
				instructions: value.instructions,
				leadTime: storeUtilities.getValue([screenId, 'txtTotalDays'], 0),
				marketAdj: storeUtilities.getValue([screenId, 'marketAdjustment'], 0),
				maxCube: storeUtilities.getValue([screenId, 'maximizeCube'], false),
				numSalesDays: storeUtilities.getValue([screenId, 'salesDays'], 0),
				orderDate: moment(storeUtilities.getValue([screenId, 'orderDate'], moment())).toDate(),
				poCube: storeUtilities.getValue([screenId, 'poCube'], 0),
				poFlag: storeUtilities.getValue([screenId, 'ddlPOFlag'], ''),
				prodLeadTime: storeUtilities.getValue([screenId, 'prodTime'], 0),
				reqConfirmation: storeUtilities.getValue([screenId, 'reqConfirmation'], false),
				requestedPONumber: requestedPONumber,
				rtgID,
				sendToFaxQueue: storeUtilities.getValue([screenId, 'sendToFaxQueue'], false),
				shipDate: value.shipDate,
				shipVia: storeUtilities.getValue([screenId, 'ddlShipVia'], ''),
				showPromoAvail: storeUtilities.getValue([screenId, 'showPromoAvail'], false),
				terms: storeUtilities.getValue([screenId, 'ddlTerms'], ''),
				transLeadTime: storeUtilities.getValue([screenId, 'transitTime'], 0),
				userDTNO: storeUtilities.getValue([screenId, 'daysToNextOrder'], 0),
				vendor,
				purchaseOrderQueueSkus: []
			};
			requestedPOArray.push({REQUESTEDPONUMBER: requestedPONumber});
			requestedPONumber++;
			if(Array.isArray(value.rowData)){
				value.rowData.forEach(row => {
					const ol = row.ORDERLINE;
					const sku = {
						orderQty: row.QTY,
						quantity: row.QTY,
						needByDate: moment(row.NEEDBYDATE, constants.cfQueryDateFormat).toDate(),
						
						alias: ol.ALIAS,
						altQty: ol.CalcOrder,
						available: ol.BVAVAIL,
						availableAfterOrder: ol.AvailableAfterOrder,
						availableToday: ol.BVINBOUND,
						averageDailySales: ol.DailyMean,
						color: ol.Color,
						cube: ol.Cube,
						daysToNextOrder: ol.DaysToNextOrder,
						description: ol.VendorDescription,
						factory: ol.FACTORY,
						firstCost: ol.FIRSTCOST,
						inbound: ol.BVINBOUND,
						intraDivisional: ol.IntraDivisional,
						orderPoint: ol.Trigger,
						orderRatio: ol.Ratio,
						pieceCount: ol.PieceCount,
						promoAvail: ol.PromoAvail,
						qoh: ol.QOH,
						qtyFromWHS: ol.QtyFromWHS,
						qtyToWHS: ol.QtyToWHS,
						replenishmentStock: ol.ReplenishmentStock,
						safetyStock: ol.SafetyStock,
						sku: ol.SKU,
						standardDeviation: ol.DailyMean,
						storeQuantityOnHand: ol.STOREQTY,
						totalSales: ol.TotalSales,
						weeklySales: ol.WeeklySales,
						weeksOfStock: ol.WeeksOfStock,
						weeksOfStockOnHand: ol.WOSOH
					};
					arg.purchaseOrderQueueSkus.push(sku);
				});
			}
			args.push(arg);
		}
	});
};
const callQueuePOs = (dispatch, screenId, vendor, vendorEmailInfos, endpoint) =>{
	let args = [];
	const requestedPOArray = [];
	getQueuePOArgs(screenId, vendor, vendorEmailInfos, args, requestedPOArray);
	dispatch(rpcAction({
		args,
		nodeRoute: constants.nodeRoutes.IFRServiceNET,
		endpoint,
		method: 'POST',
		hideLoadingMaskOnComplete: false,
		callback: (data) => {
			const identifier = 'PO Queue#';
			const modifiedData = data.map(x => ({
				SUCCESS: x.success,
				PONUMBER: x.queueId,
				REQUESTEDPONUMBER: x.requestedPONumber,
				WAREHOUSE: x.warehouse
			}));
			const e = orderUtilities.getCreatePOResponseDialogElements(modifiedData, identifier, 'Sent To PO Queue');
			let hasErrors = e.unsuccessfulJSXElements.length > 0;
			if(Array.isArray(modifiedData) && modifiedData.length > 0){
				const posNotCreated = [];
				requestedPOArray.forEach(po => {
					const uppercasedPOKeys = sharedUtilities.uppercaseObjectKeys(po);
					const createdPO = modifiedData.filter(row => {
						const upperCaseRowKeys = sharedUtilities.uppercaseObjectKeys(row);
						return parseInt(upperCaseRowKeys['REQUESTEDPONUMBER']) === uppercasedPOKeys.REQUESTEDPONUMBER;
					});
					if(createdPO.length === 0){
						hasErrors = true;
						posNotCreated.push(identifier + ' ' + uppercasedPOKeys.REQUESTEDPONUMBER);
					}
				});
				if(e.successfulJSXElements.length > 0){
					e.successfulJSXElements.push(<br key={e.successfulJSXElements.length + 1} />);
				}
				if(e.unsuccessfulJSXElements.length > 0){
					e.unsuccessfulJSXElements.push(<br key={e.unsuccessfulJSXElements.length + 1} />);
				}
				window.setTimeout(() => {
					const OkCancelDialogContainer = React.lazy(() => import('../../dialogs/OkCancelDialogContainer.jsx'));
					dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
					dispatch(showDialogAction(
						<Suspense fallback={<div>Loading...</div>}>
							<OkCancelDialogContainer 
								id={'purchaseOrdersCreatedDialog'}
								screenId={ screenId + '_informationDialog'} 
								title={'Information'}
								handleOkClicked={()=>{ 
									dispatch(goBack());
									storeUtilities.deleteValue(dispatch, [screenId]);
								}}
								hasCancelButton={hasErrors}
								okButtonText={'Return To Search'}
								cancelButtonText={'Close Dialog and Stay'}>
								<div>
									{e.successfulJSXElements}
									{e.unsuccessfulJSXElements}
									{posNotCreated.length > 0 ? ('The following Suggested PO\'s were not created successfully: ' + posNotCreated.join(', ')): ''}
								</div>
							</OkCancelDialogContainer>
						</Suspense>, 
						350, 
						550, 
						()=>{}));
				}, 500);
			}
			else {
				if(data !== undefined){
					sharedUtilities.showError(data.error ? data.error : 'An error has occured', data.detail ? data.detail : '', 'Error Creating Purchase Orders');
				}
			}
		},
		retryOnFailure: true
	}));
};

const callCreatePOs = (dispatch, screenId, vendor, vendorEmailInfos) =>{
	const args = buildPOArgs(screenId, vendor, vendorEmailInfos);
	dispatch(rpcAction({
		args: args,
		nodeRoute: constants.nodeRoutes.IFRServiceNET,
		endpoint: 'PurchaseOrdersRequest/Create',
		method: 'POST',
		callback: (response) => {
			const flatResponse = sharedUtilities.flattenResponse(response);
			dispatch(showDialogAction(
				<POsCreatedDialog 
					screenId={screenId}
					panelId={'SuggestedPOsPanel'}
					fieldId={'POsCreatedDialog'}
					rowData={flatResponse}
					okButtonText={'Return to Search'}
					handleOkClicked={() => {
						dispatch(goBack());
						storeUtilities.deleteValue(dispatch, [screenId]);
					}}
				/>, 
				350, 
				550, 
				()=>{}));
		},
		retryOnFailure: false
	}));
};
const disableCreatePOsButtonIfNecessary = (dispatch, screenId, gridRefs) => {
	let disable = false;
	const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
	const usingCubes = orderUtilities.useCubes(screenId);
	const usePDMPieceCountInUse = storeUtilities.getValue([screenId, 'usePDMPieceCountInUse'], false);
	if(Array.isArray(gridRefs)){
		gridRefs.forEach(gridRef => {
			const qtySum = orderUtilities.getQtySum(gridRef.api);
			disable = disable || (usingCubes === false ? (qtySum > maxPO) : false);
			gridRef.api.forEachNode(node => {
				const pieceCount = usePDMPieceCountInUse === true ? node.data.ORDERLINE.PDMPieceCount : node.data.ORDERLINE.PieceCount;
				disable = disable || (pieceCount > 0 && node.data.QTY % pieceCount !== 0);
			});
		});
	}
	storeUtilities.updateValue(dispatch, [screenId, 'QTYCELLSVALIDATIONERROR'], disable);
};

SuggestedPOsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	vendor: PropTypes.string.isRequired,
	whsNum: PropTypes.string,
	disableSuggestedPOsPanel: PropTypes.bool,
	createPurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	vendorEmailInfos: PropTypes.arrayOf(PropTypes.object),
	vendorEmail: PropTypes.string,
	previewInFlow: PropTypes.func,
	formatCurrencty: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	userEmail: PropTypes.string,
	onLoad: PropTypes.func,
	totalCost: PropTypes.number,
	whsList: PropTypes.array,
	totalPOsCount: PropTypes.number,
	deletedPOsCount: PropTypes.number,
	onOrderClicked: PropTypes.func,
	onSuggestedPosTotalCostChange:  PropTypes.func.isRequired,
	checkAllQtyCells: PropTypes.func.isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOsPanelContainer);

export default connectedComponent;