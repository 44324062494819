import * as storeUtilities from '../../../utilities/storeUtilities';
import rpcAction from '../../../actions/rpcAction';
import { nodeRoutes } from '../../../constants';
export const reloadQueue = (dispatch, screenId, path, callback=undefined) => {
	storeUtilities.deleteValue(dispatch, path);
	const selectedVendor = storeUtilities.getValue([screenId, 'vendor'], undefined);
	dispatch(rpcAction({
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/PurchaseOrderQueue',
		method: 'GET',
		callback: (rowData) => {
			if(Array.isArray(rowData))
			{
				storeUtilities.updateValue(dispatch, path, rowData);
				updateHeaderLabels(dispatch, screenId, rowData, selectedVendor);
			}
			if(callback){
				callback(rowData);
			}
		}
	}));
};
export const updateHeaderLabels = (dispatch, screenId, data, vendor) => {
	storeUtilities.updateValue(dispatch, [screenId, 'selectedPOCount'], 0);
	storeUtilities.updateValue(dispatch, [screenId, 'selectedPOCost'], 0);
	if(!Array.isArray(data)){
		storeUtilities.updateValue(dispatch, [screenId, 'totalPOCount'], 0);
		storeUtilities.updateValue(dispatch, [screenId, 'totalPOCost'], 0);
	}
	else {
		const selectedVendorIsValid = data.filter(row => vendor && vendor.toLowerCase && row.vendor && row.vendor.toLowerCase && vendor.toLowerCase() === row.vendor.toLowerCase()).length > 0;
		const queueIDs = [];
		let totalCost = 0;
		data.forEach(row => {
			if(vendor === undefined || selectedVendorIsValid === false || (vendor.toLowerCase && row.vendor?.toLowerCase && vendor.toLowerCase() === row.vendor.toLowerCase())){
				if(!queueIDs.includes(parseInt(row.queueID))){
					queueIDs.push(parseInt(row.queueID));
				}
				totalCost += (parseFloat(row.firstCost) * parseInt(row.quantity));
			}
		});
		storeUtilities.updateValue(dispatch, [screenId, 'totalPOCount'], queueIDs.length);
		storeUtilities.updateValue(dispatch, [screenId, 'totalPOCost'], totalCost);
	}
};