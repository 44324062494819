import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import PurchaseOrderQueueScreen from './PurchaseOrderQueueScreen';
import * as poQueueUtilities from './PurchaseOrderQueueScreenUtilities';
import ViewEditCreatePOsDialogContainer from './ViewEditCreatePOsDialogContainer';
import showDialogAction2 from '../../../actions/showDialogAction2';
const SCREEN_ID = 'purchaseOrderQueueScreen';
const PANEL_ID = 'purchaseOrderQueuePanel';

class PurchaseOrderQueueScreenContainer extends Component {
	render() {
		return (
			<PurchaseOrderQueueScreen {...this.props}
				screenId={this.props.screenId} 
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const screenId = props.screenId || SCREEN_ID;
	const panelId = props.panelId || PANEL_ID;
	const user = storeUtilities.getUser();
	const purchaseOrderQueueData = storeUtilities.getValue([screenId, 'purchaseOrderQueueData'], undefined);
	const vendors = [];
	const selectedVendor = storeUtilities.getValue([screenId, 'vendor'], undefined);
	const selectedQueueIDs = storeUtilities.getValue([screenId, 'selectedQueueIDs'], []);
	const disableViewCreateEditPOsButton = storeUtilities.getValue([screenId, 'disableViewCreateEditPOsButton'], true);
	const noRowsSelected = selectedQueueIDs.length === 0;
	if(purchaseOrderQueueData){
		purchaseOrderQueueData
			.map(x => x.vendor)
			.forEach(x => {
				if(x.toUpperCase && vendors.filter(v => v.value === x.toUpperCase()).length === 0){
					vendors.push({name: x.toUpperCase(), value: x.toUpperCase()});
				}
			});
		vendors.sort((a, b) => {
			return a.value > b.value ? 1 : (a.value < b.value ? -1 : 0);
		});
	}
	const selectedVendorIsValid = vendors.filter(x => x.value === selectedVendor).length > 0;
	return {
		screenId,
		panelId,
		user,
		purchaseOrderQueueData,
		vendors,
		selectedVendor: selectedVendorIsValid ? selectedVendor : undefined,
		noRowsSelected,
		disableViewCreateEditPOsButton
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId || SCREEN_ID;
	return {
		onLoad: () => {
			storeUtilities.deleteValue(dispatch, [screenId, 'selectedQueueIDs']);
			poQueueUtilities.reloadQueue(dispatch, screenId, [screenId, 'purchaseOrderQueueData']);
		},
		onCellClicked: (params, gridRefs) => {
			if(!Array.isArray(gridRefs) || params === undefined){
				return;
			}
			storeUtilities.updateValue(dispatch, [screenId, 'disableViewCreateEditPOsButton'], true);
			window.setTimeout(()=>{
				const queueIDs = typeof(params.data?.poQueueRowModels) === 'object' ? Object.keys(params.data.poQueueRowModels).map(x => parseInt(x)) : [];
				const nodeSelected = params.node.isSelected();
				const ctrlKeyPressed = params.event.ctrlKey === true;
				const clickedNodeRelatedQueueIDs = getAllRelatedQueueIDs(queueIDs, gridRefs);
				const queueIDsToAdd = [];
				const queueIDsToRemove = [];
				let overwriteSelectedQueueIDs = false;
				if(ctrlKeyPressed){
					if(nodeSelected){
						gridRefs.forEach(gridRef => {
							gridRef.api.forEachNodeAfterFilter(otherNode => {
								const relatedQueueIDs = typeof(otherNode?.data?.poQueueRowModels) === 'object' ? Object.keys(otherNode.data.poQueueRowModels).map(x => parseInt(x)).filter(x => clickedNodeRelatedQueueIDs.includes(parseInt(x))) : [];
								const hasRelatedQueueIDs = relatedQueueIDs.length > 0;
								if(hasRelatedQueueIDs){
									otherNode.setSelected(true);
								}
								relatedQueueIDs.forEach((relatedQueueID)=>{
									if(!queueIDsToAdd.includes(relatedQueueID)){
										queueIDsToAdd.push(relatedQueueID);
									}
								});
							});
						});
					}
					else {
						gridRefs.forEach(gridRef => {
							gridRef.api.forEachNodeAfterFilter(otherNode => {
								if(typeof(otherNode?.data?.poQueueRowModels) !== 'object'){
									return;
								}
								const relatedQueueIDs = Object.keys(otherNode.data.poQueueRowModels).map(x => parseInt(x)).filter(queueID => clickedNodeRelatedQueueIDs.includes(parseInt(queueID)));
								const hasRelatedQueueIDs = relatedQueueIDs.length > 0;
								if(hasRelatedQueueIDs){
									otherNode.setSelected(false);
								}
								relatedQueueIDs.forEach((queueID) => {
									if(!queueIDsToRemove.includes(parseInt(queueID))){
										queueIDsToRemove.push(parseInt(queueID));
									}
								});
							});
						});
					}
				}
				else {
					overwriteSelectedQueueIDs = true;
					if(nodeSelected){
						gridRefs.forEach(gridRef => {
							gridRef.api.forEachNodeAfterFilter(otherNode => {
								if(typeof(otherNode?.data?.poQueueRowModels) !== 'object'){
									return;
								}
								const relatedQueueIDs = Object.keys(otherNode.data.poQueueRowModels).map(x => parseInt(x)).filter(queueID => clickedNodeRelatedQueueIDs.includes(queueID));
								otherNode.setSelected(relatedQueueIDs.length > 0);
								relatedQueueIDs.forEach((relatedQueueID) => {
									if(!queueIDsToAdd.includes(parseInt(relatedQueueID))){
										queueIDsToAdd.push(parseInt(relatedQueueID));
									}
								});
							});
						});
					}
				}
				const selectedQueueIDs = !overwriteSelectedQueueIDs ? storeUtilities.getValue([screenId, 'selectedQueueIDs'], []).map(x => parseInt(x)) : [];
				queueIDsToAdd.forEach((queueID) => {
					if(!selectedQueueIDs.includes(parseInt(queueID))){
						selectedQueueIDs.push(parseInt(queueID));
					}
				});
				const purchaseOrderQueueDataRows = storeUtilities.getValue([screenId, 'purchaseOrderQueueData'], []);
				const remainingQueueIDs = selectedQueueIDs.filter((selectedQueueID) => !queueIDsToRemove.includes(selectedQueueID));
				storeUtilities.updateValue(dispatch, [screenId, 'selectedQueueIDs'], remainingQueueIDs);
				storeUtilities.updateValue(dispatch, [screenId, 'selectedPOCount'], remainingQueueIDs.length);
				let selectedPOCostTotal = 0;
				purchaseOrderQueueDataRows.forEach(row => {
					if(remainingQueueIDs.includes(parseInt(row.queueID))){
						selectedPOCostTotal += (row.firstCost * row.quantity);
					}
				});
				storeUtilities.updateValue(dispatch, [screenId, 'selectedPOCost'], selectedPOCostTotal);
				window.setTimeout(()=>{
					gridRefs.forEach((gridRef)=>{
						gridRef.updateTotals();
					});
					storeUtilities.updateValue(dispatch, [screenId, 'disableViewCreateEditPOsButton'], false);
				}, 100);
			}, 100);
		},
		reloadQueue: () => {
			poQueueUtilities.reloadQueue(dispatch, screenId, [screenId, 'purchaseOrderQueueData']);
		},
		viewEditCreatePOsClicked: () => {
			const purchaseOrderQueueDataRows = storeUtilities.getValue([screenId, 'purchaseOrderQueueData'], []);
			const selectedQueueIDs = storeUtilities.getValue([screenId, 'selectedQueueIDs'], []);
			const selectedQueueEntriesBySku = purchaseOrderQueueDataRows.filter((row) => selectedQueueIDs.includes(row.queueID));
			const queueEntries = {};

			selectedQueueEntriesBySku.forEach(skuRow => {
				var keys = Object.keys(queueEntries);
				const key = skuRow?.queueID?.toString ? skuRow.queueID.toString() : undefined;
				if(key === undefined){
					return;
				}
				const rowModel = {...skuRow};
				if(!keys.includes(key)){
					queueEntries[key] = {
						rowModel: [rowModel]
					};
				}
				else {
					queueEntries[key]['rowModel'].push(rowModel);
				}
			});
			const rowData = Object.entries(queueEntries).map(([,v]) => {
				return { 
					rowModel: v.rowModel
				};
			});
			storeUtilities.updateValue(dispatch, ['viewEditCreatePOsDialog', 'viewEditCreatePOsDialogPanel', 'QueuedPOsDataGrid', 'rowData'], rowData);
			window.setTimeout(()=>{
				dispatch(showDialogAction2({
					dialogContent: (
						<ViewEditCreatePOsDialogContainer
							parentScreenId={screenId} 
							screenId={'viewEditCreatePOsDialog'}
							panelId={'viewEditCreatePOsDialogPanel'}
						/>),
					height: 400,
					width: 900
				}));
			}, 1000);
		},
		handleVendorChanged: (vendor) => {
			const purchaseOrderQueueDataRows = storeUtilities.getValue([screenId, 'purchaseOrderQueueData'], []);
			poQueueUtilities.updateHeaderLabels(dispatch, screenId, purchaseOrderQueueDataRows, vendor);
		}
	};
};
const getAllRelatedQueueIDs = (queueIDs, gridRefs, accumulated=[]) => {
	const newRelations = [];
	if(Array.isArray(queueIDs)){
		queueIDs.forEach(queueID => {
			if(!accumulated.includes(parseInt(queueID))){
				accumulated.push(parseInt(queueID));
			}
		});
	}
	if(Array.isArray(gridRefs)){
		gridRefs.forEach(gridRef => {
			gridRef.api.forEachNodeAfterFilter(node => {
				const currentNodeQueueIDs = typeof(node?.data?.poQueueRowModels) === 'object' ? Object.keys(node.data.poQueueRowModels).map(x => parseInt(x)) : [];
				const hasRelations = currentNodeQueueIDs.filter(x => queueIDs.includes(parseInt(x))).length > 0;
				if(hasRelations){
					currentNodeQueueIDs.forEach(queueID => {
						if(!newRelations.includes(parseInt(queueID)) && !accumulated.includes(parseInt(queueID))){
							newRelations.push(parseInt(queueID));
						}
					});
				}
			});
		});
	}
	if(newRelations.length === 0){
		return accumulated;
	}
	else {
		return getAllRelatedQueueIDs(newRelations, gridRefs, accumulated);
	}
};
PurchaseOrderQueueScreenContainer.propTypes = {
	purchaseOrderQueueData: PropTypes.array
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderQueueScreenContainer);

export default connectedComponent;