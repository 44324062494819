import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ViewEditCreatePOsDialog from './ViewEditCreatePOsDialog';
import * as poQueueUtilities from './PurchaseOrderQueueScreenUtilities';
import rpcAction from '../../../actions/rpcAction';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as constants from '../../../constants';
import showDialogAction2 from '../../../actions/showDialogAction2';
import closeDialogAction from '../../../actions/closeDialogAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import OrderLine from '../../../models/OrderLine';
import PurchaseOrderQueuePOGridPanelContainer from './PurchaseOrderQueuePOGridPanelContainer';
import POsCreatedDialog from '../../dialogs/POsCreatedDialog.jsx';

const DELETE = '/PurchaseOrderQueue';
const CREATE = '/PurchaseOrdersRequest/Create';

const SCREEN_ID = 'viewEditCreatePOsDialog';
const PANEL_ID = 'viewEditCreatePOsDialogPanel';

class ViewEditCreatePOsDialogContainer extends Component {
	render() {
		const screenId = this.props.screenId ? this.props.screenId : SCREEN_ID;
		const panelId = this.props.panelId ? this.props.panelId : PANEL_ID;
		return (
			<ViewEditCreatePOsDialog {...this.props} 
				screenId={screenId}
				panelId={panelId}
			/>
		);
	}
}
const mapStateToProps = (_, props) => {
	const screenId = props.screenId ? props.screenId : SCREEN_ID;
	const panelId = props.panelId ? props.panelId : PANEL_ID;
	const selectedRowCount = storeUtilities.getValue([screenId, panelId, 'selectedRowCount'], 0);
	return {
		disableDeleteAndCreateButtons: selectedRowCount === 0
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const screenId = props.screenId ? props.screenId : SCREEN_ID;
	const panelId = props.panelId ? props.panelId : PANEL_ID;
	const rowDataPath = [screenId, panelId, 'QueuedPOsDataGrid', 'rowData'];
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'selectedRowCount'], 0);
		},
		getRowValue: (params) => {
			switch(params.colDef.colId){
				case 'dateQueued':
					return moment(moment.utc(params.data.rowModel[0].dateQueuedUTC).toDate()).local();
				case 'skus':
					return params.data.rowModel.map(row => row.sku);
				case 'qty':
					return params.data.rowModel.map(row => row.quantity);
				case 'cost':
					return params.data.rowModel.map(row => row.firstCost);
				case 'total':
					return calcTotal(params.data.rowModel);
				case 'queueID':
					return params.data.rowModel[0].queueID;
				default:
					return params.data[params.colDef.colId];
			}
		},
		formatRowValue: (params) => {
			switch(params.colDef.colId){
				case 'queueID':
					return params.data.rowModel[0].queueID;
				case 'dateQueued':
					return params.value.format('MM/DD/YYYY  hh:mm A');
				case 'total':
					return sharedUtilities.formatCurrency(params.value);
				default:
					return undefined;
			}
		},
		onViewPOClicked: (node, gridApi) => {
			if(!Array.isArray(node?.data?.rowModel) || node.data.rowModel.length <= 0 || node.data.rowModel[0].queueID === undefined){
				return;
			}
			const queueID = parseInt(node.data.rowModel[0].queueID);
			const {rowData, cubes, poCost} = getPOData(node);
			dispatch(rpcAction({
				args: {
					div: node.data.rowModel[0].deliverTo.split('_')[0],
					divWhsOrder: true
				},
				nodeRoute: constants.nodeRoutes.IFRServiceNET,
				endpoint: '/Warehouse/Warehouse',
				method: 'GET',
				callback: (data) => {
					const deliverToOptions = data.map(dataRow => {
						return {
							name: dataRow['whs_name'],
							value: dataRow['whs_ID']
						};
					});

					const poDialogPath = [screenId, panelId, 'poDialog', queueID];
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'deliverTo'], node.data.rowModel[0].deliverTo);
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'arrivalDate'], moment(node.data.rowModel[0].arrivalDate, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate());
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'shipDate'], moment(node.data.rowModel[0].shipDate, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate());
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'poCubes'], cubes);
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'poCost'], poCost);
					storeUtilities.updateValue(dispatch, [...poDialogPath, 'instructions'], node.data.rowModel[0].instructions);
					dispatch(showDialogAction2({
						dialogContent:(
							<PurchaseOrderQueuePOGridPanelContainer
								screenId={screenId}
								panelId={panelId}
								whsNum={node.data.rowModel[0].deliverTo}
								rowData={rowData}
								poNumber={queueID}
								height={'390px'}
								title={'Queue ID: ' + queueID}
								fob={node.data.rowModel[0].fob}
								deliverToOptions={deliverToOptions}
								path={poDialogPath}
								onSaveClicked={(gridRef) => {
									const purchaseOrderQueueSkus = [];
									gridRef.api.forEachNode((node)=>{
										const ol = node.data.ORDERLINE;
										ol.quantity = node.data.QTY;
										purchaseOrderQueueSkus.push(ol);
									});
									const deliverTo = storeUtilities.getValue([...poDialogPath, 'deliverTo'], undefined);
									const arrivalDate = storeUtilities.getValue([...poDialogPath, 'arrivalDate'], undefined);
									const shipDate = storeUtilities.getValue([...poDialogPath, 'shipDate'], undefined);
									const instructions = storeUtilities.getValue([...poDialogPath, 'instructions'], undefined);
									dispatch(rpcAction({
										args: {
											purchaseOrderQueueSkus, 
											id: queueID, 
											deliverTo, 
											arrivalDate, 
											shipDate, 
											instructions,
											requestedPONumber: queueID
										},
										nodeRoute: constants.nodeRoutes.IFRServiceNET,
										endpoint: '/PurchaseOrderQueue',
										method: 'PUT',
										callback: (data) => {
											if(data?.success === true){
												const { matchingRows, rowData } = getMatchingRows('queueID', queueID, [screenId, panelId, 'QueuedPOsDataGrid', 'rowData']);
												const refreshNodes = [];
												if(matchingRows.length === 1){
													const row = matchingRows[0];
													if(!Array.isArray(row.rowModel)){
														return;
													}
													for(let i = 0; i < row.rowModel.length; i++){
														const skuModel = row.rowModel[i];
														const matches = purchaseOrderQueueSkus.filter(x => x.SKU === skuModel.sku);
														if(matches.length === 1){
															const match = matches[0];
															skuModel.quantity = match.quantity;
														}
														skuModel.arrivalDate = arrivalDate;
														skuModel.shipDate = shipDate;
														skuModel.instructions = instructions;
													}
													node.setDataValue('rowModel', row.rowModel);
													refreshNodes.push(node);
												}
												gridApi.refreshCells({rowNodes:refreshNodes, force:true});
												storeUtilities.updateValue(dispatch, rowDataPath, rowData);
												window.setTimeout(()=>{
													dispatch(closeDialogAction());
													poQueueUtilities.reloadQueue(dispatch, props.parentScreenId, [props.parentScreenId, 'purchaseOrderQueueData']);
													gridApi.sizeColumnsToFit();
												}, 1000);
											}
										}
									}));
								}}
							/>
						),
						height: 400,
						width: 400,
						enableResizing: false})
					);
				}
			}));
		},
		onDeleteClicked: (gridApi) => {
			const queueIDs = [];
			gridApi.getSelectedRows().forEach(row => {
				if(Array.isArray(row.rowModel)){
					row.rowModel.forEach(skuModel => {
						if(!queueIDs.includes(skuModel.queueID)){
							queueIDs.push(skuModel.queueID);
						}
					});
				}
			});
			if(queueIDs.length > 0){
				deleteFromQueue(dispatch, props.parentScreenId, screenId, rowDataPath, queueIDs, gridApi);
			}
			else {
				sharedUtilities.showMessage(screenId, 'NoItemsToDeleteDialog', 'No rows are selected', 'Please select 1 or more rows and click "Remove from Queue" again.');
			}
		},
		onCreatePOsClicked: (gridApi) => {
			const selectedQueueIDs = [];
			gridApi.getSelectedRows().forEach(row => {
				if(Array.isArray(row.rowModel)){
					row.rowModel.forEach(x => {
						if(!selectedQueueIDs.includes(x.queueID)){
							selectedQueueIDs.push(x.queueID);
						}
					});
				}
			});

			createPOs(dispatch, props.parentScreenId, screenId, selectedQueueIDs, rowDataPath, () => {
				gridApi.sizeColumnsToFit();
			});
		},
		onCloseClicked: () => {
			dispatch(closeDialogAction());
		},
		onRowSelected: (params) => {
			const selectedNodes = params.api.getSelectedNodes();
			storeUtilities.updateValue(dispatch, [screenId, panelId, 'selectedRowCount'], selectedNodes.length);
		},
		onCellClicked: (params) => {
			if(params.event.ctrlKey){
				const selected = !params.node.isSelected();
				params.node.setSelected(selected);
				if(selected){
					storeUtilities.updateValue(dispatch, [screenId, panelId, 'lastSelectedRowIndex'], params.node.rowIndex);
				}
				else {
					storeUtilities.deleteValue(dispatch, [screenId, panelId, 'lastSelectedRowIndex']);
				}
			}
			else if(params.event.shiftKey){
				const lastSelectedRowIndex = storeUtilities.getValue([screenId, panelId, 'lastSelectedRowIndex'], undefined);
				if(lastSelectedRowIndex !== undefined){
					const clickedRowIndex = params.node.rowIndex;
					params.api.forEachNodeAfterFilter((node) => {
						if(clickedRowIndex >= lastSelectedRowIndex){
							//select all rows between lastClickedRowIndex and clickedRowIndex
							if(node.rowIndex >= lastSelectedRowIndex && node.rowIndex <= clickedRowIndex){
								node.setSelected(true);
							}
						}
						else {
							//select all rows between clickedRowIndex and lastClickedRowIndex
							if(node.rowIndex >= clickedRowIndex && node.rowIndex <= lastSelectedRowIndex){
								node.setSelected(true);
							}
						}
					});
				}
				else {
					params.node.setSelected(true);
				}
				storeUtilities.updateValue(dispatch, [screenId, panelId, 'lastSelectedRowIndex'], params.node.rowIndex);
			}
			else {
				params.api.forEachNodeAfterFilter((node) => {
					const selected = node.rowIndex === params.node.rowIndex;
					node.setSelected(selected);
					if(selected){
						storeUtilities.updateValue(dispatch, [screenId, panelId, 'lastSelectedRowIndex'], params.node.rowIndex);
					}
				});
			}
		}
	};
};
const createPOs = (dispatch, parentScreenId, screenId, selectedQueueIDs, rowDataPath, callback) => {
	const rowData = storeUtilities.getValue(rowDataPath, []);
	/**
	 * When the POs were queued, they were queued to a unique batchKey, meaning all of the common parameters will be the same for each po with the same batchKey.
	 * Re-group them by batchKey and make a separate call to the Create PO API call for each (to maintain the original parameters).
	 */
	const rowsByBatchKey = {};
	rowData.forEach(row => {
		if(!Array.isArray(row.rowModel)){
			return;
		}
		row.rowModel.forEach(poSku => {
			const batchKeys = Object.keys(rowsByBatchKey);
			if(selectedQueueIDs.includes(poSku.queueID)){
				if(!batchKeys.includes(poSku.batchKey)){
					rowsByBatchKey[poSku.batchKey] = [];
				}
				rowsByBatchKey[poSku.batchKey].push(poSku);
			}
		});
	});
	const createPOCalls = [];
	Object.values(rowsByBatchKey).forEach(posSkus => {
		const { queueIDs, poArray } = buildPOArray(posSkus);
		createPOCalls.push({ queueIDs, args: getCreatePOsArgs(posSkus, poArray)});
	});
	const OkCancelDialogContainer = React.lazy(() => import('../../../components/dialogs/OkCancelDialogContainer'));
	dispatch(showDialogAction2({
		dialogContent: (
			<Suspense fallback={<div>Loading...</div>}>
				<OkCancelDialogContainer
					screenId={screenId} 
					title={'Confirmation'}
					handleOkClicked={()=>{
						let callIndex = 0;
						const flatResponses = [];
						createPOCalls.forEach(createPOCall => {
							dispatch(rpcAction({
								args: createPOCall.args,
								nodeRoute: constants.nodeRoutes.IFRServiceNET,
								endpoint: CREATE,
								method: 'POST',
								hideLoadMaskOnComplete: false,
								callback: (response) => {
									deleteCreatedPOs(dispatch, createPOCall.queueIDs);
									const flatResponse = sharedUtilities.flattenResponse(response);
									flatResponses.push(...flatResponse);
									if(callIndex === (createPOCalls.length - 1)){
										dispatch(showDialogAction2({
											dialogContent: (
												<POsCreatedDialog 
													screenId={screenId}
													panelId={'PurchaseOrderQueue'}
													fieldId={'POsCreatedDialog'}
													rowData={flatResponses}
													okButtonText={'Close'}
													handleOkClicked={() => {
														poQueueUtilities.reloadQueue(dispatch, parentScreenId, [parentScreenId, 'purchaseOrderQueueData']);
														dispatch(closeDialogAction());
														if(callback){
															callback();
														}
													}}
												/>),
											width: 550,
											height: 350
										}));
									}
									callIndex++;
								},
								retryOnFailure: false
							}));
						});

					}}
					hasCancelButton
					okButtonText={'Yes'}
					cancelButtonText={'No'}>
					<h5>{'Are you sure you want to '}<b><u>CREATE</u></b>{' POs from the selected Purchase Order Queue entries?'}</h5>
				</OkCancelDialogContainer>
			</Suspense>),
		height: 200,
		width: 350,
		dialogId: 'DELETE_POS_CONFIRMATION_DIALOG'
	}));
};
const getCreatePOsArgs = (posSkus, poArray) => {
	//posSkus is a list of all the skus in this batch...there must be at least one record for any of this to even make sense
	if(!Array.isArray(posSkus) || posSkus.length < 1){
		return undefined;
	}
	//grab the rest of the createPO args from the first record (because the are identical across all the skus in this batch)
	const first = posSkus[0];
	const args = {};
	const user = storeUtilities.getUser();
	const whsArray = first.deliverTo.split('_');
	args.buyerInitials = first.buyerInitials;
	args.confirmationRequested = first.reqConfirmation;
	args.daysToStock = first.daysToStock;
	args.destinationDivision = whsArray[0];
	args.destinationWarehouseId = whsArray[1];
	args.emailAddress = first.emailAddress;
	args.confirmationEmail = first.confirmationEmail;
	args.salesBeginDate = first.beginSalesDate;
	args.salesEndDate = first.endSalesDate;
	args.systemUser = user.rtgID;
	args.buyerName = user.fullName;
	args.requestType = constants.RequestTypes.PRODUCT;
	args.group = first.grouping;
	args.leadTime = first.leadTime;
	args.marketAdjustment = first.marketAdj.toString();
	args.maxCube = first.maxCube;
	args.numberOfSalesDays = first.numSalesDays;
	args.productionLeadTime = first.prodLeadTime;
	args.sendAsFax = first.sendToFaxQueue;
	args.showPromotionAvailable = first.showPromoAvail;
	args.transportationLeadTime = first.transLeadTime;
	args.userDaysToNextOrder = first.userDTNO;
	args.vendor = first.vendor;
	args.isQueued = false;
	args.terms = first.terms;
	args.shipVia = first.shipVia;
	args.flag = first.poflag;
	args.comboWharehouse = first.combineWhsList.length > 0 ? true : false;
	args.purchaseOrders = poArray;
	return args;
};
const buildPOArray = (posSkus) => {
	const poArray = [];
	const queueIDs = [];
	if(!Array.isArray(posSkus)){
		return poArray;
	}
	/**
	 * When the POs were queued, each PO is denoted by a queueID. 
	 * Re-group the PO Skus onto their POs using this value.
	 */
	const posObject = {};
	posSkus.forEach((poSku) => {
		const queueIDKeys = Object.keys(posObject);
		if(!queueIDKeys.includes(String(poSku.queueID))){
			posObject[poSku.queueID] = [];
		}
		posObject[poSku.queueID].push(poSku);
		if(!queueIDs.includes(poSku.queueID)){
			queueIDs.push(poSku.queueID);
		}
	});
	//at this point, the pos have been reassembled the pos...now, create the poArray request object
	const posObjectValues = Object.values(posObject);
	if(posObjectValues.length < 1){
		return { queueIDs, poArray };
	}
	for(let i = 0; i < posObjectValues.length; i++){
		const skusArray = posObjectValues[i];
		const po = {
			skus: []
		};
		for(let j = 0; j < skusArray.length; j++){
			const s = skusArray[j];
			const whsArray = s.deliverTo.split('_');
			/* begin: identical values across skus per PO */
			po.shipDate = s.shipDate;
			po.arrivalDate = s.arrivalDate;
			po.calculatedArrivalDate = s.arrivalDate;
			po.poCube = s.poCube;
			po.destinationDivision = whsArray[0];
			po.destinationWarehouseId = whsArray[1];
			po.freightOnBoard = s.fob;
			po.instructions = s.instructions;
			/* end: identical values across skus per PO*/
			
			po.skus.push({
				sku: s.sku,
				quantity: s.quantity,
				alternateQuantity: s.altQty,
				orderQuantity: s.orderQty,
				cube: s.cube,
				averageDailySales: s.averageDailySales,
				inbound: s.inbound,
				available: s.available,
				orderPoint: s.orderPoint,
				availableToday: s.availableToday,
				pieceCount: s.pieceCount,
				availableAfterOrder: s.availableAfterOrder,
				orderRatio: s.orderRatio,
				intradivisional: s.intradivisional,
				daysToNextOrder: s.daysToNextOrder,
				promotionAvailable: s.promoAvail,
				needByDate: s.needByDate,
				description: s.description,
				color: s.color,
				price: s.firstCost
			});
		}
		poArray.push(po);
	}
	return { queueIDs, poArray };
};
const getMatchingRows = (field, value, path) => {
	const rowData = storeUtilities.getValue(path, undefined);
	const matchingRows = [];
	for(let i = 0; i < rowData.length; i++){
		const row = rowData[i];
		if(!Array.isArray(row.rowModel)){
			continue;
		}
		let found = false;
		for(let j = 0; j < row.rowModel.length; j++){
			const skuModel = row.rowModel[j];
			if(skuModel[field] === value){
				if(found === false){
					matchingRows.push(row);
				}
				found = true;
			}
		}
	}
	return { matchingRows, rowData };
};
const deleteFromQueue = (dispatch, parentScreenId, screenId, rowDataPath, queueIDs, gridApi) => {
	const OkCancelDialogContainer = React.lazy(() => import('../../../components/dialogs/OkCancelDialogContainer'));
	dispatch(showDialogAction2({
		dialogContent: (
			<Suspense fallback={<div>Loading...</div>}>
				<OkCancelDialogContainer
					screenId={screenId} 
					title={'Confirmation'}
					handleOkClicked={()=>{
						dispatch(rpcAction({
							args: {queueIDs},
							nodeRoute: constants.nodeRoutes.IFRServiceNET,
							endpoint: DELETE,
							method: 'DELETE',
							hideLoadMaskOnComplete: false,
							callback: () => {
								const rowData = storeUtilities.getValue(rowDataPath, []);
								const leftoverRows = rowData.filter(row => !queueIDs.includes(row.rowModel[0].queueID));
								if(leftoverRows.length > 0){
									storeUtilities.updateValue(dispatch, rowDataPath, leftoverRows);
								}
								else {
									dispatch(closeDialogAction());
								}
								poQueueUtilities.reloadQueue(dispatch, parentScreenId, [parentScreenId, 'purchaseOrderQueueData'], () => {
									const selectedQueueIDs = storeUtilities.getValue([parentScreenId, 'selectedQueueIDs'], []);
									const leftoverQueueIDs = selectedQueueIDs.filter(x => !queueIDs.includes(x));
									storeUtilities.updateValue(dispatch, [parentScreenId, 'selectedQueueIDs'], leftoverQueueIDs);
									if(leftoverRows.length > 0 && gridApi?.sizeColumnsToFit){
										gridApi.sizeColumnsToFit();
									}
								});
							},
							retryOnFailure: true
						}));
					}}
					hasCancelButton
					okButtonText={'Yes'}
					cancelButtonText={'No'}>
					<h5>{'Are you sure you want to '}<b><u>REMOVE</u></b>{' the selected entries from the Purchase Order Queue?'}</h5>
				</OkCancelDialogContainer>
			</Suspense>),
		height: 200,
		width: 350,
		dialogId: 'DELETE_POS_CONFIRMATION_DIALOG'
	}));
};
const deleteCreatedPOs = (dispatch, queueIDs) => {
	dispatch(rpcAction({
		args: {'queueIDs' : queueIDs},
		nodeRoute: constants.nodeRoutes.IFRServiceNET,
		endpoint: 'PurchaseOrderQueue',
		method: 'DELETE',
		callback: () => {},
		showLoadingMask: false
	}));
};
const calcTotal = (rowModel) => {
	if(!Array.isArray(rowModel)){
		return 0;
	}
	let total = 0;
	rowModel.forEach(row => {
		total += (row.quantity * row.firstCost);
	});
	return total;
};
const getPOData = (node) => {
	const rowData = [];
	let cubes = 0;
	let poCost = 0;
	if(!Array.isArray(node?.data?.rowModel)){
		return undefined;
	}
	node.data.rowModel.forEach(model=>{
		const row = {};
		row.ORDERLINE = new OrderLine();
		Object.entries(row.ORDERLINE).forEach(([olKey]) => {
			Object.entries(model).forEach(([modelKey, modelValue]) => {
				if(olKey?.toLowerCase && modelKey?.toLowerCase && olKey.toLowerCase() === modelKey.toLowerCase()){
					row.ORDERLINE[olKey] = modelValue;
				}
			});
		});
		row.CUBES = model.cube * model.quantity;
		cubes += row.CUBES;
		row.FIRSTCOST = model.firstCost;
		row.LINE = (model.quantity * model.firstCost);
		poCost += row.LINE;
		row.NEEDBYDATE = moment(model.needByDate, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate();
		row.QTY = model.quantity;
		row.SKU = model.sku;
		rowData.push(row);
	});
	return {rowData, cubes, poCost};
};
ViewEditCreatePOsDialogContainer.propTypes = {};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ViewEditCreatePOsDialogContainer);

export default connectedComponent;