import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { nodeRoutes } from '../../../constants';
import VendorWarehouseOrderLine from '../../../models/VendorWarehouseOrderLine';
import * as validators from '../../../utilities/commonValidators';
import * as numberUtilities from '../../../utilities/numberUtilities';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import VendorWarehouseOrderGridPanelContainer from './VendorWarehouseOrderGridPanelContainer';
import {
	ButtonContainer,
	DropdownContainer,
	DropdownRpcContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';

class WarehouseScreen extends Component {
	constructor(props) {
		super(props);
		this.handleWarehouseChanged = this.handleWarehouseChanged.bind(this);
		this.handleDivisionChanged = this.handleDivisionChanged.bind(this);
		this.queueStoreOrders = this.queueStoreOrders.bind(this);
		this.addSku = this.addSku.bind(this);
		this.viewPoOrderQueue = this.viewPoOrderQueue.bind(this);
		this.gridRefs = [];
		this.handleSearchClicked = this.handleSearchClicked.bind(this);
	}
	componentDidMount() {
		if (this.props.onLoad) {
			this.props.onLoad();
		}
	}
	handleWarehouseChanged(value) {
		if (this.props.handleWarehouseChanged) {
			this.props.handleWarehouseChanged(value);
		}
	}
	handleDivisionChanged(value){
		if (this.props.handleDivisionChanged) {
			this.props.handleDivisionChanged(value);
		}
	}

	handleSearchClicked() {
		if (this.props.handleSearchClicked && !this.props.searchButtonDisabled) {
			this.props.handleSearchClicked();
		}
	}
	queueStoreOrders() {
		if (this.props.queueStoreOrders) {
			this.props.queueStoreOrders(this.gridRefs);
		}
	}
	addSku() {
		if (this.props.addSku) {
			this.props.addSku();
		}
	}
	viewPoOrderQueue() {
		if (this.props.viewPoOrderQueue) {
			this.props.viewPoOrderQueue();
		}
	}
	setRowModel(data) {
		const orderLine = new VendorWarehouseOrderLine();
		orderLine.PARTSKU = data.partSku.substr(1, 8);
		orderLine.PARTMOD = data.partSku.substr(8, 4);
		orderLine.PARTDESCRIPTION = data.partDescription;
		orderLine.AVERAGEDAILYSHIPMENTS = numberUtilities.getNumberOrDefault(data.averageDailyShipments);
		orderLine.ALIAS = data.vendor + ' ' + data.partSku.substr(4, 4);
		orderLine.AVERAGEWEEKLYSHIPMENTS = numberUtilities.getNumberOrDefault(data.averageWeeklyShipments);
		orderLine.TOTALSHIPMENTS = numberUtilities.getNumberOrDefault(data.totalShipments);
		orderLine.PENDINGSHIPMENTS = numberUtilities.getNumberOrDefault(data.pendingShipments);
		orderLine.QUANTITYONHAND = numberUtilities.getNumberOrDefault(data.quantityOnHand);
		orderLine.INBOUNDQUANTITY = numberUtilities.getNumberOrDefault(data.inboundQuantity);
		orderLine.AVAILABLEQUANTITY = numberUtilities.getNumberOrDefault(data.availableQuantity);
		orderLine.WEEKSOFSTOCKINBOUND = numberUtilities.getNumberOrDefault(data.weeksOfStockInbound);
		orderLine.VENDOR = data.vendor;
		orderLine.CALCULATEDORDERQUANTITY = numberUtilities.getNumberOrDefault(data.calculatedOrderQuantity);
		orderLine.ORD = this.calculateOrdQty(data);
		const rowModel = { ...{ ROWMODEL: orderLine, ROWMODEL_ORIGINAL: cloneDeep(orderLine) }, ...data };
		return rowModel;
	}
	calculateOrdQty(data) {
		let calcval = 0;
		// = [(Moved Quantity/ report run length (typically 90 days))*Lead Time ]-(inventory + Ordered)
		if (numberUtilities.getNumberOrDefault(data.totalShipments) > 0) {
			calcval = ((numberUtilities.getNumberOrDefault(data.totalShipments) / 30) * 90) - (numberUtilities.getNumberOrDefault(data.quantityOnHand) + numberUtilities.getNumberOrDefault(data.inboundQuantity));
		}
		return calcval;
	}
	render() {
		this.gridRefs = [];
		if (this.props.user === undefined
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasBuyerAccess === undefined
			|| this.props.user.hasPartsAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasBuyerAccess() && !this.props.user.hasPartsAccess())) {
			return <NoAccessToScreenPanel />;
		}
		const skuDataByVendor = new Map();
		if (this.props.warehouseSkuData !== undefined) {
			for (let i = 0; i < this.props.warehouseSkuData.length; i++) {
				const vendorCode = this.props.warehouseSkuData[i]['VENDOR'];
				if (!skuDataByVendor.has(vendorCode)) {
					skuDataByVendor.set(vendorCode, [this.setRowModel(this.props.warehouseSkuData[i])]);
				}
				else {
					skuDataByVendor.get(vendorCode).push(this.setRowModel(this.props.warehouseSkuData[i]));
				}
			}
		}
		const skuDataByVendorIterator = skuDataByVendor.entries();
		const warehouseGridPanelsToRender = [];
		for (let j = 0; j < skuDataByVendor.size; j++) {
			const entry = skuDataByVendorIterator.next();
			if (entry === undefined || entry.value === undefined) {
				break;
			}
			const panelStyle = j === 0 ? { marginTop: '3px', marginBottom: '3px' } : { marginTop: '0px', marginBottom: '3px' };
			const gridRef = React.createRef();
			this.gridRefs.push(gridRef);
			warehouseGridPanelsToRender.push(
				<VendorWarehouseOrderGridPanelContainer
					screenId={this.props.screenId}
					rowData={entry.value[1]}
					vendorCode={entry.value[0]}
					key={j}
					index={j}
					panelStyle={panelStyle}
					gridRef={gridRef}
				/>);
		}
		return (
			<Container className={'h-100'} fluid={true}>
				<Card>
					<Card.Header>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<h3 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Warehouse Ordering</h3>
								<hr />
							</Col>
						</Row>
						<Row>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<DropdownContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'division'}
									label={'Division'}
									options={[{ value: 'FL', name: 'Florida' }, { value: 'SE', name: 'Southeast' }, { value: 'TX', name: 'Texas' }]}
									mainComponentBootstrapSize={8}
									showRequiredAsterisk
									onChange={this.handleDivisionChanged.bind(this)}
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<DropdownRpcContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'whsNum'}
									route={nodeRoutes.IFRServiceNET}
									method={'GET'}
									label={'Warehouse'}
									endpoint={'/Warehouse/Warehouse'}
									args={{ div: this.props.division, divWhsOrder: true }}
									isReadyToFetchData={()=>{
										return this.props.division !== undefined && this.props.division.trim && this.props.division.trim() !== '';
									}}
									valueColumn={'whs_ID'}
									nameColumn={'whs_name'}
									hasBlankItem={true}
									mainComponentBootstrapSize={8}
									validators={[validators.required]}
									showRequiredAsterisk
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'VendorCode'}
									onEnterPressed={this.onSearchClicked}
									label={'Vendor Code'}
									validators={[validators.required]}
									showRequiredAsterisk
									store={store} 
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<DropdownContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'leadtime'}
									label={'Lead Time'}
									options={[{ value: '60', name: '60' }, { value: '90', name: '90' }, { value: '120', name: '120' }]}
									mainComponentBootstrapSize={8}
									validators={[validators.required]}
									showRequiredAsterisk
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'Search'}
									text='Search'
									disabled={this.props.searchButtonDisabled}
									onClick={this.handleSearchClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								{/* <ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'QueueStoreOrders'} 
									text={'Queue order for' + (this.props.whsNum === undefined ? '...' : (' ' + this.props.whsNum.split('_')[1]))} 
									onClick={this.queueStoreOrders}
									disabled={this.props.whsNum === undefined}
									fill
								/> */} &nbsp;
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								{/* <ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'viewPoOrderQueue'} 
									text={'View PO Order Queue (' + this.props.queueCount + ')'} 
									onClick={this.viewPoOrderQueue}
									fill
								/> */} &nbsp;
							</Col>
						</Row>
					</Card.Header>
					{warehouseGridPanelsToRender}
				</Card>
			</Container>
		);
	}
}

WarehouseScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	divison: PropTypes.string,
	leadtime: PropTypes.string,
	handleWarehouseChanged: PropTypes.func,
	handleDivisionChanged: PropTypes.func,
	warehouseSkuData: PropTypes.array,
	queueStoreOrders: PropTypes.func,
	addSku: PropTypes.func,
	viewPoOrderQueue: PropTypes.func,
	disableAddSkusButton: PropTypes.bool,
	onLoad: PropTypes.func,
	queueCount: PropTypes.number,
	handleSearchClicked: PropTypes.func,
	searchButtonDisabled: PropTypes.bool
};

export default WarehouseScreen;