import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SizeMe } from 'react-sizeme';
import * as numberUtilities from '../../../utilities/numberUtilities';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import ExportToExcelButton from '../../formelements/ExportToExcelButton';
import SuggestedOrdersDetailsTabContainer from './SuggestedOrdersDetailsTabContainer';
import SuggestedOrdersOrdersTabContainer from './SuggestedOrdersOrdersTabContainer';
import AllocationsTabContainer from './AllocationsTabContainer';
import {
	ButtonContainer,
	DropdownContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import * as constants from '../../../constants';
class SuggestedOrdersPanel extends Component {
	constructor(props){
		super(props);
		this.getExcelDataSet = this.getExcelDataSet.bind(this);
		this.onResetValuesClicked = this.onResetValuesClicked.bind(this);
		this.onCalculatePOsClicked = this.onCalculatePOsClicked.bind(this);
		this.onHeaderHeightChanged = this.onHeaderHeightChanged.bind(this);
		this.getComponent = this.getComponent.bind(this);
		this.ordersGridRef = undefined;
		this.detailsGridRef = undefined;
	}
	componentDidMount(){
		if(this.props.onLoad)
			this.props.onLoad();
	}
	onCalculatePOsClicked(){
		if(this.props.onCalculatePOsClicked)
			this.props.onCalculatePOsClicked(this.ordersGridRef.api);
	}
	onResetValuesClicked(){
		if(this.props.onResetValuesClicked)
			this.props.onResetValuesClicked();
	}
	getExcelDataSet(){
		if(this.props.getExcelDataSet 
			&& ((this.ordersGridRef)
				|| (this.detailsGridRef))){
			return this.props.getExcelDataSet(this.ordersGridRef, this.detailsGridRef);
		}
	}
	onTabChanged(key){
		if(this.props.onTabChanged)
			this.props.onTabChanged(key);
	}
	onHeaderHeightChanged(height){
		if(this.props.onHeaderHeightChanged){
			this.props.onHeaderHeightChanged(height);
		}
	}
	onAddAltSkuClicked(){
		if(this.props.onAddAltSkuClicked && this.ordersGridRef && this.detailsGridRef){
			this.props.onAddAltSkuClicked(this.ordersGridRef, this.detailsGridRef);
		}
	}
	handleOrdersGridRowClicked(params){
		if(this.props.handleOrdersGridRowClicked){
			this.props.handleOrdersGridRowClicked(params);
		}
	}
	onExportToExcelClicked(dataset){
		if(this.props.onExportToExcelClicked){
			this.props.onExportToExcelClicked(dataset);
		}
	}
	onMaximizeCubeChanged(maximizeCube){
		if(this.props.onMaximizeCubeChanged){
			this.props.onMaximizeCubeChanged(maximizeCube);
		}
	}
	getComponent(height){
		let clientHeight = 0;
		if(this.headerRef){
			clientHeight = this.headerRef?.clientHeight === undefined ? 0 : this.headerRef.clientHeight;
		}
		const tabHeight = (height - clientHeight - constants.navTabsHeight);
		return (
			<Container fluid={true} className={'h-100 m-0 p-0'} style={{minHeight:'150px'}}>
				<Card className={'h-100 mb-2'} style={{overflow:'hidden', minHeight: '150px'}}>
					<Card.Header ref={(ref) => {this.headerRef = ref;}}>
						<Row className={'p-0 m-0'} noGutters={true}>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Suggested Orders</h5>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1'}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'maximizeCube'}
									label={'Maximize Cube'}
									mainComponentBootstrapSize={1}
									labelStyle={{float:'right', paddingRight: '5px'}} 
									onChange={this.onMaximizeCubeChanged.bind(this)}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3} xl={3} className={'pl-1'}>
								<DropdownContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'groupPOsBy'}
									options={[
										{value:'SkuGroup', 		name:'SKU Group'},
										{value:'Theme|FactoryColor', 	name:'Theme/Factory Color'}
									]}
									labelStyle={{float:'right', paddingRight: '5px'}}
									label={'Group POs By'}
									hasBlankOption
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}>
								&nbsp;
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'addAltSku'} 
									text='Add Alt-SKU'
									disabled={this.props.disablePanel || this.props.disableAddAltSkuButton}
									onClick={this.onAddAltSkuClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} className={'pl-1'}>
								<ExportToExcelButton
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'exportExcel'} 
									text={'Export to Excel'}
									disabled={this.props.disablePanel || this.props.disableDataDrivenButtons}
									excelDataSetName={'SuggestedOrders'}
									getExcelDataSet={this.getExcelDataSet.bind(this)}
									fill
									onClick={this.onExportToExcelClicked.bind(this)}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} className={'pl-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'resetValues'} 
									text='Reset Values'
									disabled={this.props.disablePanel || this.props.disableDataDrivenButtons}
									onClick={this.onResetValuesClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} className={'pl-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'calculatePOs'} 
									text='Calculate POs'
									disabled={this.props.disablePanel || this.props.disableCalculatePOsButton}
									onClick={this.onCalculatePOsClicked.bind(this)} 
									fill
									store={store}
								/>
							</Col>
						</Row>
					</Card.Header>
					<Row noGutters={true} className={'h-100'}>
						<Col className={'p-0 m-0 h-100'} style={{overflow:'hidden', backgroundColor:'gainsboro'}}>
							<Tabs 
								style={{position:'relative', left:'10px'}}
								onSelect={this.onTabChanged.bind(this)}>
								<Tab 
									eventKey={'Orders'}
									title={'Orders'}
									style={{position:'relative', top:'10px', height: (tabHeight + 'px')}}>
									<SuggestedOrdersOrdersTabContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										forwardRef={(ref) => { 
											this.ordersGridRef = ref; 
										}}
										getDetailsGridRef={() => { return this.detailsGridRef; }}
										excludeRatios={this.props.excludeRatios}
										excludeSalesRatios={this.props.excludeSalesRatios}
										handleRowClicked={this.handleOrdersGridRowClicked.bind(this)}
									/>
								</Tab>
								<Tab 
									eventKey={'Details'} 
									title={'Details'} 
									style={{position:'relative', top:'10px', height: (tabHeight + 'px')}}>
									<SuggestedOrdersDetailsTabContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										forwardRef={(ref) => {
											this.detailsGridRef = ref; 
										}}
										getOrdersGridRef={() => { return this.ordersGridRef; }}
									/>
								</Tab>
								<Tab 
									eventKey={'allocations'} 
									title={'Allocations'} 
									style={{position:'relative', top:'10px'}}>
									<AllocationsTabContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										height={height-46}
									/>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Card>
			</Container>);
	}
	render() {
		const { doNotMonitorSize } = this.props;
		//SizeMe does not work in Jest unit tests...so, setting the "doNotMonitorSize" will render it without it
		return doNotMonitorSize 
			? this.getComponent(500) 
			: (
				<SizeMe monitorHeight={true}> 
					{({size}) => {
						const height = numberUtilities.getNumberOrDefault(size.height, 150);
						return (
							this.getComponent(height)
						);}}
				</SizeMe>
			);
	}
}

SuggestedOrdersPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	handleOrderChanged: PropTypes.func,
	excludeRatios: PropTypes.bool,
	onDescriptionColumnExpandCollapseClicked: PropTypes.func,
	compareDescriptions: PropTypes.func,
	getIsPrimary: PropTypes.func,
	getIsCellHidden: PropTypes.func,
	getColumnValue: PropTypes.func,
	onCalculatePOsClicked: PropTypes.func,
	onResetValuesClicked: PropTypes.func,
	disablePanel: PropTypes.bool,
	getExcelDataSet: PropTypes.func,
	tab: PropTypes.string,
	onExportToExcelClicked: PropTypes.func,
	disableCalculatePOsButton: PropTypes.bool,
	doNotMonitorSize: PropTypes.any,
	onAddAltSkuClicked: PropTypes.func,
	handleOrdersGridRowClicked: PropTypes.func,
	disableAddAltSkuButton: PropTypes.bool,
	disableDataDrivenButtons: PropTypes.bool
};
export default SuggestedOrdersPanel;