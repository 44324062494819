import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deleteCurrentValuesAction from '../../../actions/deleteCurrentValuesAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import SearchDailyOrdersPanel from './SearchDailyOrdersPanel';
import { RequestTypes, nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';
const PANEL_ID = 'searchDailyOrdersPanel';
class SearchDailyOrdersPanelContainer extends Component {
	render() {
		return (
			<SearchDailyOrdersPanel {...this.props}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		handleSearchClicked: () => {
			const orderMomentFrom = moment(storeUtilities.getValue([props.screenId, 'OrderDateFrom'], new Date()));
			const orderMomentTo = moment(storeUtilities.getValue([props.screenId, 'OrderDateTo'], new Date()));
			const formattedOrderDateFrom = formatDate(orderMomentFrom);
			const formattedOrderDateTo = formatDate(orderMomentTo.add(1, 'days'));
			const args = sharedUtilities.pruneObject({
				BuyerInitials: storeUtilities.getValue([props.screenId, 'BuyerInitials'], undefined),
				OrderDateFrom: formattedOrderDateFrom,
				OrderDateTo: formattedOrderDateTo,
				Sku: storeUtilities.getValue([props.screenId, 'Sku'], undefined),
				Vendor: storeUtilities.getValue([props.screenId, 'Vendor'], undefined),
				RequestType: buildRequestTypeArray(
					storeUtilities.getValue([props.screenId, 'selectProducts'], false),
					storeUtilities.getValue([props.screenId, 'selectParts'], false),
					storeUtilities.getValue([props.screenId, 'selectClearance'], false)
				)
			});
			dispatch(rpcAction({
				args: args, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				endpoint: '/Management/DailyOrders', 
				method: 'GET',
				showLoadingMask: true,
				hideLoadingMaskOnComplete: true,
				callback: (data) => {
					if(data && Array.isArray(data.dailyOrders)){
						storeUtilities.updateValue(dispatch, [props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData'], data.dailyOrders, true, false);
					}

				}
			}));
		},
		handleClearFormClicked: () => {
			dispatch(deleteCurrentValuesAction([props.screenId]));
		}
	};
};
const formatDate = (moment) => {
	return moment.format('YYYY-MM-DD 00:00:00');
};

const buildRequestTypeArray = (SelectProducts, SelectParts, SelectClearance) => {
	let arr = [];
	if(SelectProducts){
		arr.push(RequestTypes.PRODUCT);
	}
	if(SelectParts){
		arr.push(RequestTypes.PART);
	}
	if(SelectClearance){
		arr.push(RequestTypes.CLEARANCE);
	}
	if(arr.length === 0){
		arr.push(RequestTypes.ALL);
	}
	return arr;
};

SearchDailyOrdersPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	handleSubmitClicked: PropTypes.func,
	handleRowClicked: PropTypes.func,
	handleSelectAllClicked: PropTypes.func,
	handleDeselectAllClicked: PropTypes.func,
	submitButtonDisabled: PropTypes.bool,
	selectAllButtonDisabled: PropTypes.bool,
	deselectAllButtonDisabled: PropTypes.bool,
	rowData: PropTypes.array,
	handleClearFormClicked: PropTypes.func,
	onLoad: PropTypes.func
};
const connectedComponent = connect(null, mapDispatchToProps)(SearchDailyOrdersPanelContainer);

export default connectedComponent;