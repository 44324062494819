import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import callRpcAction from '../../../actions/callRpcAction';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import WarehouseOrderQueueDialog from './WarehouseOrderQueueDialog';
import { nodeRoutes } from '../../../constants';
export const PANEL_ID = 'warehouseOrderQueueDialog';
class WarehouseOrderQueueDialogContainer extends Component {
	render() {
		return (
			<WarehouseOrderQueueDialog {...this.props}
				panelId={this.props.panelId || PANEL_ID}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const panelId = props.panelId || PANEL_ID;
	const rowData = storeUtilities.getValue([props.screenId, panelId, 'rowData'], []);
	const reqConfirmation = storeUtilities.getValue([props.screenId, panelId, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId, panelId]);
	const createPurchaseOrdersButtonDisabled = validationErrors === undefined 
		|| 'BuyerInitials' in validationErrors
		|| 'Terms' in validationErrors
		|| 'ShipVia' in validationErrors
		|| 'ShipDate' in validationErrors
		|| 'ArrivalDate' in validationErrors
		|| ('confirmationEmailAddress' in validationErrors && reqConfirmation === true);
	return {
		rowData,
		createPurchaseOrdersButtonDisabled,
		disableConfirmationEmail: reqConfirmation === false
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const panelId = props.panelId || PANEL_ID;
	return {   
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			const defaultValues = {
				confirmationEmailAddress: userEmail
			};
			dispatch(updateDefaultValuesAction([props.screenId, panelId], defaultValues));
			const args = { poUser: user.rtgID };
			dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedWarehouseOrders', (data) => {
				if (data && Array.isArray(data)) {
					for (let idx in data) {
						data[idx].DELETE = false; 
					}
					storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'rowData'], data);
				}
			}));
		},
		handleCancelClicked: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId, panelId]);
			dispatch(closeDialogAction());
		},
		onDeleteRowClicked: (gridApi, node, value) => {
			const rowData = storeUtilities.getValue(
				[props.screenId, panelId, 'rowData'], []);
			let idx = 0; 
			for (idx = 0; idx < rowData.length; idx++) {
				if (rowData[idx].SKU === node.data.SKU && rowData[idx].WHS === node.data.WHS) {
					break;
				}
			}
			storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'rowData', idx, 'DELETE'], value);
			node.setDataValue('DELETE', value);
			gridApi.refreshCells({rowNodes:[node], force:true});
		},
		onDeleteColumnHeaderClicked: () => {
			const rowData = storeUtilities.getValue([props.screenId, panelId, 'rowData'], []);
			var idx = 0;
			var deleteRows = [];
			var deleteSkusList = []; 
			for (idx = 0; idx < rowData.length; idx++) {
				if (rowData[idx].DELETE) {
					deleteRows.push(rowData[idx]);
					deleteSkusList.push(<div key={idx}>{rowData[idx].SKU}<br/></div>);
				}
			}
			dispatch(showDialogAction(
				<OkCancelDialogContainer
					screenId={props.screenId} 
					title={'Confirmation'}
					handleOkClicked={()=>{
						deleteFromQueue(dispatch, props, deleteRows);
					}}
					hasCancelButton
					message={'Are you sure you want to remove these SKUs from the queue?'}
					okButtonText={'Yes'}
					cancelButtonText={'No'}>
					<div>{deleteSkusList}</div>
				</OkCancelDialogContainer>,
				250, 
				250, 
				()=>{},
				true,
				undefined,
				true,
				false,
				true));
		},
		handleCreatePurchaseOrdersClicked: () =>{
			const user = storeUtilities.getUser();
			const shipDate = moment(storeUtilities.getValue([props.screenId, panelId, 'ShipDate'], moment())).format('L');//L formats to 'MM/DD/YYYY' or 'DD/MM/YYYY' based on locale
			const arrivalDate = moment(storeUtilities.getValue([props.screenId, panelId, 'ArrivalDate'], moment())).format('L');
			const buyerInitials = storeUtilities.getValue([props.screenId, panelId, 'BuyerInitials']);
			const sendToFaxQueue = storeUtilities.getValue([props.screenId, panelId, 'SendToBvFaxQueue'], false);
			const terms = storeUtilities.getValue([props.screenId, panelId, 'Terms']);
			const shipVia = storeUtilities.getValue([props.screenId, panelId, 'ShipVia']);
			const reqConfirmation = storeUtilities.getValue([props.screenId, panelId, 'reqConfirmation'], false);
			const confirmationEmailAddress = storeUtilities.getValue([props.screenId, panelId, 'confirmationEmailAddress'], false);
			const args = { 
				poUser: user.rtgID,
				emailAddress: confirmationEmailAddress,
				shipDate,
				arrivalDate,
				buyerInitials,
				sendToFaxQueue,
				terms,
				shipVia,
				reqConfirmation
			};
			dispatch(callRpcAction(args, nodeRoutes.IFRService, 'CreateClearancePOs', (data) => {
				let POs;
				if(data && Array.isArray(data)){
					POs = data.map(row => {
						const vendor = row['VENDOR'];
						const error = row['ERROR'];
						if(error !== 'OK'){
							return (
								<Row noGutters={true} key={Math.random()}>
									<Col>
										{vendor}
									</Col>
									<Col>
										<span style={{color:'#dc3545'}}>ERROR: </span>{error}
									</Col>
								</Row>
							);
						}
						const key = row['PONUMBER'];
						return (
							<Row noGutters={true} key={key}>
								<Col>
									{vendor}
								</Col>
								<Col>
									{key}
								</Col>
							</Row>
						);
					});
				}
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						id={'purchaseOrdersCreatedDialog'}
						screenId={ props.screenId + '_informationDialog'} 
						title={'Information'}
						handleOkClicked={()=>{ 
							dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedWarehouseOrdersCount', (data) => {
								if(data && Array.isArray(data) && data.length === 1)
								{
									const queueCount = numberUtilities.getNumberOrDefault(data[0]['QUEUECOUNT']);
									storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceOrdering', 'QueueCount'], queueCount);
									storeUtilities.deleteValue(dispatch, [props.screenId, 'clearanceSkuData']);
									storeUtilities.deleteValue(dispatch, [props.screenId, 'whsNum']);
									dispatch(closeDialogAction());
								}
							}, false));
						}}
						message={'Purchase Orders Created'}
						height={300}
						width={450}>
						<Container fluid={true}>
							<Row noGutters={true} style={{borderBottom:'1px solid gainsboro'}}>
								<Col>
									<h5>Vendor</h5>
								</Col>
								<Col>
									<h5>PO Number</h5>
								</Col>
							</Row>
							{POs}
						</Container>
					</OkCancelDialogContainer>, 
					300, 
					450, 
					()=>{},
					true,
					undefined,
					true,
					false));
			}));
		}
	};
};

const deleteFromQueue = (dispatch, props, deleteRows) => {
	const panelId = props.panelId || PANEL_ID;
	var idx = 0;
	var deleteOrdIdList = ''; 
	for (idx = 0; idx < deleteRows.length; idx++) {
		if (deleteRows[idx].ORDID) {
			deleteOrdIdList += deleteRows[idx].ORDID;
			if (idx !== (deleteRows.length - 1)) {
				deleteOrdIdList += ',';
			}
		}
	}
	dispatch(callRpcAction({ordID: deleteOrdIdList}, nodeRoutes.IFRService, 'DeleteQueuedWarehouseOrders', () => {
		const user = storeUtilities.getUser();
		const args = { poUser: user.rtgID };
		dispatch(callRpcAction(args, nodeRoutes.IFRService, 'GetQueuedWarehouseOrders', (data) => {
			if (data && Array.isArray(data)) {
				storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'rowData'], data);
				storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceOrdering', 'QueueCount'], data.length);
			}
		}, false));
	}, false));
};

WarehouseOrderQueueDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	orderQueueRowData: PropTypes.array,
	onBeforeLoad: PropTypes.func,
	onLoad: PropTypes.func,
	handleCreatePurchaseOrdersClicked: PropTypes.func,
	createPurchaseOrdersButtonDisabled: PropTypes.bool,
	disableConfirmationEmail: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(WarehouseOrderQueueDialogContainer);
export default connectedComponent;
