import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { nodeRoutes } from '../../../constants';
import VendorClearanceOrderLine from '../../../models/VendorClearanceOrderLine';
import * as numberUtilities from '../../../utilities/numberUtilities';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import VendorClearanceOrderGridPanelContainer from './VendorClearanceOrderGridPanelContainer';
import {
	ButtonContainer,
	DropdownContainer,
	DropdownRpcContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';

class ClearanceScreen extends Component {
	constructor(props){
		super(props);
		this.gridRefs = {};
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	handleDivisionChanged = () => {
		if(this.props.handleDivisionChanged){
			this.props.handleDivisionChanged();
		}
	};
	handleWarehouseChanged = (value) => {
		if(this.props.handleWarehouseChanged){
			this.props.handleWarehouseChanged(value);
		}
	};
	queueStoreOrders = () => {
		if(this.props.queueStoreOrders){
			this.props.queueStoreOrders(Object.values(this.gridRefs));
		}
	};
	addSku = () => {
		if(this.props.addSku){
			this.props.addSku();
		}
	};
	viewPoOrderQueue = () => {
		if(this.props.viewPoOrderQueue){
			this.props.viewPoOrderQueue();
		}
	};
	gridRefsUpdated = (gridRef, vendorCode) => {
		this.gridRefs[vendorCode] = gridRef;
		if(this.props.gridRefsUpdated){
			this.props.gridRefsUpdated(Object.values(this.gridRefs));
		}
	};
	setRowModel = (data) => {
		const orderLine = new VendorClearanceOrderLine();
		orderLine.SKU = data.sku;
		orderLine.DESCRIPTION = data.description;
		orderLine.QOH = numberUtilities.getNumberOrDefault(data.qoh, 0);
		orderLine.QTYONORDER = numberUtilities.getNumberOrDefault(data.qtyOnOrder, 0);
		orderLine.OPENORDERS = numberUtilities.getNumberOrDefault(data.openOrders, 0);
		orderLine.T30SALES = numberUtilities.getNumberOrDefault(data.t30Sales, 0);
		orderLine.QTYONDISPLAY = numberUtilities.getNumberOrDefault(data.qtyOnDisplay, 0);
		orderLine.AVAILABLE = numberUtilities.getNumberOrDefault(data.available, 0);
		orderLine.AAO = Math.ceil((numberUtilities.getNumberOrDefault(data.t30Sales, 0) / 30) * numberUtilities.getNumberOrDefault(data.daysToStock));
		orderLine.DAYSTOSTOCK = numberUtilities.getNumberOrDefault(data.daysToStock, 0);
		orderLine.TARGETQTY = numberUtilities.getNumberOrDefault(data.targetQty, 0);
		orderLine.ORD = numberUtilities.getNumberOrDefault(data.ord, 0);
		orderLine.WHS = data.whs;
		orderLine.VENDOR = data.vendor;
		orderLine.CUBE = numberUtilities.getNumberOrDefault(data.cube, 0);
		const rowModel = {...{ROWMODEL: orderLine, ROWMODEL_ORIGINAL: cloneDeep(orderLine)}, ...data};
		return rowModel;
	};
	onModelUpdated = () => {
		if(this.props.onModelUpdated){
			this.props.onModelUpdated(this.gridRefs);
		}
	};
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasClearanceAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasClearanceAccess())){
			return <NoAccessToScreenPanel />;
		}
		const skuDataByVendor = new Map();
		if(Array.isArray(this.props.clearanceSkuData)){	
			for(let i = 0; i < this.props.clearanceSkuData.length; i++){
				const vendorCode = this.props.clearanceSkuData[i]['vendor'];
				if(!skuDataByVendor.has(vendorCode)){
					skuDataByVendor.set(vendorCode, [this.setRowModel(this.props.clearanceSkuData[i])]);
				}
				else{
					skuDataByVendor.get(vendorCode).push(this.setRowModel(this.props.clearanceSkuData[i]));
				}
			}
		}
		const skuDataByVendorIterator = skuDataByVendor.entries();
		const clearanceGridPanelsToRender = [];
		if(skuDataByVendor.size === 0){
			this.gridRefs = {};
		}
		for(let j = 0; j < skuDataByVendor.size; j++){
			const entry = skuDataByVendorIterator.next();
			if(entry === undefined || entry.value === undefined){
				break;
			}
			const panelStyle = j === 0 ? {marginTop:'3px', marginBottom:'3px'} : {marginTop:'0px', marginBottom:'3px'};
			clearanceGridPanelsToRender.push(
				<VendorClearanceOrderGridPanelContainer
					screenId={this.props.screenId}
					rowData={entry.value[1]}
					vendorCode={entry.value[0]}
					key={j}
					index={j}
					panelStyle={panelStyle}
					gridRef={(ref)=>{
						this.gridRefsUpdated(ref, entry.value[0]);
					}}
					onModelUpdated={this.onModelUpdated}
				/>);
		}
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<h3 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Clearance Ordering</h3>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'division'}
								label={'Division'}
								options={[{value:'FL', name:'Florida'},{value:'SE', name:'Southeast'},{value:'TX', name:'Texas'}]}
								onChange={this.handleDivisionChanged}
								mainComponentBootstrapSize={8}
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<DropdownRpcContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'whsNum'}
								route={nodeRoutes.IFRServiceNET}
								method={'GET'}
								label={'Store'}
								endpoint={'/Clearance/Warehouse'}
								onChange={this.handleWarehouseChanged}
								args={{div:this.props.division}}
								isReadyToFetchData={()=>{
									return this.props.division !== undefined && this.props.division.trim && this.props.division.trim() !== '';
								}}
								valueColumn={'warehouseId'}
								nameColumn={'warehouseLabel'}
								hasBlankItem={true}
								mainComponentBootstrapSize={8}
								textAlign={'center'}
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>&nbsp;</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'AddSku'} 
								text='Add Sku' 
								onClick={this.addSku}
								disabled={this.props.whsNum === undefined}
								fill
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<ButtonContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'QueueStoreOrders'} 
								text={'Queue Order For' + (this.props.whsNum === undefined ? '...' : (' ' + this.props.whsNum.split('_')[1]))} 
								onClick={this.queueStoreOrders}
								disabled={this.props.whsNum === undefined}
								fill
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<ButtonContainer 
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'viewPoOrderQueue'} 
								text={'View PO Order Queue (' + this.props.queueCount + ')'} 
								onClick={this.viewPoOrderQueue}
								fill
								store={store}
							/>
						</Col>
					</Row>
				</Card.Header>
				<div style={{overflow: 'auto'}}>
					{clearanceGridPanelsToRender}
				</div>
			</Card>
		);
	}
}

ClearanceScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	divison: PropTypes.string,
	handleDivisionChanged: PropTypes.func,
	handleWarehouseChanged: PropTypes.func,
	clearanceSkuData: PropTypes.array,
	queueStoreOrders: PropTypes.func,
	addSku: PropTypes.func,
	viewPoOrderQueue: PropTypes.func,
	disableAddSkusButton: PropTypes.bool,
	onLoad: PropTypes.func,
	queueCount: PropTypes.number,
	gridRefsUpdated: PropTypes.func,
	onModelUpdated: PropTypes.func
};

export default ClearanceScreen;