import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AltSkuCellRenderer from './AltSkuCellRenderer.jsx';
import '../../../widgets/css/NumberTextBoxCell.css';

class AltSkuCellRendererContainer extends Component {
	onFocus(){
		if(this.props.api && this.props.api.startEditingCell){
			this.props.api.startEditingCell({
				rowIndex: this.props.node.rowIndex,
				colKey: this.props.colDef.colId
			});
		}
	}
	render() {
		const fieldId = this.props.colDef.colId + '_' + this.props.node.rowIndex;
		const value = this.props.api.getCellValue({rowNode: this.props.node, colKey: this.props.colDef.colId});
		const isInvalid = this.props.getIsInvalid === undefined ? false : this.props.getIsInvalid(this.props, true);//second arg: isRenderer
		const tooltips = this.props.getTooltips ? this.props.getTooltips(this.props, true) : [];
		const disabled = this.props.getDisabled === undefined ? false : this.props.getDisabled(this.props, true);
		const hide = this.props.getIsHidden ? this.props.getIsHidden(this.props) : false;
		const tooltipPlacement = this.props.tooltipPlacement || 'left';
		return <AltSkuCellRenderer {...this.props} 
			fieldId={fieldId} 
			tooltips={tooltips}
			hide={hide}
			value={value}
			dataType={this.props.dataType}
			tooltipPlacement={tooltipPlacement}
			isInvalid={isInvalid}
			onFocus={this.onFocus.bind(this)}
			disabled={disabled}
		/>;
	}
}
AltSkuCellRendererContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	getIsInvalid: PropTypes.func,
	getIsHidden: PropTypes.func.isRequired,
	gridName: PropTypes.string.isRequired,
	tooltipPlacement: PropTypes.string,
	getTooltips: PropTypes.func,
	getDisabled: PropTypes.func,
	onLoadSkuClicked: PropTypes.func
};

export default AltSkuCellRendererContainer;