import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import { 
	ButtonContainer,
	DropdownContainer,
	MultiSelectDropdownContainer,
	TextBoxContainer 
} from '@rtg/shared-react-components';
import withID from '../../../higherordercomponents/WithID';
import store from '../../../configureStore';
import * as storeUtilities from '../../../utilities/storeUtilities';

class SearchProductsListPanel extends Component {
	constructor(props){
		super(props);
		this.onSearchClicked = this.onSearchClicked.bind(this);
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onSearchClicked = () => {
		if(this.props.onSearchClicked && !this.props.searchButtonDisabled){
			this.props.onSearchClicked();
		}
	};
	onVendorChanged = (value) => {
		if(this.props.onVendorChanged){
			this.props.onVendorChanged(value);
		}
	};
	onDivisionChanged = (values) => {
		if(this.props.onDivisionChanged){
			this.props.onDivisionChanged(values);
		}
	};
	render() {
		return (
			<Card className={'h-100 mr-3'} id={this.props.id} data-testid={this.props.id}>
				<Card.Header>
					<h5 className='text-left'>Search Product List</h5>
				</Card.Header>
				<Card.Body>
					<Row className={'mb-1'}>
						<Col>
							<MultiSelectDropdownContainer
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Region'} 
								options={this.props.divisionOptions}
								validators={[validators.required]}
								label={'Division'}
								onChange={this.onDivisionChanged}
								showRequiredAsterisk
								optionsMinWidth={'120px'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'VendorCode'}
								onEnterPressed={this.onSearchClicked}
								validators={[validators.required, (args) => validators.stringLengthIsValid({...args, message:'Vendor Code length is invalid', stringLengthChecker: (value)=>{
									return value?.trim && value.trim() !== '' && value.trim().length === 4;
								}})]}
								label={'Vendor Code'}
								showRequiredAsterisk
								store={store}
								onChange={this.onVendorChanged}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'BeginSKU'}
								onEnterPressed={this.onSearchClicked}
								validators={[(args) => validators.isNumber({...args}), (args) => validators.compareSkus({...args, beginSku: args.value, endSku: storeUtilities.getValue([this.props.screenId, 'EndSKU'])})]}
								label='Begin SKU'
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'EndSKU'}
								onEnterPressed={this.onSearchClicked}
								validators={[(args) => validators.isNumber({...args}), (args) => validators.compareSkus({...args, beginSku: storeUtilities.getValue([this.props.screenId, 'BeginSKU']), endSku: args.value})]}
								label={'End SKU'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Style'}
								onEnterPressed={this.onSearchClicked}
								label={'Style'}
								defaultTooltip={'Comma Separated'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Description'}
								onEnterPressed={this.onSearchClicked}
								label={'Description'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Color'}
								onEnterPressed={this.onSearchClicked}
								label={'Color'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Finish'}
								onEnterPressed={this.onSearchClicked}
								label={'Finish'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Family'}
								onEnterPressed={this.onSearchClicked}
								label={'Family'}
								defaultTooltip={'Comma Separated'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Factory'}
								onEnterPressed={this.onSearchClicked}
								label={'Factory'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'Theme'}
								onEnterPressed={this.onSearchClicked}
								label={'Theme'}
								defaultTooltip={'Comma Separated'}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<TextBoxContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'SortCode'}
								onEnterPressed={this.onSearchClicked} 
								label={'Sort Code'}
								store={store}
								disabled={this.props.sortCodeDisabled}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'DiscontinuedSKUS'} 
								options={[{value:'EXCLUDE', name:'Exclude'},{value:'INCLUDE', name:'Include'},{value:'ONLY', name:'Only'}]}
								label={'Discontinued'}
								disabled={this.props.discontinuedSKUSDisabled}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'UnavailableSKUS'}//i.e., "InactiveSKUS"
								options={[{value:'EXCLUDE', name:'Exclude'},{value:'INCLUDE', name:'Include'}]}
								label={'Inactive'}
								disabled={this.props.unavailableSKUSDisabled}
								store={store}
							/>
						</Col>
					</Row>
					<Row className={'mb-1'}>
						<Col>
							<DropdownContainer 
								screenId={this.props.screenId} 
								panelId={this.props.panelId}
								fieldId={'ToBeDropped'} 
								options={[{value:'EXCLUDE', name:'Exclude'},{value:'INCLUDE', name:'Include'}]}
								label={'To Be Dropped'}
								disabled={this.props.toBeDroppedDisabled}
								store={store}
							/>
						</Col>
					</Row>
					<Row className='mt-5 mb-1'>
						<Col>
							<div className={'d-flex justify-content-center'}>
								<ButtonContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'Search'} 
									text='Search'
									disabled={this.props.searchButtonDisabled}
									onClick={this.onSearchClicked}
									width={'100px'}
									store={store}
								/>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}
}

SearchProductsListPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onSearchClicked: PropTypes.func,
	searchButtonDisabled: PropTypes.bool,
	onLoad: PropTypes.func,
	divisionOptions: PropTypes.array.isRequired,
	onVendorChanged: PropTypes.func,
	onDivionChanged: PropTypes.func,
	sortCodeDisabled: PropTypes.bool,
	discontinuedDisabled: PropTypes.bool,
	inactiveDisabled: PropTypes.bool,
	toBeDroppedDisabled: PropTypes.bool
};

export default withID(SearchProductsListPanel);
