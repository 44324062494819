import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { keyCodes } from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { 
	DataGridContainer,
	LabelContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
const ROW_HEIGHT = 30;
class PurchaseOrderQueueGridPanel extends Component {
	constructor(props){
		super(props);
		this.MAX_ROWS_WITHOUT_SCROLL = 10;
		this.gridApi = undefined;
		this.onGridSizeChangedTimeout = undefined;
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getColumnValue = (params) => {
		return this.props.getColumnValue 
			? this.props.getColumnValue(params)
			: undefined;
	};
	formatColumnValue = (params) => {
		return this.props.formatColumnValue 
			? this.props.formatColumnValue(params)
			: undefined;
	};
	onModelUpdated = (params) => {
		window.setTimeout(() => {
			params.api.sizeColumnsToFit();
			this.updateTotals();
		}, 200);
	};
	onCellClicked = (params) => {
		if(this.props.onCellClicked){
			this.props.onCellClicked(params);
		}
	};
	onGridReady = (params) => {
		this.gridApi = params.api;
		params['updateTotals'] = this.updateTotals;
		if(this.props.getGridRef){
			this.props.getGridRef(params);
		}
		window.setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 1000);
		this.props.updateTotals();
	};
	updateTotals = () => {
		if(this.props.updateTotals){
			this.props.updateTotals(this.gridApi);
		}
	};
	onBodyScroll = (evt) => {
		if(this.props.setTotalsDivPosition){
			this.props.setTotalsDivPosition(evt);
		}
	};
	onGridSizeChanged = (params) => {
		if(this.onGridSizeChangedTimeout){
			window.clearTimeout(this.onGridSizeChangedTimeout);
		}
		this.onGridSizeChangedTimeout = window.setTimeout(() => {
			params.api.sizeColumnsToFit();
			window.setTimeout(() => {
				const totalsDivWidth = this.getSumOfColumns('actualWidth');
				if(this.props.setTotalsDivWidth){
					this.props.setTotalsDivWidth(totalsDivWidth);
				}
			}, 100);
		}, 500);
	};
	getSumOfColumns = (colField) => {
		if(!this.gridApi){
			return 0;
		}
		const cols = this.gridApi.getAllDisplayedColumns();
		let minWidth = 0;
		cols.forEach(col => {
			minWidth += parseInt(col[colField]);
		});
		return minWidth;
	};
	render() {
		const panelStyle = {...this.props.panelStyle};
		panelStyle['overflowX'] = 'hidden';
		const rowCount = (this.props.rowData?.length || 0) > this.MAX_ROWS_WITHOUT_SCROLL ? this.MAX_ROWS_WITHOUT_SCROLL : (this.props.rowData?.length);
		const totalsDivLeft = this.props.totalsDivLeft === 0 ? '0px' : ('-' + this.props.totalsDivLeft + 'px');
		const totalsDivWidth = this.props.totalsDivWidth;
		const margins = 58;
		const diff = window.innerWidth - (this.getSumOfColumns('minWidth') + margins);
		const horizontalScrollbarVisible = diff <= 0;
		let panelHeight = ((rowCount + 1) * ROW_HEIGHT) + 4 + (horizontalScrollbarVisible ? 17 : 0);
		return (				
			<Container style={panelStyle} fluid={true}>
				<Card>
					<Card.Header>
						<Row>
							<Col xs={11} sm={11} md={11} lg={11} xl={11}>
								<h5 className='text-left pr-2'>Vendor: {this.props.vendorCode}</h5>
								<span className='text-left pr-2'>Alias: {this.props.alias}</span>
							</Col>
						</Row>
					</Card.Header>
					<div style={{height:(panelHeight + 50) + 'px', overflowX: 'hidden'}}>
						<Container fluid={true}>
							<Row style={{height: (panelHeight) + 'px'}}>
								<Col className={'m-0 p-0'}>
									<DataGridContainer
										fieldId={''} 
										panelId={this.props.panelId}
										screenId={this.props.screenId} 
										defaultColDef={{
											resizable: true, 
											sortable: true, 
											suppressKeyboardEvent: (params)=>{ 
												return params.event.type === 'keydown' && params.event.which === keyCodes.ENTER;
											},
											valueGetter: this.getColumnValue, 
											valueFormatter: this.formatColumnValue
										}}
										rowSelection={'multiple'}
										onCellClicked={this.onCellClicked}
										columnDefs={[
											{
												headerName: 'Row Number',
												field: 'rowNumber',
												colId: 'rowNumber',
												hide: true
											},
											{
												headerName: 'poQueueRowModels',
												field: 'poQueueRowModels',
												colId: 'poQueueRowModels',
												valueFormatter: (node) => { 
													return node?.value ? JSON.stringify(node.value) : '';
												},
												hide: true
											},
											{ 
												headerName: 'SKU', 
												field: 'sku', 
												colId: 'sku',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'Description', 
												field: 'description', 
												colId: 'description',
												width: 200,
												minWidth: 200
											},
											{ 
												headerName: 'Color', 
												field: 'color', 
												colId: 'color',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'Family', 
												field: 'family', 
												colId: 'family',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'FOB', 
												field: 'fob', 
												colId: 'fob',
												width: 100,
												minWidth: 100
											},
											{ 
												headerName: 'FL', 
												field: 'flSkuQty', 
												colId: 'flSkuQty',
												width: 100,
												maxWidth: 100,
												minWidth: 100,
												suppressMovable: true,
												resizable: false,
												type: 'numericColumn'
											},
											{ 
												headerName: 'SE', 
												field: 'seSkuQty', 
												colId: 'seSkuQty',
												width: 100,
												maxWidth: 100,
												minWidth: 100,
												suppressMovable: true,
												resizable: false,
												type: 'numericColumn'
											},
											{ 
												headerName: 'TX', 
												field: 'txSkuQty', 
												colId: 'txSkuQty',
												width: 100,
												maxWidth: 100,
												minWidth: 100,
												suppressMovable: true,
												resizable: false,
												type:'numericColumn'
											},
											{ 
												headerName: 'Qty Total', 
												field: 'skuQtyTotal', 
												colId: 'skuQtyTotal',
												width: 150,
												maxWidth: 150,
												minWidth: 150,
												suppressMovable: true,
												resizable: false,
												type:'numericColumn'
											},
											{ 
												headerName: 'Cost Each', 
												field: 'firstCost', 
												colId: 'firstCost',
												width: 250,
												minWidth: 250,
												maxWidth: 250,
												type:'numericColumn'
											},
											{ 
												headerName: 'Cost Total', 
												field: 'costTotal', 
												colId: 'costTotal',
												width: 150,
												maxWidth: 150,
												minWidth: 150,
												suppressMovable: true,
												resizable: false,
												type:'numericColumn'
											}
										]}
										onModelUpdated={this.onModelUpdated.bind(this)}
										rowData={this.props.rowData}
										postSortRows={(params) => {
											let rowNumber = 1;
											params.nodes.forEach(node => {
												node.setDataValue('rowNumber', rowNumber);
												rowNumber++;
											});
											params.api.redrawRows();
										}}
										getRowClass={(params) => {
											return params.data.rowNumber % 2 === 0 ? 'grid-row-even' : 'grid-row-odd';
										}}
										onGridReady={this.onGridReady}
										sizeColumnsToFit
										store={store}
										onGridSizeChanged={this.onGridSizeChanged}
										onBodyScroll={this.onBodyScroll}
										rowHeight={ROW_HEIGHT}
									/>
								</Col>
							</Row>
							<Row>
								<div style={{width:'100%', overflowX: 'hidden'}}>
									<div style={{position: 'relative', left: totalsDivLeft, height: '50px', width: totalsDivWidth }}>
										<div className={'d-flex ml-auto'} style={{width: '1000px'}}>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'selectedTotalPOCountLabel'}
												value={'Selected PO Count: '}
												style={{fontSize: '16px', width:'140px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'flSelectedPOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'flSelectedPOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'seSelectedPOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'seSelectedPOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'txSelectedPOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'txSelectedPOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<div style={{width:'300px'}}></div>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'selectedTotalCostLabel'}
												value={'Selected Cost: '}
												style={{fontSize: '16px', width:'150px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'selectedCostTotal'}
												overridePath={[this.props.screenId, this.props.panelId, 'selectedCostTotal']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												valueFormatter={(v) => sharedUtilities.formatCurrency(v)}
												store={store}
											/>
										</div>
										<div className={'d-flex ml-auto'} style={{width: '1000px'}}>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'totalLabel'}
												value={'Total PO Count: '}
												style={{fontSize: '16px', fontWeight: 'bold', width:'140px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'flPOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'flPOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'sePOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'sePOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'txPOCount'}
												overridePath={[this.props.screenId, this.props.panelId, 'txPOCount']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												store={store}
											/>
											<div style={{width:'300px'}}></div>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'costTotalLabel'}
												value={'Total Cost: '}
												style={{fontSize: '16px', fontWeight: 'bold', width:'150px'}}
												store={store}
											/>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'costTotal'}
												overridePath={[this.props.screenId, this.props.panelId, 'costTotal']}
												type={'number'}
												useStore={true}
												style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
												valueFormatter={(v) => sharedUtilities.formatCurrency(v)}
												store={store}
											/>
										</div>
									</div>
								</div>
							</Row>
						</Container>
					</div>
				</Card>
			</Container>);
	}
}

PurchaseOrderQueueGridPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func,
	onDeleteClicked: PropTypes.func,
	onDispatchClicked: PropTypes.func,
	onModelUpdated: PropTypes.func,
	onCellClicked: PropTypes.func,
	forwardRef: PropTypes.func
};

export default PurchaseOrderQueueGridPanel;
