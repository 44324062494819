import urlJoin from 'url-join';
import * as sharedUtilities from './sharedUtilities.jsx';
import * as urlUtilities from './urlUtilities';
import * as storeUtilities from './storeUtilities';
import { loggingLevels, nodeRoutes } from '../constants';
const MAX_ATTEMPTS = 5;
const RETRYABLE_STATUS_CODES = [408, 500, 502, 503, 504];
const RETRY_WAIT_TIME_MS = 5000;
export const request = async (nodeRoute, endpoint, req, method, headers, customResponseHandler, requestInBody, retryOnFailure=true) => {
	const methodToUse = method ? method : 'GET';
	const fetchOpts = { 
		method: methodToUse,
		headers: headers !== undefined ? headers : {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	};
	let requestInBodyToUse = requestInBody;
	if(requestInBodyToUse === true && fetchOpts.method === 'DELETE'){
		console.log('WARNING: DELETE requests do not support requestInBody. Changing requestInBody to false.');
		requestInBodyToUse = false;
	}
	if(requestInBodyToUse === undefined){
		switch (fetchOpts.method){
			case 'GET':
			case 'DELETE':
				requestInBodyToUse = false;
				break;
			case 'PUT':
			case 'POST':
			case 'PATCH':
				requestInBodyToUse = true;
				break;
			default:
				requestInBodyToUse = false;
		}
	}
	const requestUrl = getRequestUrl(nodeRoute, endpoint, requestInBodyToUse === true ? undefined : req);
	if(requestUrl === undefined){
		sharedUtilities.showError('Invalid request', 'URL is invalid.');
		return;
	}
	window.console.log('REQUEST URL: ' + requestUrl + '\nMETHOD: ' + methodToUse + '\nRETRY ON FAILURE: ' + retryOnFailure);
	if(requestInBodyToUse === true){
		fetchOpts.body = JSON.stringify(req);
	}
	return await handleRequest(requestUrl, fetchOpts, 0, customResponseHandler, retryOnFailure);

};
const handleRequest = async (requestUrl, fetchOpts, currentAttemptNumber, customResponseHandler, retryOnFailure) => {
	const user = storeUtilities.getUser();
	const email = user?.email?.trim && user.email.trim() !== '' ? user.email : undefined;
	try {
		if(currentAttemptNumber >= MAX_ATTEMPTS){
			sharedUtilities.showError('Request failed after ' + MAX_ATTEMPTS + ' attempts.', 'Request URL: ' + requestUrl);
			return;
		}
		else {
			const responsePromise = await window.fetch(requestUrl, fetchOpts);
			if(retryOnFailure === true && RETRYABLE_STATUS_CODES.includes(responsePromise.status)){
				const consoleMessage = 'ERROR RESPONSE FOR: ' + requestUrl + '\nSTATUS CODE: ' + responsePromise.status;
				const parsedResponse = await sharedUtilities.parseResponse(responsePromise);
				const strParsedResponse = typeof(parsedResponse) === 'object'
					? JSON.stringify(parsedResponse)
					: String(parsedResponse);

				const logMessage = consoleMessage + '\n' + strParsedResponse;
				console.log(consoleMessage + '\nRetrying request...');
				sharedUtilities.logMessage(logMessage + (email ? ('\nrtgEmail: ' + email) : ''), loggingLevels.error);
				await sharedUtilities.delay(RETRY_WAIT_TIME_MS);
				return await handleRequest(requestUrl, fetchOpts, currentAttemptNumber + 1, customResponseHandler, retryOnFailure);
			}
			else{
				const parsedResponse = await handleResponsePromise(responsePromise, customResponseHandler);
				const res = typeof(parsedResponse) === 'object'
					? await handleResponseObject(parsedResponse) 
					: parsedResponse;
				return res;
			}
		}
	}
	catch(error){
		const consoleMessage = 'ERROR UNHANDLED EXCEPTION: ' + requestUrl;
		console.log('error: ' + error);
		if(error?.message?.includes && error.message.includes('401') && error.message.includes('Unauthorized')){
			sharedUtilities.showOkCancelDialog({
				screenId:'UnauthorizedDialog',
				message: 'User is Unauthorized',
				detail: 'Click OK to re-authenticate or Cancel to stay.',
				title: 'Unauthorized',
				okClickedHandler:(() => {
					window.setTimeout(() => {
						window.location.reload();
					}, 500);
				})
			});
		}
		else {
			if(currentAttemptNumber < MAX_ATTEMPTS && retryOnFailure === true){
				console.log(consoleMessage + '\nAttempt: ' + (currentAttemptNumber + 1) + '\nRetrying request...');
				await sharedUtilities.delay(RETRY_WAIT_TIME_MS);
				return await handleRequest(requestUrl, fetchOpts, currentAttemptNumber + 1, customResponseHandler, retryOnFailure);
			}
			else{
				throw error;
			}
		}
	}
};

const handleResponsePromise = async (response, customResponseHandler) =>{
	if(customResponseHandler){
		return customResponseHandler(response);
	}
	else{
		return await sharedUtilities.parseResponse(response);
	}
};
const handleResponseObject = async (responseObj) => {
	if(responseObj !== undefined) {
		if(((responseObj.isQuery === false || responseObj.isQuery === 'false') && responseObj.statusCode === '0')
			&& (responseObj.statusDescription !== undefined
				|| responseObj.statusDescription.Message !== undefined
				|| responseObj.statusDescription.Detail !== undefined)){
			sharedUtilities.showError(responseObj.statusDescription.Message, responseObj.statusDescription.Detail);
			return;
		}
		else if((responseObj.isQuery === false || responseObj.isQuery === 'false') 
			&& responseObj.message 
			&& responseObj.status === '1'){
			sharedUtilities.showError(null, responseObj.message);
			return;
		}
		else if(responseObj.detail !== undefined && responseObj.detail.trim && responseObj.error !== undefined && responseObj.error.trim){
			sharedUtilities.showError(responseObj.error, responseObj.detail);
			return;
		}
		if(responseObj.isQuery !== undefined && (responseObj.isQuery === true || responseObj.isQuery === 'true') && responseObj.query !== undefined){
			responseObj = responseObj.query.DATA !== undefined && responseObj.query.COLUMNS !== undefined 
				? sharedUtilities.convertToJsonArray(responseObj.query, undefined, 'COLUMNS', 'DATA')
				: responseObj.query;
		}
		return responseObj;
	}
};
const getRequestUrl = (nodeRoute, endpoint, req) => {
	const user = storeUtilities.getUser();
	const request = {};
	Object.assign(request, req);
	if(nodeRoute?.value === nodeRoutes.IFRService.value && user?.email?.trim && user.email.trim() !== ''){
		request['rtgEmail'] = user.email;
	}
	if(nodeRoute?.value !== undefined){
		const strRequest = request 
			? (typeof(request) === 'object' && Object.keys(request).length > 0 
				? ('?' + urlUtilities.getQueryString(request, nodeRoute)) 
				: '') 
			: '';
		const returnUrl = endpoint !== undefined 
			? urlJoin(urlJoin('/', nodeRoute.value), endpoint) 
			: urlJoin('/', nodeRoute.value);
		if(strRequest && strRequest.trim && strRequest.trim() !== ''){
			return urlJoin(returnUrl, strRequest);
		}
		return returnUrl;
	}
	else {
		throw new Error('Invalid nodeRoute');
	}
};