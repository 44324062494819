import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import { 
	ButtonContainer,
	DatePickerContainer,
	TextBoxContainer 
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';

class SearchDailyOrdersPanel extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	handleSearchClicked(){
		if(this.props.handleSearchClicked){
			this.props.handleSearchClicked();
		}
	}
	handleClearFormClicked(){
		if(this.props.handleClearFormClicked)
			this.props.handleClearFormClicked();
	}
	render() {
		return (
			<Container fluid={true} className={'h-100'}>
				<Card className={'h-100'}>
					<Card.Header>
						<Container className={'h-100'} fluid={true}>
							<Row className={'py-1 px-0'} noGutters={true}>
								<Col>
									<h5 className={'text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'}>Search Daily Orders</h5>
								</Col>
							</Row>
						</Container>
					</Card.Header>
					<Card.Body>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'BuyerInitials'}
									label={'Buyer Initials'} 
									trimValue
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DatePickerContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'OrderDateFrom'}
									label={'Order Date From'}
									maxDate={this.props.orderDateTo}
									isRequired
									showRequiredAsterisk
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DatePickerContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'OrderDateTo'}
									label={'Order Date To'}
									minDate={this.props.orderDateFrom}
									isRequired
									showRequiredAsterisk
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'Sku'}
									label={'SKU'}
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'Vendor'}
									label={'Vendor'}
									store={store}
								/>
							</Col>
						</Row>
						<Row noGutters={true} className={'mb-3'}>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectProducts'}
									label={'Products'}
								/>
							</Col>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectParts'}
									label={'Parts'}
								/>
							</Col>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectClearance'}
									label={'Clearance'}
								/>
							</Col>
						</Row>
						<Row className={'mb-1 mt-4'}>
							<Col xs={0} sm={0} md={1} lg={2} xl={2}/>
							<Col xs={6} sm={6} md={5} lg={4} xl={4}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}		
									fieldId={'btnClearForm'} 
									text='Clear Form'
									onClick={this.handleClearFormClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={6} sm={6} md={5} lg={4} xl={4}>
								<ButtonContainer
									screenId={this.props.screenId}		
									panelId={this.props.panelId}
									fieldId={'btnSearch'} 
									text='Search' 
									onClick={this.handleSearchClicked.bind(this)}
									fill
									store={store}
								/>
							</Col>
							<Col xs={0} sm={0} md={1} lg={2} xl={2}/>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		);
	}
}
SearchDailyOrdersPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	onLoad: PropTypes.func,
	handleSearchClicked: PropTypes.func,
	handleClearFormClicked: PropTypes.func,
	orderDateFrom: PropTypes.instanceOf(Date),
	orderDateTo: PropTypes.instanceOf(Date)
};

export default SearchDailyOrdersPanel;