import * as sharedUtilities from '../../../utilities/sharedUtilities';
import PdfGridCellIcon from '../../formelements/ImageComponents/PdfGridCellIcon';
const comparator = (date1, date2, node1, node2, isInverted) => {
	return sharedUtilities.compareDates(date1, date2, isInverted);
};
const searchDailyOrdersResultsGridColumnDefs = (screenId, panelId, gridName, onPdfIconClick=()=>{}, getPdfCellIsHidden) => {
	return [
		{
			headerName: 'SKU',
			field: 'sku',
			colId: 'sku',
			width: 120,
			minWidth: 120
		},
		{
			headerName: 'Warehouse',
			field: 'whs',
			colId: 'whs',
			width: 60,
			minWidth: 60
		},
		{
			headerName: 'Division',
			field: 'div',
			colId: 'div',
			width: 50,
			minWidth: 50
		},
		{
			headerName: 'PO Number',
			field: 'poNum',
			colId: 'poNum',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'QTY',
			field: 'qty',
			colId: 'qty',
			width: 50,
			minWidth: 50,
			type: 'numericColumn'
		},
		{
			headerName: 'Order QTY',
			field: 'orderQty',
			colId: 'orderQty',
			width: 80,
			minWidth: 80,
			type: 'numericColumn'
		},
		{
			headerName: 'Grouping',
			field: 'grp',
			colId: 'grp',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Buyer',
			field: 'buyerInitials',
			colId: 'buyerInitials',
			width: 70,
			minWidth: 70
		},
		{
			headerName: 'Days To Stock',
			field: 'daysToStock',
			colId: 'daysToStock',
			width: 110,
			minWidth: 110,
			type: 'numericColumn'
		},
		{
			headerName: 'DTNO',
			field: 'daysToNextOrder',
			colId: 'daysToNextOrder',
			width: 140,
			minWidth: 140,
			type: 'numericColumn'
		},
		{
			headerName: 'Days to Next Order',
			field: 'userDaysToNextOrder',
			colId: 'userDaysToNextOrder',
			width: 90,
			minWidth: 90,
			type: 'numericColumn'
		},
		{
			headerName: 'PO Flag',
			field: 'poFlag',
			colId: 'poFlag',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Exclude Promo Flag',
			field: 'promoFlag',
			colId: 'promoFlag',
			width: 160,
			minWidth: 160
		},
		{
			headerName: 'Prod Time',
			field: 'prodLeadTime',
			colId: 'prodLeadTime',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Transit Time',
			field: 'transLeadTime',
			colId: 'transLeadTime',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Lead Time',
			field: 'leadTime',
			colId: 'leadTime',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Num Sales Days',
			field: 'numSalesDays',
			colId: 'numSalesDays',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Max Cube',
			field: 'maxCube',
			colId: 'maxCube',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'PO Cube',
			field: 'poCube',
			colId: 'poCube',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Date',
			field: 'ordDate',
			colId: 'ordDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Ship Date',
			field: 'shipDate',
			colId: 'shipDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Arrival Date',
			field: 'arrivalDate',
			colId: 'arrivalDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Vendor',
			field: 'vendor',
			colId: 'vendor',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Combine WHS List',
			field: 'combineWhsList',
			colId: 'combineWhsList',
			width: 100,
			minWidth: 100
		},
		{
			headerName: 'Begin Sales Date',
			field: 'salesBeginDate',
			colId: 'salesBeginDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'End Sales Date',
			field: 'salesEndDate',
			colId: 'salesEndDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Cube',
			field: 'cube',
			colId: 'cube',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Avg Daily Sales',
			field: 'averageDailySales',
			colId: 'averageDailySales',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Weekly Sales',
			field: 'weeklySales',
			colId: 'weeklySales',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Total Sales',
			field: 'totalSales',
			colId: 'totalSales',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QOH',
			field: 'qoh',
			colId: 'qoh',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Inbound',
			field: 'inbound',
			colId: 'inbound',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Available',
			field: 'available',
			colId: 'available',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Promo Avail',
			field: 'promoAvail',
			colId: 'promoAvail',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Weeks of Stock',
			field: 'weeksofStock',
			colId: 'weeksofStock',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Std. Deviation',
			field: 'standardDeviation',
			colId: 'standardDeviation',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Safety Stock',
			field: 'safetyStock',
			colId: 'safetyStock',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Replenishment Stock',
			field: 'replenishmentStock',
			colId: 'replenishmentStock',
			width: 150,
			minWidth: 150,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Point',
			field: 'orderPoint',
			colId: 'orderPoint',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Weeks of Stock on Hand',
			field: 'weeksofStockOnHand',
			colId: 'weeksofStockOnHand',
			width: 180,
			minWidth: 180,
			type: 'numericColumn'
		},
		{
			headerName: 'Available Today',
			field: 'availableToday',
			colId: 'availableToday',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QTY to WHS',
			field: 'qtyToWHS',
			colId: 'qtyToWHS',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'QTY from WHS',
			field: 'qtyFromWHS',
			colId: 'qtyFromWHS',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Store Qty on Hand',
			field: 'storeQuantityOnHand',
			colId: 'storeQuantityOnHand',
			width: 140,
			minWidth: 140,
			type: 'numericColumn'
		},
		{
			headerName: 'Piece Count',
			field: 'pieceCount',
			colId: 'pieceCount',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'AAO',
			field: 'availableAfterOrder',
			colId: 'availableAfterOrder',
			width: 160,
			minWidth: 160,
			type: 'numericColumn'
		},
		{
			headerName: 'Order Ratio',
			field: 'orderRatio',
			colId: 'orderRatio',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Intradivisional',
			field: 'intradivisional',
			colId: 'intradivisional',
			width: 130,
			minWidth: 130,
			type: 'numericColumn'
		},
		{
			headerName: 'Send as Fax',
			field: 'sendAsFax',
			colId: 'sendAsFax',
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Style',
			field: 'style',
			colId: 'style',
			width: 80,
			minWidth: 80
		},
		{
			headerName: 'Need By Date',
			field: 'needByDate',
			colId: 'needByDate',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Date Created',
			field: 'createdDateTime',
			colId: 'createdDateTime',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Last Modified',
			field: 'lastModifiedDateTime',
			colId: 'lastModifiedDateTime',
			width: 130,
			minWidth: 130,
			comparator
		},
		{ 
			headerName: 'PO', 		
			field: 'pdfUrl',			
			colId: 'pdfUrl',
			cellRenderer: PdfGridCellIcon, 
			cellRendererParams:
			{
				height: '25px', 
				width: '25px',
				onClick: onPdfIconClick,
				getIsHidden: getPdfCellIsHidden,
				screenId
			},
			width:70,
			minWidth:70,
			maxWidth:70
		},		
		{
			headerName: 'Sent to Email',
			field: 'recipient',
			colId: 'recipient',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Sent to CC',
			field: 'cc',
			colId: 'cc',
			width: 130,
			minWidth: 130
		},
		{
			headerName: 'Date Sent',
			field: 'dateSent',
			colId: 'dateSent',
			width: 130,
			minWidth: 130,
			comparator
		},
		{
			headerName: 'Over Allocation',
			field: 'overAllocation',
			colId: 'overAllocation',
			width: 130,
			minWidth: 130
		}
	];
};
export default searchDailyOrdersResultsGridColumnDefs;