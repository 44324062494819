import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import rpcAction from '../../../actions/rpcAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import { nodeRoutes } from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../../utilities/storeUtilities';
import SearchProductsListPanel from './SearchProductsListPanel';

class SearchProductsListPanelContainer extends Component {
	render() {
		return (
			<SearchProductsListPanel {...this.props} />
		);
	}
}

const mapStateToProps = (state, props) => {
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const vendorCode = storeUtilities.getValue([props.screenId, 'VendorCode'], undefined);
	const vendorEmailInfos = storeUtilities.getValue([props.screenId, 'VendorEmailInfos'], undefined);
	const searchButtonHasValidationErrors = validationErrors !== undefined && (
		'Region' in validationErrors
	|| 	'VendorCode' in validationErrors
	|| 	'BeginSKU' in validationErrors
	|| 	'EndSKU' in validationErrors);
	const searchButtonDisabled = !sharedUtilities.vendorHasValidVendorEmailInfos(vendorCode, vendorEmailInfos) || searchButtonHasValidationErrors;
	const selectedDivisions = storeUtilities.getValue([props.screenId, 'Region'], []);
	const divisionOptions = sharedUtilities.getMultiSelectDivisionOptions(selectedDivisions);
	const isSavannah = selectedDivisions.includes('FL') && selectedDivisions.includes('SE');
	return {
		searchButtonDisabled,
		divisionOptions,
		sortCodeDisabled: isSavannah,
		discontinuedSKUSDisabled: isSavannah,
		unavailableSKUSDisabled: isSavannah,
		toBeDroppedDisabled: isSavannah
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(updateDefaultValuesAction([props.screenId], props.defaultValues));
		},
		onSearchClicked: () => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'ProductSearchResultsGrid', 'selectedRows']);
			storeUtilities.deleteValue(dispatch, [props.screenId, 'ProductSearchResultsGrid', 'rowData']);
			const selectedRegions = storeUtilities.getValue([props.screenId, 'Region'], []);
			const vendorCode = storeUtilities.getValue([props.screenId, 'VendorCode']);
			const region = selectedRegions.length === 2 && selectedRegions.includes('FL') && selectedRegions.includes('SE') ? 'FL' : (selectedRegions.length === 1 ? selectedRegions[0] : undefined);

			let BeginSKU = storeUtilities.getValue([props.screenId, 'BeginSKU']);
			let EndSKU = storeUtilities.getValue([props.screenId, 'EndSKU']);

			if (!isNaN(BeginSKU) && !isNaN(EndSKU) && Number(BeginSKU) > Number(EndSKU)){ 
				let temp = BeginSKU;
				BeginSKU = EndSKU;
				EndSKU = temp;
			}
			if(vendorCode.trim && vendorCode.trim().length === 4 && selectedRegions.length > 0){
				const args = {
					BeginSKU: BeginSKU,
					Color: storeUtilities.getValue([props.screenId, 'Color']),
					Description: storeUtilities.getValue([props.screenId, 'Description']),
					EndSKU: EndSKU,
					Family: storeUtilities.getValue([props.screenId, 'Family']),
					Finish: storeUtilities.getValue([props.screenId, 'Finish']),
					SortCode: storeUtilities.getValue([props.screenId, 'SortCode'], 'ALL'),
					Style: storeUtilities.getValue([props.screenId, 'Style']),
					VendorCode: storeUtilities.getValue([props.screenId, 'VendorCode']),
					Division: region,
					Discontinued: storeUtilities.getValue([props.screenId, 'DiscontinuedSKUS']),
					Inactive: storeUtilities.getValue([props.screenId, 'UnavailableSKUS']),
					Factory: storeUtilities.getValue([props.screenId, 'Factory']),
					Theme: storeUtilities.getValue([props.screenId, 'Theme']),
					ToBeDropped: storeUtilities.getValue([props.screenId, 'ToBeDropped'])
				};
				const prunedArgs = sharedUtilities.pruneObject(args);
				dispatch(rpcAction({
					args: prunedArgs,
					nodeRoute: nodeRoutes.IFRServiceNET,
					endpoint: '/Products/Skus',
					method: 'GET',
					callback: (rowData) => {
						if(Array.isArray(rowData)){
							storeUtilities.updateValue(dispatch, [props.screenId, 'VendorCodeInUse'], vendorCode);
							storeUtilities.updateValue(dispatch, [props.screenId, 'ProductSearchResultsGrid', 'rowData'], rowData, true);
							const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizeProductSearchResultsGridColumnsToFit'], undefined);
							if(sizeColumnsToFit !== undefined){
								sizeColumnsToFit();
							}
						}
					}
				}));
			}
		},
		onVendorChanged: (vendorId) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'VendorEmailInfos']);
			if(vendorId?.length === 4)
			{
				const selectedRegions = storeUtilities.getValue([props.screenId, 'Region'], []);
				sharedUtilities.getVendorEmailInfos(dispatch, [props.screenId, 'VendorEmailInfos'], selectedRegions, vendorId, 
					async (response)=>{
						if(response.status === 404){
							return '';
						}
						else {
							return await sharedUtilities.parseResponse(response);
						}
					}, true);
			}
		},
		onDivisionChanged: (selectedRegions) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'VendorEmailInfos']);
			const paths = [
				[props.screenId, 'SortCode'],
				[props.screenId, 'DiscontinuedSKUS'],
				[props.screenId, 'UnavailableSKUS'],
				[props.screenId, 'ToBeDropped']
			];
			if(selectedRegions.length === 2 && selectedRegions.includes('FL') && selectedRegions.includes('SE')){
				storeUtilities.stashValues(dispatch, paths);
				//overwrite with new values
				storeUtilities.updateValue(dispatch, [props.screenId, 'SortCode'], 'ALL');
				storeUtilities.updateValue(dispatch, [props.screenId, 'DiscontinuedSKUS'], 'EXCLUDE');
				storeUtilities.updateValue(dispatch, [props.screenId, 'UnavailableSKUS'], 'EXCLUDE');
				storeUtilities.updateValue(dispatch, [props.screenId, 'ToBeDropped'], 'EXCLUDE');
			}
			else {
				storeUtilities.restoreStashedValues(dispatch, paths);
			}
			const vendorId = storeUtilities.getValue([props.screenId, 'VendorCode']);
			if(vendorId?.length === 4){
				sharedUtilities.getVendorEmailInfos(dispatch, [props.screenId, 'VendorEmailInfos'], selectedRegions, vendorId);
			}
		}
	};
};
SearchProductsListPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchProductsListPanelContainer);

connectedComponent.defaultProps = {
	defaultValues: {
		Region: ['FL'],
		DiscontinuedSKUS: 'EXCLUDE',
		UnavailableSKUS: 'EXCLUDE',
		ToBeDropped: 'EXCLUDE',
		SortCode: 'ALL'
	}
};

export default connectedComponent;