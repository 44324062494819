import * as numberUtilities from '../utilities/numberUtilities';
import converter from 'number-to-words';
import moment from 'moment';
import emailValidator from 'email-validator';
/**
 * This is a collection of common validators.
 * If you need a validator that is not in this file, feel free to add it here.
 * 
 * Remember: this is a list of *common* validators...i.e., validators that are likely to be used by other components.
 * If you require a highly specific validator that is unlikely to ever be used anywhere else, just define it in-line as
 * one of the validators in the array of validators on your component's validators prop.
 * 
 * NOTE: Each validator should validate a value and should:
 * Return undefined if the value is valid.
 * Return a string explaining why value is invalid.
 */
export const required = (args) => {
	//Note: this does not work for DatePickers. Use the prop "isRequired" for DatePickers
	const { value, label, message } = args;
	const isValid = (value !== undefined && (value.toString === undefined || value.toString().trim() !== ''));
	const defaultMessage = 'Field is required';
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' is required';
	}
	return isValid === true ? undefined : msg;
};
export const inRange = (args) => {
	const { value, lowerLimit, upperLimit, lowerLimitInclusive=true, upperLimitInclusive=true, label, message } = args;
	if(isNaN(value) 
		|| (value.toString && value.toString().trim() === '')
		|| isNaN(lowerLimit) 
		|| isNaN(upperLimit)){
		/**NOT validating whether value is given...use the "required" validator in combination with this one if you want to also enforce a value */
		return undefined;
	}
	const _value = Number(value);
	const _lowerLimit = Number(lowerLimit);
	const _upperLimit = Number(upperLimit);
	let isValid = true;// assume it is valid because we are validating in steps.
	
	if(lowerLimitInclusive === true){
		isValid = isValid && (_value >= _lowerLimit);
	}
	else {
		isValid = isValid && (_value > _lowerLimit);
	}

	if(upperLimitInclusive === true){
		isValid = isValid && (_value <= _upperLimit);
	}
	else {
		isValid = isValid && (_value < _upperLimit);
	}

	const defaultMessage = 'alue is not in range ' 
		+ (lowerLimitInclusive === true ? '[' : '(') 
		+ _lowerLimit 
		+ ', ' 
		+ _upperLimit 
		+ (upperLimitInclusive === true ? ']' : ')');
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = 'V' + message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' v' + defaultMessage;
	}
	return isValid === true ? undefined : msg;
};
export const isDivisibleBy = (args, divisor) => {
	const { value, label, message } = args;
	const numValue = numberUtilities.getNumberOrDefault(value);
	const isDivisible = (numValue % 8 === 0);
	const defaultMessage = 'Value must be a multiple of ' + converter.toWords(divisor);
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' must be a multiple of ' + converter.toWords(divisor);
	}
	return isDivisible === true ? undefined : msg;
};
export const isInDateRange = (args) => {
	const { value, label, minDate, maxDate } = args;
	let _label = '';
	if(value === undefined){
		return undefined;
	}
	if(label === undefined){
		_label = 'Date';
	}
	else {
		_label = label;
	}
	if(_label.toLowerCase().endsWith('date') === false){
		_label += ' date';
	}
	if(minDate !== undefined && maxDate !== undefined){
		const valueMoment = moment(value);
		const maxMoment = moment(maxDate);
		const minMoment = moment(minDate);
		return (valueMoment.isSameOrAfter(minMoment) && valueMoment.isSameOrBefore(maxMoment))?undefined : _label + ' is out of range (min: ' + minMoment.format('M/D/YYYY') + ', max: ' + maxMoment.format('M/D/YYYY') + ')';
	}
	else if (minDate !== undefined){
		const valueMoment = moment(value);
		const minMoment = moment(minDate);
		const isSameOrAfter = valueMoment.isSameOrAfter(minMoment);
		const ret = (isSameOrAfter) ? undefined : _label + ' cannot be before ' + minMoment.format('M/D/YYYY');
		return ret;
	}
	else if (maxDate !== undefined){
		const valueMoment = moment(value);
		const maxMoment = moment(maxDate);
		const isSameOrBefore = valueMoment.isSameOrBefore(maxMoment);
		const ret = (isSameOrBefore) ? undefined : _label + ' cannot be after ' + maxMoment.format('M/D/YYYY');
		return ret;
	}
	return undefined;
};
export const isValidEmail = (args) => {
	const { value, label, message } = args;
	const isValid = emailValidator.validate(value);
	const defaultMessage = 'Email is invalid';
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' is invalid';
	}
	return isValid === true ? undefined : msg;
};
export const stringLengthIsValid = (args) => {
	const { value, label, message, stringLengthChecker } = args;
	const isValid = stringLengthChecker(value);
	const defaultMessage = 'Value is invalid';
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' is invalid';
	}
	return isValid === true ? undefined : msg;
};
export const compareSkus = (args) => {
	const { beginSku, endSku } = args;
	return isEndSkuGreaterThanBeginSku(beginSku, endSku) === true ? undefined : 'End SKU must be greater than Begin SKU';
};

export const isEndSkuGreaterThanBeginSku = (beginSku, endSku) => {
	let returnVal = true;
	if ((beginSku != undefined && beginSku != '') && (endSku != undefined && endSku != '')) {
		if (!isNaN(beginSku) && !isNaN(endSku) && Number(beginSku) > Number(endSku)) {
			returnVal = false;
		}
	} 
	return returnVal;
};

export const isNumber = (args) => {
	const { value, label, message } = args;
	const isValid = !isNaN(value) || value == undefined || value == null;
	const defaultMessage = 'Value must be a number';
	let msg = defaultMessage;
	if(message !== undefined && message.toString && message.toString().trim().length > 0){
		msg = message;
	}
	else if(label !== undefined && label.toString && label.toString().trim().length > 0){
		msg = label + ' must be a number';
	}
	return isValid === true ? undefined : msg;
};