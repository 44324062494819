import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import SuggestedPOGridPanelContainer from './SuggestedPOGridPanelContainer';
import EditPurchaseOrderIconButton from '../orderproductsscreen/EditPurchaseOrderIconButton';
import { 
	ButtonContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
const DEFAULT_ICON_HEIGHT = 20;
const DEFAULT_ICON_WIDTH = 20;
const PO_PANEL_HEIGHT = 400;
class SuggestedPOsPanel extends Component {
	constructor(props) {
		super(props);
		this.formatCurrency = this.formatCurrency.bind(this);
		this.createRow = this.createRow.bind(this);
		this.rowRenderer = this.rowRenderer.bind(this);
		this.state = { viewPort : 'xl', rowCount: 0, rowWidth: 400 };
		this.posPanelRef = React.createRef();
	}
	componentDidUpdate(prevProps){
		if(prevProps.suggestedPos.size !== this.props.suggestedPos.size || this.props.viewPort !== this.state.viewPort){
			let poPanelWidth = 400;
			let rowWidth = poPanelWidth;
			let rowCount = this.props.suggestedPos.size; //default
			if(this.props.viewPort === 'xs'){
				rowCount /= 2;
				rowWidth = poPanelWidth * 2;
			}
			else if(this.props.viewPort === 'sm'){
				rowCount /= 3;
				rowWidth = poPanelWidth * 3;
			}
			else if (this.props.viewPort === 'md') {
				rowCount /= 3;
				rowWidth = poPanelWidth * 3;
			}
			else if (this.props.viewPort === 'lg') {
				rowCount /= 3;
				rowWidth = poPanelWidth * 3;
			}
			else if (this.props.viewPort === 'xl') {
				rowCount /= 4;
				rowWidth = poPanelWidth * 4;
			}
			rowCount = Math.ceil(rowCount);
			this.setState({rowWidth, rowCount, viewPort: this.props.viewPort});
		}
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	editEmailsClicked(){
		if(this.props.editEmailsClicked){
			this.props.editEmailsClicked();
		}
	}
	createPurchaseOrders() {
		if (this.props.createPurchaseOrders)
			this.props.createPurchaseOrders();
	}
	formatCurrency(params) {
		if (this.props.formatCurrency) {
			return this.props.formatCurrency(params.value);
		}
		return params.value;
	}
	onSuggestedPosTotalCostChange(costDiff){
		if(this.props.onSuggestedPosTotalCostChange){  
			this.props.onSuggestedPosTotalCostChange(costDiff);  
		}
	}
	createRow(numberOfPOsInRow, rowIndex) {
		const posToRender = [];
		const poIterator = this.props.suggestedPos.entries();//reset the iterator
		/** determine the starting po number we need for the row*/
		const firstPoIteratorIndex = numberOfPOsInRow * rowIndex;
		for (let i = 0; i < firstPoIteratorIndex; i++) {
			poIterator.next();
		}

		if(this.props.suggestedPos.size === 0){
			return;
		}
		for (let i = 0; i < numberOfPOsInRow; i++) {
			const iteration = poIterator.next();
			const iterationValue = iteration !== undefined && iteration.value !== undefined ? iteration.value : undefined;

			if(iterationValue !== undefined){
				const suggestedPO = iterationValue[1];//the po
				posToRender.push(
					<Col key={suggestedPO.poNumber}
						className={'p-0 m-0 SuggestedPO-border'}>
						<SuggestedPOGridPanelContainer
							screenId={this.props.screenId}
							panelId={'suggestedPOGridPanel'}
							poNumber={suggestedPO.poNumber}
							key={suggestedPO.poNumber}
							path={[this.props.screenId, 'SuggestedPOs', 'purchaseOrders', suggestedPO.poNumber]}
							onSuggestedPosTotalCostChange={this.onSuggestedPosTotalCostChange.bind(this)}
							scrollContainerRef={this.posPanelRef !== undefined ? this.posPanelRef.current : undefined}
							divisions={this.props.divisions}
						/>
					</Col>);
			}
			else {
				posToRender.push(
					<Col className={'px-0'} key={'rowKey_' + rowIndex + '_positionIndexInRow_' + i}>
						<div>
							&nbsp;
						</div>
					</Col>);
			}
		}
		return (
			<div className={'row'} style={{height:(PO_PANEL_HEIGHT + 'px'), width: '100%', marginLeft: '1px', marginBottom: '2px' }} key={rowIndex}>
				{posToRender}
			</div>
		);
	}
	rowRenderer(index) {
		switch (this.state.viewPort) {
			case 'xs':
				return this.createRow(2, index);
			case 'sm':
				return this.createRow(3, index);
			case 'md':
				return this.createRow(3, index);
			case 'lg':
				return this.createRow(3, index);
			case 'xl':
				return this.createRow(4, index);
			default:
				return this.createRow(1);
		}
	}
	render() {
		const panelStyle = { height: (PO_PANEL_HEIGHT + 4) + 'px', overflowY: 'auto', overflowX: 'hidden'};
		var rowsToRender = [];
		for(var rowIndex = 0; rowIndex < this.state.rowCount; rowIndex++){
			rowsToRender.push(this.rowRenderer(rowIndex));
		}
		if (this.props.rowData && this.props.rowData.length > 0)
		 	panelStyle['backgroundColor'] = 'rgba(0,0,0,0.2)';
		return (
			<Card>
				<Card.Header>
					<Container className={'h-100'} fluid={true}>
						<Row className={'py-1 px-0'} noGutters={true}>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<Row>
									<h5 className={'text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'}>Suggested POs&nbsp;{this.props.poCount !== undefined && this.props.poCount.trim !== undefined && this.props.poCount.trim() !== '' ? ('(' + this.props.poCount + ')') : ''}</h5>
								</Row>
								<Row>
									<div className={'pb-1 text text-muted'}>
											Total Cost: <span id={this.props.screenId+'_totalCost'}>{this.formatCurrency({value:this.props.totalCost})}</span>
									</div>
								</Row>
							</Col>
							<Col xs={8} sm={8} md={8} lg={8} xl={8} className={'pl-5 pt-3'}>
									&nbsp;
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnCreatePOs'}
									text={'Create POs'}
									disabled={this.props.disablePanel || this.props.disableBtnCreatePOs}
									onClick={this.createPurchaseOrders.bind(this)}
									height={'50px'}
									fill 
									store={store}
								/>
							</Col>
						</Row>
					</Container>
				</Card.Header>
				<div style={panelStyle} ref={this.posPanelRef}>
					{rowsToRender}
				</div>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<Row>
									<Col xs={2} sm={2} md={1} lg={1} xl={1}>
										<EditPurchaseOrderIconButton 
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId={'recipientsEditButton'}
											onClick={this.editEmailsClicked.bind(this)}
											height={DEFAULT_ICON_HEIGHT}
											width={DEFAULT_ICON_WIDTH}
											tooltipPlacement={'top'}
											tooltips={[]}
										/>
									</Col>
									<Col xs={10} sm={10} md={8} lg={8} xl={8}>
										<div align="left">
											<TextBoxContainer
												screenId={this.props.screenId}
												panelId={this.props.panelId}
												fieldId={'recipients'}
												label={'Email Address'}
												width={'200px'}
												store={store} 
											/>
										</div>
									</Col>
								</Row>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'sendToFaxQueue'}
									label={'Send to BluVista Fax Queue'}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'reqConfirmation'}
									label={'Request Email Confirmation'}
									width={'155px'}
									mainComponentBootstrapSize={1}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'confirmationEmailAddress'}
									label={'Confirmation Email Address'}
									width={'200px'}
									showRequiredAsterisk
									validators={[validators.required, validators.isValidEmail]}
									store={store} 
								/>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		);
	}
}

SuggestedPOsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	division: PropTypes.string,
	disablePanel: PropTypes.bool,
	rowData: PropTypes.array,
	isDialogOpen: PropTypes.bool,
	createPurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	disableBtnPreviewInFlow: PropTypes.bool,
	additionalEmails: PropTypes.string,
	formatCurrency: PropTypes.func,
	totalCost: PropTypes.number,
	suggestedPos: PropTypes.object,
	onLoad: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	poCount: PropTypes.number,
	datePickerPopupLeft: PropTypes.number,
	windowSize: PropTypes.object,
	editEmailsClicked: PropTypes.func
};
export default SuggestedPOsPanel;