import { DataGridContainer } from '@rtg/shared-react-components';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import store from '../../../configureStore';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import ClearanceOrderQueueDialog from './ClearanceOrderQueueDialog';
import { RequestTypes, nodeRoutes } from '../../../constants';
import rpcAction from '../../../actions/rpcAction';
import { flattenResponse } from '../../../utilities/sharedUtilities';
export const PANEL_ID = 'clearanceOrderQueueDialog';
const OPEN_CLOSE_DIALOG_WAIT_MS = 300;
class ClearanceOrderQueueDialogContainer extends Component {
	render() {
		const panelId = this.props.panelId || PANEL_ID;
		return (
			<ClearanceOrderQueueDialog {...this.props}
				panelId={panelId}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const panelId = props.panelId || PANEL_ID;
	const rowData = storeUtilities.getValue([props.screenId, panelId, 'rowData'], []);
	const reqConfirmation = storeUtilities.getValue([props.screenId, panelId, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId, panelId]);
	const selectedRowCount = storeUtilities.getValue([props.screenId, panelId, 'selectedRowCount'], 0);
	const createPurchaseOrdersButtonDisabled = validationErrors === undefined 
		|| 'BuyerInitials' in validationErrors
		|| 'Terms' in validationErrors
		|| 'ShipVia' in validationErrors
		|| 'ShipDate' in validationErrors
		|| 'ArrivalDate' in validationErrors
		|| ('confirmationEmailAddress' in validationErrors && reqConfirmation === true)
		|| rowData.length === 0
		|| selectedRowCount === 0;
	const removeFromQueueButtonDisabled = selectedRowCount === 0;
	return {
		rowData,
		createPurchaseOrdersButtonDisabled,
		removeFromQueueButtonDisabled,
		disableConfirmationEmail: reqConfirmation === false
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const panelId = props.panelId || PANEL_ID;
	return {   
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			const defaultValues = {
				confirmationEmailAddress: userEmail
			};
			dispatch(updateDefaultValuesAction([props.screenId, panelId], defaultValues));
			window.setTimeout(()=>{
				dispatch(rpcAction({
					args: { poUser: user.rtgID },
					nodeRoute: nodeRoutes.IFRServiceNET,
					endpoint: '/Clearance/QueuedClearanceOrders',
					method: 'GET',
					callback: (data) => {
						if (data && Array.isArray(data.clearanceQueues)) {
							storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'rowData'], data.clearanceQueues);
						}
					}
				}));
			}, OPEN_CLOSE_DIALOG_WAIT_MS);
		},
		handleCloseClicked: () => {
			window.setTimeout(() => {
				storeUtilities.deleteValue(dispatch, [props.screenId, panelId]);
			}, OPEN_CLOSE_DIALOG_WAIT_MS);
			dispatch(closeDialogAction());
		},
		handleDeleteClicked: (gridApi) => {
			const selectedRows = gridApi.getSelectedRows();
			if(selectedRows.length > 0){
				const deleteSkuDivs = [];
				selectedRows.forEach(row => {
					deleteSkuDivs.push(<div key={row.id}>{row.sku}<br/></div>);
				});
				dispatch(showDialogAction(
					<OkCancelDialogContainer
						screenId={props.screenId} 
						title={'Confirmation'}
						handleOkClicked={()=>{
							deleteFromQueue(dispatch, props, panelId, selectedRows);
						}}
						message={<div>Are you sure you want to <b><u>REMOVE</u></b> these SKUs from the queue?</div>}
						hasCancelButton
						okButtonText={'Yes'}
						cancelButtonText={'No'}>
						<div>{deleteSkuDivs}</div>
					</OkCancelDialogContainer>,
					250, 
					250, 
					()=>{},
					true,
					undefined,
					true,
					false,
					true));
			}
			else {
				dispatch(showDialogAction(
					<OkCancelDialogContainer
						screenId={props.screenId} 
						title={'Information'}
						handleOkClicked={()=>{}}
						message={'No SKUs selected for deletion.'}>
					</OkCancelDialogContainer>,
					250, 
					250, 
					()=>{},
					true,
					undefined,
					true,
					false));
			}
		},
		handleCreatePurchaseOrdersClicked: (gridApi) =>{
			const selectedNodes = gridApi.getSelectedNodes();
			const selectedSkuDivs = [];
			selectedNodes.forEach(node => {
				selectedSkuDivs.push(<div key={node.data.id}>{node.data.sku}<br/></div>);
			});
			dispatch(showDialogAction(
				<OkCancelDialogContainer
					screenId={props.screenId} 
					title={'Confirmation'}
					handleOkClicked={()=>{
						const user = storeUtilities.getUser();
						const shipDate = moment(storeUtilities.getValue([props.screenId, panelId, 'ShipDate'], moment())).format('YYYY-MM-DD');//L formats to 'MM/DD/YYYY' or 'DD/MM/YYYY' based on locale
						const arrivalDate = moment(storeUtilities.getValue([props.screenId, panelId, 'ArrivalDate'], moment())).format('YYYY-MM-DD');
						const buyerInitials = storeUtilities.getValue([props.screenId, panelId, 'BuyerInitials']);
						const sendToFaxQueue = storeUtilities.getValue([props.screenId, panelId, 'SendToBvFaxQueue'], false);
						const terms = storeUtilities.getValue([props.screenId, panelId, 'Terms']);
						const shipVia = storeUtilities.getValue([props.screenId, panelId, 'ShipVia']);
						const reqConfirmation = storeUtilities.getValue([props.screenId, panelId, 'reqConfirmation'], false);
						const confirmationEmailAddress = storeUtilities.getValue([props.screenId, panelId, 'confirmationEmailAddress'], '');
						const storeVendorOrders = {};
						if(gridApi !== undefined){
							selectedNodes.forEach(node => {
								const storeKeys = Object.keys(storeVendorOrders);
								if(storeKeys.includes(node.data.storeId) === false){
									storeVendorOrders[node.data.storeId] = {};
								}
								const vendorKeys = Object.keys(storeVendorOrders[node.data.storeId]);
								if(vendorKeys.includes(node.data.vendor) === false){
									storeVendorOrders[node.data.storeId][node.data.vendor] = [];
								}
								const ordersArray = storeVendorOrders[node.data.storeId][node.data.vendor];
								if(ordersArray.includes(node.data.sku) === false){
									if (node.data.quantity > 0) {
										ordersArray.push({
											sku: node.data.sku,
											quantity: node.data.quantity,
											ordId: node.data.id
										});
									}
								}
							});
						}
			
						const requests = [];
						const whsVendorEntries = Object.entries(storeVendorOrders);
						whsVendorEntries.forEach(([store, vendorOrders]) => {
							const storeArray = store.split('_');
							const vendorOrdersEntries = Object.entries(vendorOrders);
							vendorOrdersEntries.forEach(([vendor, orders]) => {
								if (orders.length > 0) {
									const purchaseOrders = [];
									const purchaseOrderArgs = { 
										shipDate: shipDate,
										arrivalDate: arrivalDate,
										calculatedArrivalDate: arrivalDate,
										poCube: 0,
										status: '',
										overAllocation: false,
										destinationDivision: storeArray[0],
										destinationWarehouseId: storeArray[1],
										freightOnBoard: '',
										instructions: '',
										totalPurchaseOrderValue: 0,
										skus: cloneDeep(orders)
									};
									purchaseOrders.push(purchaseOrderArgs);

									const requestArgs = {
										buyerInitials: buyerInitials,
										confirmationRequested: reqConfirmation,
										destinationDivision: storeArray[0],
										destinationWarehouseId: storeArray[1],
										emailAddress: confirmationEmailAddress,
										confirmationEmail: user.rtgID,
										systemUser: user.rtgID,
										buyerName: user.fullName,
										requestType: RequestTypes.CLEARANCE,
										sendAsFax: sendToFaxQueue,
										vendor: vendor,
										isQueued: false,
										terms: terms,
										shipVia: shipVia,
										purchaseOrders: cloneDeep(purchaseOrders)
									};
									requests.push(requestArgs);
								}
							});
						});
						callCreateClearancePOJsonBody(dispatch, props.screenId, panelId, requests, [], user.rtgID);
					}}
					message={<div>Are you sure you want to <b><u>CREATE</u></b> POs for the selected SKUs?</div>}
					hasCancelButton
					okButtonText={'Yes'}
					cancelButtonText={'No'}>
					<div>{selectedSkuDivs}</div>
				</OkCancelDialogContainer>,
				250, 
				250, 
				()=>{},
				true,
				undefined,
				true,
				false,
				true));
		},
		onRowSelected: (e) => {
			const selectedRowCount = e.api.getSelectedRows().length;
			storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'selectedRowCount'], selectedRowCount);
		}
	};
};
const callCreateClearancePOJsonBody = (dispatch, screenId, panelId, requestsArray, resultsArray, poUser) => {
	const requestsArrayClone = cloneDeep(requestsArray);
	const resultsArrayClone = cloneDeep(resultsArray);
	if(requestsArrayClone[0] !== undefined){
		dispatch(rpcAction({
			args: requestsArrayClone[0],
			nodeRoute: nodeRoutes.IFRServiceNET,
			endpoint: 'PurchaseOrdersRequest/ClearanceOrder',
			method: 'POST',
			showLoadingMask: true,
			hideLoadingMaskOnComplete: requestsArrayClone[1] === undefined,
			callback: (data) => {
				const leftoverRequests = requestsArrayClone.slice(1);
				if(data !== undefined){
					resultsArrayClone.push(Object.assign(data, {reqConfirmation: requestsArrayClone.confirmationRequested}));
				}
				callCreateClearancePOJsonBody(dispatch, screenId, panelId, leftoverRequests, resultsArrayClone, poUser);
			},
			retryOnFailure: false
		}));
	}
	else {
		const purchaseOrderNumbers = [];
		resultsArrayClone.forEach(result => {
			for (let i = 0; i < result.purchaseOrders.length; i++) {
				purchaseOrderNumbers.push(result.purchaseOrders[i].purchaseOrderNumber);
			}
		});
		var flatResponse = flattenResponse(resultsArrayClone);
		const reqConfirmation = storeUtilities.getValue([screenId, panelId, 'reqConfirmation'], false);
		const emailAddress = storeUtilities.getValue([screenId, panelId, 'confirmationEmailAddress'], '');
		dispatch(rpcAction({
			args: {purchaseOrderNumbers: purchaseOrderNumbers, emailAddress, requestConfirmation: reqConfirmation}, 
			nodeRoute: nodeRoutes.IFRServiceNET, 
			endpoint: 'Notifications/Send',
			method: 'POST',
			showLoadingMask: true,
			hideLoadingMaskOnComplete: true,
			callback: () => {
				const dataGrid = 
				<div className={'h-100 w-100'}>
					<DataGridContainer
						screenId={screenId}
						panelId={'randomPanelId'}
						fieldId={'clearanceOrderQueueDialogResults'}
						defaultColDef={{resizable: true, sortable: true}}
						columnDefs={
							[
								{ 
									headerName: 'Store', 
									field: 'warehouse', 
									colId: 'warehouse', 
									width: 70,
									minWidth: 70 
								},
								{ 
									headerName: 'Vendor', 
									field: 'vendor', 
									colId: 'vendor', 
									width: 70,
									minWidth: 70 
								},
								{ 
									headerName: 'PO Number', 
									field: 'purchaseOrderNumber', 
									colId: 'purchaseOrderNumber', 
									width: 120, 
									minWidth: 120 
								},
								{ 
									headerName: 'Errors', 
									field: 'ERROR', 
									colId: 'ERROR', 
									width: 145,
									minWidth: 145
								}
							]
						}
						rowData={flatResponse}
						store={store}
					/>
				</div>;
				if(resultsArrayClone.length > 0){
					dispatch(showDialogAction(
						<OkCancelDialogContainer 
							id={'purchaseOrdersCreatedDialog'}
							screenId={ screenId + '_informationDialog'} 
							title={'Purchase Orders Created'}
							style={{paddingLeft: '0px', paddingRight: '0px'}}
							handleOkClicked={()=>{ 
								dispatch(rpcAction({
									args: { poUser },
									nodeRoute: nodeRoutes.IFRServiceNET,
									endpoint: '/Clearance/QueuedClearanceOrdersCount',
									method: 'GET',
									showLoadingMask: false,
									callback: (data) => {
										const queueCount = numberUtilities.getNumberOrDefault(data.count);
										storeUtilities.updateValue(dispatch, [screenId, 'clearanceOrdering', 'QueueCount'], queueCount);
										storeUtilities.deleteValue(dispatch, [screenId, 'clearanceSkuData']);
										storeUtilities.deleteValue(dispatch, [screenId, 'whsNum']);
										dispatch(closeDialogAction());
									}
								})); 
							}}
							height={300}
							width={700}>
							{dataGrid}
						</OkCancelDialogContainer>, 
						300, 
						410, 
						()=>{},
						true,
						undefined,
						true,
						false));
				}
			}}));
	}
};

const deleteFromQueue = (dispatch, props, panelId, deleteRows) => {
	if(!Array.isArray(deleteRows) || deleteRows.length <= 0){
		return;
	}
	var deleteOrdIdList = []; 
	deleteRows.forEach(row => {
		if(!deleteOrdIdList.includes(row.id)){
			deleteOrdIdList.push(row.id);
		}
	});
	dispatch(rpcAction({args: {OrdersIdToDelete: deleteOrdIdList}, nodeRoute: nodeRoutes.IFRServiceNET, method: 'POST', endpoint: '/Clearance/DeleteQueuedOrders', callback: () => {
		const user = storeUtilities.getUser();
		dispatch(rpcAction({
			args: { poUser: user.rtgID },
			nodeRoute: nodeRoutes.IFRServiceNET,
			method: 'GET',
			endpoint: '/Clearance/QueuedClearanceOrders',
			showLoadingMask: false,
			callback: (data) => {
				if (data && Array.isArray(data.clearanceQueues)) {
					storeUtilities.updateValue(dispatch, [props.screenId, panelId, 'rowData'], data.clearanceQueues);
					storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceOrdering', 'QueueCount'], data.clearanceQueues.length);
				}
			}
		}));
	}, requestInBody: false, showLoadingMask: false}));
};

ClearanceOrderQueueDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	orderQueueRowData: PropTypes.array,
	onLoad: PropTypes.func,
	handleCreatePurchaseOrdersClicked: PropTypes.func,
	createPurchaseOrdersButtonDisabled: PropTypes.bool,
	disableConfirmationEmail: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ClearanceOrderQueueDialogContainer);

export default connectedComponent;
