import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withTooltip from '../../higherordercomponents/WithTooltip';
import RefreshIcon from './ImageComponents/RefreshIcon';

const DEFAULT_HEIGHT = 15;
const DEFAULT_WIDTH = 15;
const GREEN = '#44cc44';
const DARK_GREEN = '#44aa44';
class RefreshButton extends Component {
	constructor(props){
		super(props);
		this.getId = this.getId.bind(this);
		this.state = {
			color: 'darkgray',
			disabled: this.props.disabled,
			cursor: 'auto'
		};
	}
	onMouseOver(){
		if(this.state.disabled === true)
			return;
		this.setState({color: GREEN, cursor: 'pointer'});
	}
	onMouseOut(){
		if(this.state.disabled === true)
			return;
		this.setState({color: 'darkgray', cursor: 'auto'});
	}
	onMouseDown(){
		if(this.state.disabled === true)
			return;
		this.setState({color: DARK_GREEN});
	}
	onMouseUp(){
		if(this.state.disabled === true)
			return;
		this.setState({color: GREEN});
	}
	onClick(){
		if(this.props.onClick)
			this.props.onClick();
	}
	getId(){
		return (this.props.screenId && this.props.fieldId) ? this.props.screenId + '_' + this.props.fieldId : ('AUTO_GENERATED_ID_' + Math.random());
	}
	render() {
		const height= this.props.height ? this.props.height : DEFAULT_HEIGHT;
		const width= this.props.width ? this.props.width : DEFAULT_WIDTH;
		return (
			<div style={{position:'relative', top:this.props.top, left:this.props.left, cursor: this.state.cursor, height: (height + 'px'), width: (width + 'px'), overflow:'hidden'}}
				onMouseOver={this.onMouseOver.bind(this)} 
				onMouseOut={this.onMouseOut.bind(this)} 
				onMouseDown={this.onMouseDown.bind(this)}
				onMouseUp={this.onMouseUp.bind(this)}
				onClick={this.onClick.bind(this)}
				id={this.getId()}>
				<RefreshIcon
					id={'undo_icon_' + this.getId()}
					height={(height - 2) + 'px'}
					width={(width - 2) + 'px'}
					fill={this.state.color}
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={this.props.fieldId}
				/>
			</div>
		);
	}
}

RefreshButton.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	height: PropTypes.number,
	width: PropTypes.number,
	top: PropTypes.string,
	left: PropTypes.string,
	tooltips: PropTypes.arrayOf(PropTypes.string)
};

export default withTooltip(RefreshButton);
