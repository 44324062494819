import { SHOW_DIALOG_ACTION } from '../actionTypes';

const showDialogAction2 = (
	{
		dialogContent, 
		height=200, 
		width=400, 
		onOverlayClickedClosedHandler=(()=>{}), 
		enableResizing=true, 
		position=undefined, 
		doSlideInEffect=true, 
		closeOnOverlayClick=false,
		closeOnEscapeKeyPressed=true,
		dialogId
	}
) => 
{
	return {
		type: SHOW_DIALOG_ACTION,
		dialogId: dialogId ? dialogId : Math.random().toString(),
		dialogContent,
		height,
		width,
		functionHandleDialogClosed:onOverlayClickedClosedHandler,
		enableResizing,
		position,
		doSlideInEffect,
		closeOnOverlayClick,
		closeOnEscapeKeyPressed
	};
};
export default showDialogAction2;