import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Label } from '@rtg/shared-react-components';
import PropTypes from 'prop-types';
const withLabel = (WrappedComponent) => {
	class WithLabelHOC extends Component {
		render(){
			const label = this.props.label !== undefined 
				? <Label style={this.props.labelStyle}
					screenId={this.props.screenId} 
					panelId={this.props.panelId}
					fieldId={this.props.fieldId + '_label'}
					value={this.props.label}
					visible={this.props.visible}
					showRequiredAsterisk={this.props.showRequiredAsterisk}
				/>
				: undefined;
			const bootStrapClasses = this.props.labelPosition === 'right' ? 'align-items-end' : 'align-items-start';
			const style = {};
			if(this.props.width){
				style.width = this.props.width;
			}
			if(label === undefined){
				return <Container fluid={true} className={'p-0 m-0'} style={style}>
					<Row className={'align-items-center'} noGutters={true}>
						<Col>
							<WrappedComponent {...this.props}/>
						</Col>
					</Row>
				</Container>;
			}
			const labelCol = (
				<Col className={'pb-2 pr-2'}
					xs={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? 12-this.props.mainComponentBootstrapSize : 6} 
					sm={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? 12-this.props.mainComponentBootstrapSize : 6} 
					md={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? 12-this.props.mainComponentBootstrapSize : 6} 
					lg={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? 12-this.props.mainComponentBootstrapSize : 6} 
					xl={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? 12-this.props.mainComponentBootstrapSize : 6}>
					{label}
				</Col>
			);
			const mainComponentCol = (
				<Col 
					xs={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? this.props.mainComponentBootstrapSize : 6} 
					sm={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? this.props.mainComponentBootstrapSize : 6} 
					md={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? this.props.mainComponentBootstrapSize : 6} 
					lg={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? this.props.mainComponentBootstrapSize : 6} 
					xl={this.props.mainComponentBootstrapSize !== undefined && parseInt(this.props.mainComponentBootstrapSize) > 0 ? this.props.mainComponentBootstrapSize : 6}>
					<WrappedComponent {...this.props}/>
				</Col>
			);
			const rowContents = this.props.labelPosition === 'right'
				? (<>{mainComponentCol}{labelCol}</>)
				: (<>{labelCol}{mainComponentCol}</>);
			return (
				<Container fluid={true} className={'p-0 m-0'} style={style}>
					<Row className={bootStrapClasses} noGutters={true}>
						{rowContents}
					</Row>
				</Container>
			);
		}
	}
	WithLabelHOC.propTypes = {
		screenId: PropTypes.string.isRequired,
		panelId: PropTypes.string,
		fieldId: PropTypes.string.isRequired,
		labelStyle: PropTypes.object,
		mainComponentBootstrapSize: PropTypes.number,
		visible: PropTypes.bool,
		showRequiredAsterisk: PropTypes.bool,
		/**
		 * labelPosition: 'left', 'right'
		 */
		labelPosition: PropTypes.string,
		width: PropTypes.string
	};
	return WithLabelHOC;
};
export default withLabel;