import * as gridUtilities from '../../../utilities/gridUtilities';
import XGridCellIcon from '../../../components/formelements/ImageComponents/XGridCellIcon';
const suggestedOrdersDetailsGridColumnDefs = (screenId, getIsXCellHidden) =>
	[
		{
			headerName: '',
			field: 'ORDERLINE',
			colId: 'ORDERLINE',
			hide: true,
			valueFormatter: (obj) => obj
		},
		{
			headerName: 'Part SKU',
			field: 'partSku',
			colId: 'partSku',
			sortable: true,
			width: 100,
			minWidth: 100,
			type: 'numericColumn'
		},
		{
			headerName: 'Part Description',
			field: 'partDescription',
			colId: 'partDescription',								
			width: 300,
			minWidth: 200,
			sortable: true
		},
		{
			headerName: 'Avg Daily Shipments',
			field: 'averageDailyShipments',
			colId: 'averageDailyShipments',
			sortable: true,
			width: 140,
			minWidth: 140,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Weekly Shipments',
			field: 'averageWeeklyShipments',
			colId: 'averageWeeklyShipments',
			sortable: true,
			width: 125,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Total Shipments',
			field: 'totalShipments',
			colId: 'totalShipments',
			sortable: true,
			width: 110,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Pending Shipments',
			field: 'pendingShipments',
			colId: 'pendingShipments',
			sortable: true,
			width: 125,
			minWidth: 100,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'QOH',
			field: 'quantityOnHand',
			colId: 'quantityOnHand',
			sortable: true,
			width: 75,
			minWidth: 75,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Inbound',
			field: 'inboundQuantity',
			colId: 'inboundQuantity',
			sortable: true,
			width: 75,
			minWidth: 75,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Available',
			field: 'availableQuantity',
			colId: 'availableQuantity',
			sortable: true,
			width: 75,
			minWidth: 75,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Weeks of Stock Inbound',
			field: 'weeksOfStockInbound',
			colId: 'weeksOfStockInbound',
			sortable: true,
			width: 150,
			minWidth: 150,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Weeks of Stock On Hand',
			field: 'weeksOfStockOnHand',
			colId: 'weeksOfStockOnHand',
			sortable: true,
			width: 150,
			minWidth: 150,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Replenishment Stock',
			field: 'replinishmentStock',
			colId: 'replinishmentStock',
			sortable: true,
			width: 130,
			minWidth: 130,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'ADTSQ',
			field: 'additionalDaysToStockQuantity',
			colId: 'additionalDaysToStockQuantity',
			sortable: true,
			width: 75,
			minWidth: 75,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Days To Next Order Qty',
			field: 'daysToNextOrderQuantity',
			colId: 'daysToNextOrderQuantity',
			sortable: true,
			width: 135,
			minWidth: 135,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Calculated Order Quantity',
			field: 'calculatedOrderQuantity',
			colId: 'calculatedOrderQuantity',
			sortable: true,
			width: 160,
			minWidth: 160,
			type: 'numericColumn'
		},
		{
			headerName: 'End Of Lead Time Available',
			field: 'endOfLeadTimeAvailable',
			colId: 'endOfLeadTimeAvailable',
			sortable: true,
			width: 175,
			minWidth: 175,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Threshold Quantity',
			field: 'thresholdQuantity',
			colId: 'thresholdQuantity',
			sortable: true,
			width: 125,
			minWidth: 125,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Production Days',
			field: 'productionDays',
			colId: 'productionDays',
			sortable: true,
			width: 110,
			minWidth: 110,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Transit Days',
			field: 'transitDays',
			colId: 'transitDays',
			sortable: true,
			width: 95,
			minWidth: 95,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Days To Next Order',
			field: 'daysToNextOrder',
			colId: 'daysToNextOrder',
			sortable: true,
			width: 125,
			minWidth: 125,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'ADTS',
			field: 'additionalDaysToStock',
			colId: 'additionalDaysToStock',
			sortable: true,
			width: 75,
			minWidth: 75,
			type: 'numericColumn',
			valueFormatter: (params) => { return gridUtilities.formatDecimal(params.value, 2); }
		},
		{
			headerName: 'Discontinued',
			field: 'discontinuedSku',
			colId: 'discontinuedSku',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
		{
			screenId,
			height: '16px', 
			width: '16px',
			getIsHidden: (params) => getIsXCellHidden(params),
			color: 'red'
		}
		},
		{
			headerName: 'Inactive',
			field: 'inactiveSku',
			colId: 'inactiveSku',
			sortable: true,
			width: 100,
			minWidth: 100,
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
		{
			screenId,
			height: '16px', 
			width: '16px',
			getIsHidden: (params) => getIsXCellHidden(params),
			color: 'red'
		}
		},
		{
			headerName: 'To Be Dropped',
			field: 'toBeDropped',
			colId: 'toBeDropped',
			sortable: true,
			width: 100,
			minWidth: 100,		
			cellStyle: {textAlign:'center'},
			headerClass: 'ag-center-header',
			cellRenderer: XGridCellIcon, 
			cellRendererParams:
		{
			screenId,
			height: '16px', 
			width: '16px',
			getIsHidden: (params) => getIsXCellHidden(params),
			color: 'red'
		}
		}
	];
export default suggestedOrdersDetailsGridColumnDefs;