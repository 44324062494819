import PartOrderLine from '../../../models/PartOrderLine';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as orderProductScreenUtilities from '../orderproductsscreen/OrderProductScreenUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';

export const updateOrderLinesInStore = (dispatch, gridApi, path) => {
	const orderLines = getOrderLinesFromGrid(gridApi);
	storeUtilities.updateValue(dispatch, path, orderLines);
};

export const getOrderLinesFromGrid = (gridApi) => {
	let orderLines = [];
	gridApi.forEachNode(node => { 
		orderLines.push(node.data.ORDERLINE);
	});
	return orderLines;
};

export const getPrimaryNodeFromGrid = (gridApi) => {
	let primaryNode = undefined;
	gridApi.forEachNode(node => { 
		if(node.data.PRIMARYSKU === true && primaryNode === undefined)
			primaryNode = node;
	});
	return primaryNode;
};
export const getPrimaryRowIndexFromGrid = (gridApi) => {
	const orderLines = getOrderLinesFromGrid(gridApi);
	const primaryNode = getPrimaryNodeFromGrid(gridApi);
	return (primaryNode !== undefined && primaryNode.data !== undefined && primaryNode.data.SKU !== undefined) ? getRowIndex(orderLines, primaryNode.data.SKU) : -1;
};
export const getRowIndex = (orderLines, sku) => {
	let primaryRowIndex = -1;
	if(orderLines !== undefined && orderLines.length > 0){
		for(let i = 0; i < orderLines.length; i++){
			if(orderLines[i].PARTSKU === sku){
				primaryRowIndex = i;
				break;
			}
		}
	}
	return primaryRowIndex;
};
export const getNode = (gridApi, sku) => {
	let target;
	gridApi.forEachNode(node => { 
		if(node.data.PARTSKU === sku){
			target = node;
		}
	});
	return target;
};
export const getOrderLinesFromJsonArrayResults = (rows) => {
	if(!rows || !Array.isArray(rows)){
		return undefined;
	}
	let orderLines = [];
	let primaryOrderLine = undefined;
	/**
	 * Loop through data so we can pick a sku to be primary
	 * A second pass through the data will be necessary to set the ratios/salesratios based whether or not each item is set as primary
	 */
	for(let i = 0; i < rows.length; i++){
		let orderLine = createOrderLineFromJsonResult(rows[i]);
		orderLine.Ratio = 0;
		orderLine.SalesRatio = 0;
		/***
		 * We apparently set the first item in the result set with an Order > 0 to be the primary
		 * Seems odd, but we'll go with it. 
		 */
		if(orderLine.Order > 0 && primaryOrderLine === undefined){
			orderLine.Ratio = 1;
			orderLine.SalesRatio = 1;
			primaryOrderLine = orderLine;
		}
		orderLines.push(orderLine);
	}
	return {orderLines, primaryOrderLine};
};

export const createOrderLineFromJsonResult = (row) => {
	let orderLine = new PartOrderLine();
	Object.keys(orderLine).forEach((orderLineKey) => {
		Object.keys(row).forEach((rowKey) => {
			if(orderLineKey.toLowerCase() === rowKey.toLowerCase()){
				if(typeof(orderLine[orderLineKey]) === 'string'){
					// object definition string field
					orderLine[orderLineKey] = row[rowKey];
				}
				else {
					// object definition number field
					orderLine[orderLineKey] = Number(row[rowKey]);
				}
			}
		});
	});
	return orderLine;
};

export const getSuggestedOrdersOrdersJsonRowData = (screenId, orderLines, primarySKURowIndex) => {
	let returnObj = [];
	for(let i = 0; i < orderLines.length; i++){
		const isPrimary = (i === primarySKURowIndex);
		const {altOrderLine, skuType} = '';
		returnObj.push(getSuggestedOrdersOrdersGridJsonRowFromOrderLine(orderLines[i], isPrimary, altOrderLine, skuType));
	}

	return returnObj;
};
export const getSuggestedOrdersDetailsJsonRowData = (screenId, orderLines) => {
	let returnObj = [];
	for(let i = 0; i < orderLines.length; i++){
		returnObj.push(getSuggestedOrdersDetailsGridJsonRowFromOrderLine(orderLines[i]));
	}

	return returnObj;
};
export const getSuggestedOrdersOrdersGridJsonRowFromOrderLine = (orderLine) => {
	return {
		ORDERLINE: orderLine,
		PARTSKU: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'PARTSKU'),
		PARTDESCRIPTION: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'PARTDESCRIPTION'),
		AVERAGEDAILYSHIPMENTS: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'AVERAGEDAILYSHIPMENTS'),
		AVERAGEWEEKLYSHIPMENTS: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'AVERAGEWEEKLYSHIPMENTS'),
		TOTALSHIPMENTS: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'TOTALSHIPMENTS'),
		PENDINGSHIPMENTS: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'PENDINGSHIPMENTS'),
		QUANTITYONHAND: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'QUANTITYONHAND'),
		INBOUNDQUANTITY: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'INBOUNDQUANTITY'),
		AVAILABLEQUANTITY: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'AVAILABLEQUANTITY'),
		WEEKSOFSTOCKINBOUND: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'WEEKSOFSTOCKINBOUND'),
		CALCULATEDORDERQUANTITY: getSuggestedOrdersOrdersGridValueFromOrderLine(orderLine, 'CALCULATEDORDERQUANTITY')
	};
};
export const getSuggestedOrdersDetailsGridJsonRowFromOrderLine = (orderLine) => {
	return {
		ORDERLINE: orderLine,
		PARTSKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PARTSKU'),
		PARTDESCRIPTION: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PARTDESCRIPTION'),
		AVERAGEDAILYSHIPMENTS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'AVERAGEDAILYSHIPMENTS'),
		AVERAGEWEEKLYSHIPMENTS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'AVERAGEWEEKLYSHIPMENTS'),
		TOTALSHIPMENTS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'TOTALSHIPMENTS'),
		PENDINGSHIPMENTS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PENDINGSHIPMENTS'),
		QUANTITYONHAND: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'QUANTITYONHAND'),
		INBOUNDQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'INBOUNDQUANTITY'),
		AVAILABLEQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'AVAILABLEQUANTITY'),
		WEEKSOFSTOCKINBOUND: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'WEEKSOFSTOCKINBOUND'),
		WEEKSOFSTOCKONHAND: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'WEEKSOFSTOCKONHAND'),
		REPLINISHMENTSTOCK: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'REPLINISHMENTSTOCK'),
		ADDITIONALDAYSTOSTOCK: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ADDITIONALDAYSTOSTOCK'),
		ADDITIONALDAYSTOSTOCKQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ADDITIONALDAYSTOSTOCKQUANTITY'),
		DAYSTONEXTORDERQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DAYSTONEXTORDERQUANTITY'),
		DAYSTONEXTORDER: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DAYSTONEXTORDER'),
		CALCULATEDORDERQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'CALCULATEDORDERQUANTITY'),
		ORDER: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'CALCULATEDORDERQUANTITY'),
		ENDOFLEADTIMEAVAILABLE: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ENDOFLEADTIMEAVAILABLE'),
		THRESHOLDQUANTITY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'THRESHOLDQUANTITY'),
		PRODUCTIONDAYS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PRODUCTIONDAYS'),
		TRANSITDAYS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'TRANSITDAYS'),
		INACTIVESKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'InactiveSKU'),
		DISCONTINUEDSKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DiscontinuedSKU'),
		TOBEDROPPED: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ToBeDropped')
	};
};
export const getSuggestedOrdersOrdersGridValueFromOrderLine = (orderLine, col) => {
	switch(col){
		/* lblSku in OrderSku.mxml */
		case 'PARTSKU':
			return orderLine.PARTSKU;
		/* lblDesc in OrderSku.mxml */
		case 'PARTDESCRIPTION':
			return orderLine.PARTDESCRIPTION;
		case 'AVERAGEDAILYSHIPMENTS':
			return Number(parseFloat(orderLine.AVERAGEDAILYSHIPMENTS).toFixed(2) || 0);
		case 'AVERAGEWEEKLYSHIPMENTS':
			return Number(parseFloat(orderLine.AVERAGEWEEKLYSHIPMENTS).toFixed(2) || 0);
		case 'TOTALSHIPMENTS':
			return Number(parseFloat(orderLine.TOTALSHIPMENTS).toFixed(2) || 0);
		case 'PENDINGSHIPMENTS':
			return Number(parseFloat(orderLine.PENDINGSHIPMENTS).toFixed(2) || 0);
		case 'QUANTITYONHAND':
			return Number(parseFloat(orderLine.QUANTITYONHAND).toFixed(2) || 0);
		case 'INBOUNDQUANTITY':
			return Number(parseFloat(orderLine.INBOUNDQUANTITY).toFixed(2) || 0);
		case 'AVAILABLEQUANTITY':
			return Number(parseFloat(orderLine.AVAILABLEQUANTITY).toFixed(2) || 0);
		case 'WEEKSOFSTOCKINBOUND':
			return Number(parseFloat(orderLine.WEEKSOFSTOCKINBOUND).toFixed(2) || 0);
		/* txtOrder in OrderSku.mxml */
		case 'CALCULATEDORDERQUANTITY':
			return Number(orderLine.CALCULATEDORDERQUANTITY || 0);
		default:
			return undefined;
	}
};

const getSuggestedOrdersDetailsGridValueFromOrderLine = (orderLine, col) => {
	return (orderLine && Object.prototype.hasOwnProperty.call(orderLine, col)) ? orderLine[col] : undefined;
};

export const getSuggestedOrdersGridExcelExportDataSet = (gridRef, rowData, mustExcludedColumns) => {
	const results = gridUtilities.excludeColumnsForExcelExportDataSet(gridRef.api.getColumns(), gridRef.api.getAllDisplayedColumns(), rowData, mustExcludedColumns);
	const obj = sharedUtilities.convertToJsonArray(results, undefined, 'COLUMNS', 'DATA');
	return obj;
};

/**
 * Handle suggested PO grid row qty change. Update row line cost, PO cubes, PO cost and Total suggested order cost.
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Object} gridApi - ag-Grid object for PO
 * @param {Object} changedRowNode - ag-grid RowNode Object where the quantity changed
 * @param {Number} newValue - New value entered by user for Qty
 * @param {Function} onSuggestedPosTotalCostChange - function pointer to same name to update Suggested POs Total Cost when PO cost changes
 * @param {Number} path - path to value being updated
 */
export const changeQty = (dispatch, gridApi, changedRowNode, newValue, onSuggestedPosTotalCostChange=()=>{}, path) => {
	const data = changedRowNode.data;
	const qtyDiff = getRowQtyDiff(changedRowNode, newValue);
	//get cost diff
	const costDiff = qtyDiff * numberUtilities.getNumberOrDefault(data.FIRSTCOST, 0);
	//get cube diff
	const cubesDiff = qtyDiff * numberUtilities.getNumberOrDefault(data.CUBES, 0);
	changeRowNode(dispatch, gridApi, changedRowNode, qtyDiff, costDiff, path);
	changePoCubes(dispatch, cubesDiff, path);
	changePoTotalCost(dispatch, costDiff, path);
	onSuggestedPosTotalCostChange(costDiff);
};

/**
 * Update PO Total Cost display and redux store value with change indicated by costDiff
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Number} costDiff - Difference in cost from where PO cost used to be
 * @param {Number} path - path to value being updated
 */
const changePoTotalCost = (dispatch, costDiff, path) => {
	const poCost = storeUtilities.getValue([...path, 'totalCost'], 0);
	storeUtilities.updateValue(dispatch, [...path, 'totalCost'], poCost + costDiff, true);
};

/**
 * Update PO Cubes display and redux store value with change indicated by cubesDiff
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Number} cubesDiff - Difference in cubes from where PO cubes used to be
 * @param {Number} path - path to value being updated
 */
const changePoCubes = (dispatch, cubesDiff, path) => {
	const cubes = storeUtilities.getValue([...path, 'cubes'], 0);
	storeUtilities.updateValue(dispatch, [...path, 'cubes'], cubes + cubesDiff, true);
};

/**
* Get the difference in rowNode quantity caused by value change
* @param {Object} changedRowNode - ag-grid rowNode object, the row that was changed
* @param {Number} newValue - new value typed into input
*/
export const getRowQtyDiff = (changedRowNode, newValue) => {
	const qtyOld = numberUtilities.getNumberOrDefault(Math.floor(changedRowNode.data.QTY), 0);
	const qtyNew = numberUtilities.getNumberOrDefault(Math.floor(newValue), 0, (val) => { return val >= 0; });
	return qtyNew - qtyOld;
};

const changeRowNode = (dispatch, gridApi, changedRowNode, qtyDiff, costDiff, rootPath) => {
	const data = changedRowNode.data;
	const rowDataLine = storeUtilities.getValue([...rootPath, 'rowData', changedRowNode.id], {});
	data.QTY += qtyDiff;
	rowDataLine.QTY = data.QTY;
	data.LINE += costDiff;
	rowDataLine.LINE = data.LINE;
	storeUtilities.updateValue(dispatch, [...rootPath, 'rowData', changedRowNode.id], rowDataLine, true);
	changedRowNode.setData(data);
	gridApi.refreshCells({rowNodes: [changedRowNode], force: true});
};
export const useCubes = (screenId) => {
	const selectedCategory = storeUtilities.getValue([screenId, 'merchGroup']);
	const useCubes = selectedCategory === undefined 
	|| selectedCategory===''
	|| !['Pillows'].includes(selectedCategory);
	return useCubes;
};
export const exportToExcelClicked = (dispatch, screenId, dataset) => {
	orderProductScreenUtilities.exportToExcelClicked(dispatch, screenId, dataset);
};
