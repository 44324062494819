import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SizeMe } from 'react-sizeme';
import * as numberUtilities from '../../../../utilities/numberUtilities';
import OkCancelDialogContainer from '../../../dialogs/OkCancelDialogContainer';
import AddClearanceSkusInactiveSkusListTabContainer from './AddClearanceSkusInactiveSkusListTabContainer';
import AddClearanceSkusSearchProductsListTab from './AddClearanceSkusSearchProductsListTab';
class AddClearanceSkusDialog extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	onTabChanged(key){
		if(this.props.onTabChanged){
			this.props.onTabChanged(key);
		}
	}
	addSkusClicked(){
		if(this.props.addSkusClicked){
			this.props.addSkusClicked();
		}
	}
	handleCancelClicked(){
		if(this.props.handleCancelClicked){
			this.props.handleCancelClicked();
		}
	}
	render() {
		return (
			<SizeMe monitorHeight={true}> 
				{({size}) => {
					const height = numberUtilities.getNumberOrDefault(size.height, 150) - 125;
					return (
						<OkCancelDialogContainer 
							screenId={this.props.screenId} 
							title={'Add Clearance SKU'}
							okButtonText={'Add SKUs'}
							handleOkClicked={this.addSkusClicked.bind(this)}
							handleCancelClicked={this.handleCancelClicked.bind(this)}
							style={{paddingLeft: '8px', paddingRight: '8px'}}
							hasCancelButton>
							<Tabs 
								style={{position:'relative', backgroundColor:'gainsboro'}} 
								onSelect={this.onTabChanged.bind(this)}>
								<Tab 
									eventKey={'addNewClearanceSkus'}
									title={'Add New SKUs'} 
									style={{position:'relative', top:'15px', padding: '0px', margin: '0px'}}>
									<div style={{height:height}} className={'p-0 m-0'}>
										<AddClearanceSkusSearchProductsListTab 
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											addNewSkusTabPath={this.props.addNewSkusTabPath}
										/>
									</div>
								</Tab>
								<Tab 
									eventKey={'activateInactiveClearanceSkus'}
									title={'Add Inactive SKUs'}
									style={{position:'relative', top:'15px'}}>
									<div style={{height:height}} className={'p-0 m-0'}>
										<AddClearanceSkusInactiveSkusListTabContainer 
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											addInactiveSkusTabPath={this.props.addInactiveSkusTabPath}
										/>
									</div>
								</Tab>
							</Tabs>
						</OkCancelDialogContainer>
					);}}
			</SizeMe>
		);
	}
}

AddClearanceSkusDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	addSkusClicked: PropTypes.func,
	addNewSkusTabPath: PropTypes.array,
	addInactiveSkusTabPath: PropTypes.array,
	onTabChanged:PropTypes.func
};
export default AddClearanceSkusDialog;
