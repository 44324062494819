import PropTypes from 'prop-types';
import React from 'react';
import withID from '../../../higherordercomponents/WithID';
const UndoIcon = ({fill, height, width, id}) => {
	const h = height ? height : '25px';
	const w = width ? width : '25px';
	const f = fill ? fill : '#111111';
	return (
		<svg 
			id={id} 
			x={'0px'} 
			y={'0px'}
			viewBox={'0 0 512 512'} 
			style={{height: h, width: w}} 
			fill={f}>
			<g id={'XMLID_3_'}>
				<path 
					id={'XMLID_5_'} 
					d={'M288.3,32.3C175.7,32.3,82.7,115.8,67,224.5H0l96.1,128.4l96.1-128.4h-60.7 c15-73.3,79.6-128.4,156.8-128.4c88.2,0,159.9,71.7,159.9,159.9s-71.7,159.9-159.9,159.9v63.8C412,479.7,512,379.7,512,256 C512,132.3,412,32.3,288.3,32.3z'}
				/>
			</g>
		</svg>
	);
};
UndoIcon.propTypes = {
	fill: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	id: PropTypes.string.isRequired
};
export default withID(UndoIcon);