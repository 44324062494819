import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import PurchaseOrderQueueGridPanel from './PurchaseOrderQueueGridPanel';

class PurchaseOrderQueueGridPanelContainer extends Component {
	render() {
		return (
			<PurchaseOrderQueueGridPanel {...this.props}/>
		);
	}
}
const mapStateToProps = (state, props) => {
	const totalsDivLeft = storeUtilities.getValue([props.screenId, props.panelId, 'totalsDivLeft'], 0);
	const totalsDivWidth = storeUtilities.getValue([props.screenId, props.panelId, 'totalsDivWidth'], 0);
	return {
		totalsDivLeft,
		totalsDivWidth
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			updateAllTotals(dispatch, props);
		},
		updateTotals: (gridApi) => {
			updateAllTotals(dispatch, props, gridApi);
		},
		getColumnValue: (params) => {
			switch(params.colDef.colId){
				case 'flSkuQty':
				case 'seSkuQty':
				case 'txSkuQty':
				case 'skuQtyTotal':
				case 'costTotal':
					return calcRowValue(params, params.colDef.colId);
				default:
					return (params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined;
			}
		},
		formatColumnValue: (params) => {
			switch(params.colDef.colId){
				case 'costTotal':
				case 'firstCost':
					return sharedUtilities.formatCurrency(params.value);
				default:
					return (params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined;
			}
		},
		onModelUpdated: (params) => {
			updateAllTotals(dispatch, props, params.api);
		},
		setTotalsDivPosition: (evt) => {
			storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'totalsDivLeft'], evt.left);
		},
		setTotalsDivWidth: (totalsDivWidth) => {
			storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'totalsDivWidth'], totalsDivWidth);
		}
	};
};
const calcRowValue = (params, colId) => {
	let total = 0;
	if(typeof(params.data?.poQueueRowModels) !== 'object'){
		return total;
	}
	Object.entries(params.data.poQueueRowModels).forEach(([, value]) => {
		if(!Array.isArray(value)){
			return;
		}
		value.forEach(entry => {
			if(params.data.sku === entry.sku){//sanity check...the rows have already grouped by sku
				if(colId === 'skuQtyTotal' || (entry.deliverTo.toLowerCase && colId.toLowerCase().substring(0, 2) === entry.deliverTo.toLowerCase().substring(0, 2))){
					total += parseInt(entry.quantity);
				}
				else if(colId === 'costTotal'){
					total += (parseInt(entry.quantity) * entry.firstCost);
				}
			}
		});
	});
	return total;
};
const updateAllTotals = (dispatch, props, gridApi) => {
	const flSelectedPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'FL', false);
	const seSelectedPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'SE', false);
	const txSelectedPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'TX', false);
	const selectedPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'total', false);
	const selectedCostTotal = gridApi === undefined ? 0 : getCostTotal(gridApi, false);

	const flPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'FL', true);
	const sePOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'SE', true);
	const txPOCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'TX', true);
	const poCount = gridApi === undefined ? 0 : getPOCount(gridApi, 'total', true);
	const costTotal = gridApi === undefined ? 0 : getCostTotal(gridApi, true);

	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'flSelectedPOCount'], flSelectedPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'seSelectedPOCount'], seSelectedPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'txSelectedPOCount'], txSelectedPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'selectedPOCount'], selectedPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'selectedCostTotal'], selectedCostTotal);

	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'flPOCount'], flPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'sePOCount'], sePOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'txPOCount'], txPOCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'poCount'], poCount);
	storeUtilities.updateValue(dispatch, [props.screenId, props.panelId, 'costTotal'], costTotal);
};
const getCostTotal = (gridApi, includeUnselected) => {
	let costTotal = 0;
	gridApi.forEachNodeAfterFilter(node => {
		const isSelected = node.isSelected();
		if(!includeUnselected && !isSelected){
			return;
		}
		const poQueueRowModels = typeof(node?.data?.poQueueRowModels) === 'object' ? Object.values(node.data.poQueueRowModels) : [];
		poQueueRowModels.forEach(poQueueRowModel => {
			if(Array.isArray(poQueueRowModel)){
				poQueueRowModel.forEach(row => {
					if(includeUnselected){
						costTotal += (row.firstCost * row.quantity);
					}
					else {
						if(isSelected){
							costTotal += (row.firstCost * row.quantity);
						}
					}
				});
			}
		});
	});
	return costTotal;
};
const getPOCount = (gridApi, division, includeUnselected) => {
	const allQueueIDs = [];
	gridApi.forEachNodeAfterFilter(node => {
		const isSelected = node.isSelected();
		if(!includeUnselected && !isSelected){
			return;
		}
		const poQueueRowModels = typeof(node?.data?.poQueueRowModels) === 'object' ? Object.values(node.data.poQueueRowModels) : [];
		poQueueRowModels.forEach(poQueueRowModel => {
			if(Array.isArray(poQueueRowModel)){
				poQueueRowModel.forEach(row => {
					if(row.deliverTo.toLowerCase && division.toLowerCase() === row.deliverTo.toLowerCase().substring(0, 2)){
						if(includeUnselected){
							if(!allQueueIDs.includes(row.queueID)){
								allQueueIDs.push(row.queueID);
							}
						}
						else {
							if(isSelected){
								if(!allQueueIDs.includes(row.queueID)){
									allQueueIDs.push(row.queueID);
								}
							}
						}
					}
				});
			}
		});
	});
	return allQueueIDs.length;
};

PurchaseOrderQueueGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	panelStyle: PropTypes.object,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderQueueGridPanelContainer);
export default connectedComponent;