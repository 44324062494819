import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import * as storeUtilities from '../../../utilities/storeUtilities';
import DatesPickerDialogContainer from '../../dialogs/DatesPickerDialogContainer';
import SalesHistoryGroupBox from './SalesHistoryGroupBox';

class SalesHistoryGroupBoxContainer extends Component {
	render() {
		return (
			<SalesHistoryGroupBox {...this.props}
				onLoad={this.props.onLoad}
				excludeDatesButtonClickedHandler={this.props.handleExcludeDatesButtonClicked}
				startDateChangedHandler={this.props.updateSalesHistoryTotalDays}
				endDateChangedHandler={this.props.updateSalesHistoryTotalDays}
				excludeDatesButtonClickedCallback={this.props.updateSalesHistoryTotalDays}
			/>
		);
	}
}
const mapStateToProps = (state, props) => {
	return {
		endDate: storeUtilities.getValue(props.endDatePath, undefined),
		startDate: storeUtilities.getValue(props.startDatePath, undefined)
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			updateSalesHistoryTotalDays(dispatch, props.startDatePath, props.endDatePath, props.excludedMomentRangesPath, props.totalDaysTextBoxPath);
		},
		handleExcludeDatesButtonClicked: (callback) => {
			dispatch(showDialogAction(
				<DatesPickerDialogContainer
					screenId={props.screenId}
					panelId={'datesPickerDialog'}
					title={'Exclude Dates'}
					momentRangesPath={[props.screenId, 'excludedMomentRanges']}
					cancelButtonText={'Do Not Exclude Selected Dates'}
					okButtonText={'Exclude Selected Dates'}
					onSaveChangesClicked={() => {
						if(callback){
							callback();
						}
					}}
					onCancelClicked={() => {
						if(callback){
							callback();
						}
					}}
				/>,
				350,
				500,
				() => { },
				true,
				undefined,
				true,
				false,
				true));
		},
		updateSalesHistoryTotalDays: () => {
			updateSalesHistoryTotalDays(dispatch, props.startDatePath, props.endDatePath, props.excludedMomentRangesPath, props.totalDaysTextBoxPath);
		}
	};
};

const updateSalesHistoryTotalDays = (dispatch, startDatePath, endDatePath, excludedMomentRangesPath, totalDaysTextBoxPath) => {
	const beginSales = storeUtilities.getValue(startDatePath, undefined);
	const endSales = storeUtilities.getValue(endDatePath, undefined);
	const excludedMomentRanges = storeUtilities.getValue(excludedMomentRangesPath, []);
	let beginSalesMoment = undefined;
	let endSalesMoment = undefined;
	if (beginSales !== undefined && endSales !== undefined) {
		beginSalesMoment = moment(beginSales).startOf('day');
		endSalesMoment = moment(endSales).endOf('day');
		const totalDays = calculateTotalDays(beginSalesMoment, endSalesMoment, excludedMomentRanges);
		storeUtilities.updateValue(dispatch, totalDaysTextBoxPath, totalDays.toString());
	}
};
export const calculateTotalDays = (startMoment, endMoment, excludedMomentRanges) => {
	let excludedDays = 0;
	if(moment.isMoment(startMoment) && moment.isMoment(endMoment) && Array.isArray(excludedMomentRanges)){
		excludedMomentRanges.forEach((range) => {
			const hightestLowEnd = range.start.isAfter(startMoment) ? range.start : startMoment;
			const lowestHighEnd = range.end.isBefore(endMoment) ? range.end : endMoment;
			const additionalDiff = lowestHighEnd.diff(hightestLowEnd, 'days') + 1;
			if(additionalDiff > 0){
				excludedDays += additionalDiff;
			}
		});
	}
	return (endMoment.diff(startMoment, 'days') + 1) - excludedDays;
};

SalesHistoryGroupBoxContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	
	//startDate props
	startDateFieldId: PropTypes.string.isRequired,
	startDatePath: PropTypes.arrayOf(PropTypes.string),
	startDateLabel: PropTypes.string.isRequired,
	startDateChangedHandler: PropTypes.func,
	startDatePersist: PropTypes.bool.isRequired,
    
	//endDate props
	endDateFieldId: PropTypes.string.isRequired,
	endDatePath: PropTypes.arrayOf(PropTypes.string),
	endDateLabel: PropTypes.string.isRequired,
	endDateChangedHandler: PropTypes.func,
	endDatePersist: PropTypes.bool.isRequired,

	//excludeDate props
	excludeDatesButtonFieldId: PropTypes.string,
	excludeDatesButtonText: PropTypes.string,
	excludeDatesButtonClickedHandler: PropTypes.func,
	excludeDatesButtonTooltips: PropTypes.arrayOf(PropTypes.string),
	excludeDatesButtonClickedCallback: PropTypes.func,

	//totalDays props
	totalDaysTextBoxFieldId: PropTypes.string.isRequired,
	totalDaysTextBoxPath: PropTypes.arrayOf(PropTypes.string).isRequired,
	totalDaysTextBoxLabel: PropTypes.string.isRequired,

	//misc props
	excludedMomentRangesPath: PropTypes.arrayOf(PropTypes.string).isRequired,
	hasExcludeDates: PropTypes.any
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SalesHistoryGroupBoxContainer);
export default connectedComponent;