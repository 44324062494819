import * as numberUtilities from './numberUtilities';
import * as sharedUtilities from './sharedUtilities';

export function excludeColumnsForExcelExportDataSet(allColumns, displayedColumns, rowData, mustExcludedColumns){
	const rowDataUpperCaseKeys = Array.isArray(rowData) ? rowData.map(row => sharedUtilities.uppercaseObjectKeys(row)) : undefined;
	const mustExcludedColumnsUpperCased = Array.isArray(mustExcludedColumns) ? mustExcludedColumns.map(x => x.toUpperCase ? x.toUpperCase() : undefined): mustExcludedColumns;
	const columnIdsUpperCased = [];
	const data = [];
	/**
	 * display the headerNames instead of the colId's (since they will visually match what is shown on the grids)
	 */
	for(let j = 0; j < displayedColumns.length; j++){
		const colIdUpperCased = displayedColumns[j].colId.toUpperCase();
		if(!mustExcludedColumnsUpperCased.includes(colIdUpperCased)){
			if(!columnIdsUpperCased.includes(colIdUpperCased)){
				columnIdsUpperCased.push(colIdUpperCased);
			}
		}
	}
	const columnHeaderNames = columnIdsUpperCased.map(column => {
		let returnValue = '';
		for(let i = 0; i < allColumns.length; i++){
			if(column === allColumns[i].colId.toUpperCase()){
				returnValue = allColumns[i].colDef.headerName;
			}
		}
		return returnValue;
	});
	if(Array.isArray(rowDataUpperCaseKeys)){
		for(let i = 0; i < rowDataUpperCaseKeys.length; i++){
			const dataRowArray = [];
			for(let j = 0; j < displayedColumns.length; j++){
				const displayedColumnUppercasedColId = displayedColumns[j]?.colId?.toUpperCase ? displayedColumns[j]?.colId?.toUpperCase() : undefined;
				const included = mustExcludedColumnsUpperCased.includes(displayedColumnUppercasedColId);
				if(displayedColumnUppercasedColId !== undefined && !included){
					dataRowArray.push(rowDataUpperCaseKeys[i][displayedColumnUppercasedColId]); 
				}
			}
			data.push(dataRowArray);
		}
	}
	return {
		COLUMNS: columnHeaderNames,
		DATA: data
	};
}
export function formatDecimal(value, radix, removeTrailingZeroes = false){
	const val = numberUtilities.getNumberOrDefault(value, 0).toFixed(radix);
	var returnValue = String(removeTrailingZeroes === true ? Number(val) : val);
	return returnValue;
}
export const getCellId = (rowIndex, colId, gridName, isEditor=false) => {
	return (colId && !window.isNaN(rowIndex)) 
		? gridName + '_' + colId + (isEditor === true ? '_EDITOR_' : '_RENDERER_') + rowIndex
		: 'no-id';
};

export const getNextCellPos = (prevPos, boundary, canMoveTo, backwards) => {
	const next = backwards ? findPreviousCellPos(prevPos, boundary, canMoveTo) : findNextCellPos(prevPos, boundary, canMoveTo);
	if(next === undefined){
		return prevPos;
	}
	else{
		return next;
	}	
};
const findNextCellPos = (pos, boundary, canMoveTo) => {
	const next = {
		x: pos.x,
		y: pos.y
	};
	if(next.x < boundary.xMax){
		next.x++;
	}
	else {
		if(next.y < boundary.yMax){
			next.y++;
			next.x = 0;
		}
		else {
			return undefined;//terminating case
		}
	}
	if(canMoveTo(next)){
		return next;
	}
	else { 
		return findNextCellPos(next, boundary, canMoveTo);
	}
};
const findPreviousCellPos = (pos, boundary, canMoveTo) => {
	const prev = {
		x: pos.x,
		y: pos.y
	};
	if(prev.x > boundary.xMin){
		prev.x--;
	}
	else {
		if(prev.y > boundary.yMin){
			prev.y--;
			prev.x = boundary.xMax;
		}
		else {
			return undefined;//terminating case
		}
	}
	if(canMoveTo(prev, boundary)){
		return prev;
	}
	else { 
		return findPreviousCellPos(prev, boundary, canMoveTo);
	}
};