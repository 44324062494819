import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import withID from '../../higherordercomponents/WithID';

class ExportToExcelButton extends Component {
	onClick = () => {
		if(this.props.getExcelDataSet){
			const excelDataSet = this.props.getExcelDataSet();
			if(this.props.onClick){
				this.props.onClick(excelDataSet);
			}
		}
	};
	render() {
		let classes = this.props.fill ? 'btn btn-block ': 'btn ';
		if(this.props.className && this.props.className !== '')
			classes += this.props.className;
		else classes += 'btn-primary';
		const visibility = this.props.visible !== false ? 'block':'none';
		return 	(				
			<Button 
				className={classes} 
				style={{whiteSpace:'normal', display:visibility}}
				disabled={this.props.disabled}
				id={this.props.id}
				data-testid={this.props.id}
				onClick={this.onClick}>
				{this.props.text}
			</Button>
		);
	}
}
ExportToExcelButton.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	fill: PropTypes.bool,
	disabled: PropTypes.bool,
	text: PropTypes.string,
	visible: PropTypes.bool,
	className: PropTypes.string,
	getExcelDataSet: PropTypes.func,
	excelDataSetName: PropTypes.string
};

export default withID(ExportToExcelButton);
