import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MultiValueCellRenderer extends Component {
	render() {
		const values = this.props.api.getCellValue({rowNode: this.props.node, colKey: this.props.colDef.colId});
		const formattedValues = formatValues(values, this.props.valueFormatter);
		const valueDivs = formattedValues.map(value => <div key={Math.random()}>{value}</div>);
		return <div>{valueDivs}</div>;
	}
}
const formatValues = (values, valueFormatter) => {
	if(!valueFormatter || !Array.isArray(values)){
		return values;
	}
	return values.map(value => valueFormatter(value));
};

MultiValueCellRenderer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string,
	valueFormatter: PropTypes.func
};

export default MultiValueCellRenderer;
