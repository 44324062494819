import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import rpcAction from '../../../actions/rpcAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as constants from '../../../constants';
import { nodeRoutes } from '../../../constants';
import OrderLine from '../../../models/OrderLine';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OnOrderDialogContainer from './dialogs/OnOrderDialogContainer';
import * as persistentValues from '../../../utilities/persistentValues';

export const updateOrderLinesInStore = (dispatch, gridApi, path) => {
	const orderLines = getOrderLinesFromGrid(gridApi);
	storeUtilities.updateValue(dispatch, path, orderLines);
};

export const getOrderLinesFromGrid = (gridApi) => {
	let orderLines = [];
	gridApi.forEachNode(node => { 
		orderLines.push(node.data.ORDERLINE);
	});
	return orderLines;
};

export const getPrimaryNodeFromGrid = (gridApi) => {
	let primaryNode = undefined;
	gridApi.forEachNode(node => { 
		if(node.data.PRIMARYSKU === true && primaryNode === undefined)
			primaryNode = node;
	});
	return primaryNode;
};
export const getPrimaryRowIndexFromGrid = (gridApi) => {
	const orderLines = getOrderLinesFromGrid(gridApi);
	const primaryNode = getPrimaryNodeFromGrid(gridApi);
	return (primaryNode?.data?.ORDERLINE?.SKU !== undefined) ? getRowIndex(orderLines, primaryNode.data.ORDERLINE.SKU) : -1;
};
export const getRowIndex = (orderLines, sku) => {
	let primaryRowIndex = -1;
	if(Array.isArray(orderLines) && orderLines.length > 0){
		for(let i = 0; i < orderLines.length; i++){
			if(orderLines[i].SKU === sku){
				primaryRowIndex = i;
				break;
			}
		}
	}
	return primaryRowIndex;
};
export const getNode = (gridApi, sku) => {
	let target;
	gridApi.forEachNode(node => { 
		if(node.data.ORDERLINE.SKU === sku){
			target = node;
		}
	});
	return target;
};
export const getOrderLinesFromJsonArrayResults = (rows) => {
	if(!rows || !Array.isArray(rows)){
		return undefined;
	}
	const orderLines = [];
	let primaryOrderLine = undefined;
	/**
	 * Loop through data so we can pick a sku to be primary
	 * A second pass through the data will be necessary to set the ratios/salesratios based whether or not each item is set as primary
	 */
	for(let i = 0; i < rows.length; i++){
		const orderLine = createOrderLineFromJsonResult(rows[i]);
		orderLine.Ratio = 0;
		orderLine.SalesRatio = 0;
		/***
		 * We apparently set the first item in the result set with an Order > 0 to be the primary
		 * Seems odd, but we'll go with it. 
		 */
		if(orderLine.Order > 0 && primaryOrderLine === undefined){
			orderLine.Ratio = 1;
			orderLine.SalesRatio = 1;
			primaryOrderLine = orderLine;
		}
		orderLines.push(orderLine);
	}
	return {orderLines, primaryOrderLine};
};
export const createOrderLineFromJsonResult = (row) => {
	const orderLine = new OrderLine();
	Object.keys(orderLine).forEach((orderLineKey) => {
		Object.keys(row).forEach((rowKey) => {
			if(orderLineKey.toLowerCase() === rowKey.toLowerCase()){
				if(typeof(orderLine[orderLineKey]) === 'string'){
					// object definition string field
					orderLine[orderLineKey] = row[rowKey];
				}
				else {
					// object definition number field
					orderLine[orderLineKey] = Number(row[rowKey]);
				}
			}
		});
	});
	return orderLine;
};
export const getDefaultAltSku = (screenId, orderLine, persistentValuesPath) => {
	let altSku = orderLine?.SUBSTITUTIONSKU;
	if(altSku !== undefined && altSku.trim && altSku.trim() !== ''){
		altSku = altSku.trim();
	}
	const skuAltSkuObj = persistentValues.get(persistentValuesPath);
	const skuAltSkuArray = Array.isArray(skuAltSkuObj) ? skuAltSkuObj : [];
	const skuAltSkuMap = new Map(skuAltSkuArray);

	const altSkuFromStorage = skuAltSkuMap.get(orderLine.SKU);
	if(altSkuFromStorage !== undefined){
		altSku = altSkuFromStorage;
	}
	return altSku;
};
export const getAltSkuInfo = (screenId, sku) => {
	const altSkuComponentOverridePath = getAltSkuOverridePath(screenId, sku);
	const altOrderLine = storeUtilities.getValue([...altSkuComponentOverridePath, 'altOrderLine'], undefined);
	const altSku = altOrderLine?.SKU;
	const substituteSelected = storeUtilities.getValue([...altSkuComponentOverridePath, 'chkSubstitute'], false);
	const combinationSelected = storeUtilities.getValue([...altSkuComponentOverridePath, 'chkCombination'], false);
	const altSubQty = storeUtilities.getValue([...altSkuComponentOverridePath, 'altSubQty'], 0);
	const altComQty = storeUtilities.getValue([...altSkuComponentOverridePath, 'altComQty'], 0);
	const origSubstituteSelected = storeUtilities.getValue([...altSkuComponentOverridePath, 'ORIG_chkSubstitute'], false);
	const origCombinationSelected = storeUtilities.getValue([...altSkuComponentOverridePath, 'ORIG_chkCombination'], false);
	/** '^' is XOR...i.e., one or the other is true, but not both*/
	const altSkuType = (substituteSelected ^ combinationSelected) ? (substituteSelected ? 'SUBSTITUTION' : 'COMBINATION') : 'DEFAULT';

	return {
		altSku: altSku,
		altOrderLine, 
		altSkuType, 
		altSubQty, 
		altComQty,
		chkSubstitute: substituteSelected,
		chkCombination: combinationSelected,
		ORIG_chkSubstitute: origSubstituteSelected,
		ORIG_chkCombination: origCombinationSelected
	};
};
export const getAltSkuOverridePath = (screenId, sku) => {
	const whsNum = storeUtilities.getValue([screenId, 'whsNumInUse'], undefined);
	if(whsNum === undefined){
		return [];
	}
	return [screenId, 'skuAltSkuMapping', whsNum, String(sku)];
};
export const getSuggestedOrdersOrdersJsonRowData = (screenId, orderLinesRowData, primarySKURowIndex) => {
	let returnObj = [];
	for(let i = 0; i < orderLinesRowData.length; i++){
		const isPrimary = (i === primarySKURowIndex);
		const {altOrderLine, altSkuType} = getAltSkuInfo(screenId, orderLinesRowData[i].SKU);
		returnObj.push(getSuggestedOrdersOrdersGridJsonRowFromOrderLine(screenId, orderLinesRowData[i].ORDERLINE, isPrimary, altOrderLine, altSkuType));
	}

	return returnObj;
};
export const getSuggestedOrdersDetailsJsonRowData = (orderLinesRowData) => {
	let returnObj = [];
	for(let i = 0; i < orderLinesRowData.length; i++){
		returnObj.push(getSuggestedOrdersDetailsGridJsonRowFromOrderLine(orderLinesRowData[i].ORDERLINE));
	}

	return returnObj;
};
export const getSuggestedOrdersOrdersGridJsonRowFromOrderLine = (screenId, orderLine, isPrimarySku, altOrderLine, altSkuType) => {
	return {
		ORDERLINE: orderLine,
		PRIMARYSKU: (isPrimarySku || false),
		SKU: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'SKU'),
		STAR: (altOrderLine !== undefined && altSkuType !== 'DEFAULT') ? '*' : '',
		DESCRIPTION: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'DESCRIPTION'),
		THEME: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'THEME'),
		AVAILABLE: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'AVAILABLE'),
		EXPRAVAIL: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'EXPRAVAIL'),
		AAO: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'AAO'),
		EXPRAAO: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'EXPRAAO'),
		WEEKLYSALES: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'WEEKLYSALES'),
		NEWWOS: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'NEWWOS'),
		SALESRATIO: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'SALESRATIO'),
		ORDER: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'ORDER'),
		AAOR: getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'AAOR')
	};
};
export const getSuggestedOrdersDetailsGridJsonRowFromOrderLine = (orderLine) => {
	return {
		ORDERLINE: orderLine,
		SKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'SKU'),
		PDMProductId: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PDMProductId'),
		ALIAS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'alias'),
		FOB: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'fob'),
		Color: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Color'),
		VendorDescription: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'VendorDescription'),
		Family: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Family'),
		Cube: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Cube'),
		DailyMean: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DailyMean'),
		WeeklySales: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'WeeklySales'),
		TotalSales: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'TotalSales'),
		BVQOH: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'BVQOH'),
		BVINBOUND: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'BVINBOUND'),
		LTAVAIL: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'LTAVAIL'),
		WeeksOfStock: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'WeeksOfStock'),
		DailyStdDev: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DailyStdDev'),
		SafetyStock: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'SafetyStock'),
		ReplenishmentStock: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ReplenishmentStock'),
		Trigger: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Trigger'),
		ADTS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ADTS'),
		DaysToNextOrder: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DaysToNextOrder'),
		WOSOH: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'WOSOH'),
		QtyToWHS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'QtyToWHS'),
		QtyFromWHS: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'QtyFromWHS'),
		CalcOrder: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'CalcOrder'),
		Order: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Order'),
		LogOrder: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'LogOrder'),
		STOREQTY: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'STOREQTY'),
		PDMPieceCount: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PDMPieceCount'),
		PieceCount: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'PieceCount'),
		BVAVAIL: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'BVAVAIL'),
		EOLAVAIL: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'EOLAVAIL'),
		AvailableAfterOrder: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'AvailableAfterOrder'),
		Ratio: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Ratio'),
		ProductionTime: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ProductionTime'),
		TransitTime: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'TransitTime'),
		IntraDivisional: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'IntraDivisional'),
		LoadFactor: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'LoadFactor'),
		SkuGroup: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'SkuGroup'),
		FactoryColor: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'FactoryColor'),
		Theme: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'Theme'),
		InactiveSKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'InactiveSKU'),
		DiscontinuedSKU: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'DiscontinuedSKU'),
		ToBeDropped: getSuggestedOrdersDetailsGridValueFromOrderLine(orderLine, 'ToBeDropped')
	};
};

export const getSuggestedOrdersOrdersGridValueFromOrderLine = (screenId, orderLine, col, excludeRatios) => {
	switch(col){
		/* lblSku in OrderSku.mxml */
		case 'SKU':
			return orderLine.SKU || '';
		case 'STAR':
			// eslint-disable-next-line no-case-declarations
			const {altOrderLine, altSkuType} = getAltSkuInfo(screenId, orderLine.SKU);
			return (altOrderLine !== undefined && altSkuType !== 'DEFAULT') ? '*' : '';
		/* lblDesc in OrderSku.mxml */
		case 'DESCRIPTION':
			return assembleDescription(orderLine);
		case 'THEME':
			return orderLine.Theme;
		/* lblAvailable in OrderSku.mxml */
		case 'AVAILABLE':
			return Number(orderLine.LTAVAIL || 0);
		case 'EXPRAVAIL':
			return Number(orderLine.PromoAvail || 0);
		/* lblAAO in OrderSku.mxml */
		case 'AAO':
			return Number(orderLine.LTAVAIL || 0) + Number(orderLine.Order || 0);
		case 'EXPRAAO':
			return Number(orderLine.PromoAvail || 0) > 0
				? Number(orderLine.Order || 0) + Number(orderLine.PromoAvail || 0)
				: Number(orderLine.PromoAvail || 0);
		/* lblWeekly in OrderSku.mxml */
		case 'WEEKLYSALES':
			return Number(orderLine.WeeklySales || 0);
		/* lblNWOS in OrderSku.mxml */
		case 'NEWWOS':
			return Number(orderLine.WeeklySales || 0) > 0
				? (getSuggestedOrdersOrdersGridValueFromOrderLine(screenId, orderLine, 'AAO', excludeRatios) / Number(orderLine.WeeklySales))
				: (Number(orderLine.WeeksOfStock || 0) > 0 ? (Number(orderLine.WeeksOfStock || 0)) : 0);
		case 'SALESRATIO':
			return excludeRatios ? '' : Number(orderLine.SalesRatio || 0);
		/* txtOrder in OrderSku.mxml */
		case 'ORDER':
			return Number(orderLine.Order || 0);
		/* txtRatio in OrderSku.mxml */
		case 'AAOR':
			return Number(orderLine.Ratio || 0);
		case 'PIECECOUNT':
			return Number(orderLine.PieceCount || 0);
		default:
			return undefined;
	}
};

const getSuggestedOrdersDetailsGridValueFromOrderLine = (orderLine, col) => {
	const upperCaseOrderLine = Object.entries(orderLine).map(x => [x[0].toUpperCase(), x[1]]);
	const kvPairs = upperCaseOrderLine.filter(x => x[0] === col.toUpperCase());
	return kvPairs.length === 1 ? kvPairs[0][1] : undefined;
};

const assembleDescription = (orderLine) => {
	const description = orderLine.Finish 
		+ ' - ' 
		+ orderLine.Color + ' :: ' 
		+ orderLine.VendorDescription;

	const detailDescription = 'Avg Daily Sales: ' + String(orderLine.DailyMean) 
		+ '    QOH: ' + String(orderLine.QOH) 
		+ '    Inbound: ' + String(orderLine.BVINBOUND) 
		+ '    Store Qty: ' + String(orderLine.STOREQTY) 
		+ '    Weeks of Stock: ' + String(orderLine.WeeksOfStock) 
		+ '    Safe Stock: ' + String(orderLine.SafetyStock) 
		+ '    Cube: ' + String(orderLine.Cube);
	return {
		detail: description,
		moreDetail: detailDescription
	};
};
export const getSuggestedOrdersGridExcelExportDataSet = (gridRef, rowData, mustExcludedColumns, valueGetterOverrides) => {
	const results = gridUtilities.excludeColumnsForExcelExportDataSet(gridRef.api.getColumns(), gridRef.api.getAllDisplayedColumns(), rowData, mustExcludedColumns);
	const obj = sharedUtilities.convertToJsonArray(results, undefined, 'COLUMNS', 'DATA', valueGetterOverrides);
	return obj;
};

/**
 * Handle suggested PO grid row qty change. Update row line cost, PO cubes, PO cost and Total suggested order cost.
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Object} gridApi - ag-Grid object for PO
 * @param {Object} changedRowNode - ag-grid RowNode Object where the quantity changed
 * @param {Number} newValue - New value entered by user for Qty
 * @param {Function} onSuggestedPosTotalCostChange - function pointer to same name to update Suggested POs Total Cost when PO cost changes
 * @param {Number} path - path to value being updated
 */
export const changeQty = (dispatch, gridApi, changedRowNode, newValue, onSuggestedPosTotalCostChange=()=>{}, path, useLoadFactor) => {
	const data = changedRowNode.data;
	const qtyDiff = getRowQtyDiff(changedRowNode, newValue);
	//get cost diff
	const costDiff = qtyDiff * numberUtilities.getNumberOrDefault(data.FIRSTCOST, 0);
	//get cube diff
	const cubesDiff = qtyDiff * numberUtilities.getNumberOrDefault(useLoadFactor === true ? data.ORDERLINE.LoadFactor : data.ORDERLINE.Cube, 0);
	changeRowNode(dispatch, gridApi, changedRowNode, qtyDiff, costDiff, path);
	changePoCubes(dispatch, cubesDiff, path);
	changePoTotalCost(dispatch, costDiff, path);
	onSuggestedPosTotalCostChange(costDiff);
};

/**
 * Update PO Total Cost display and redux store value with change indicated by costDiff
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Number} costDiff - Difference in cost from where PO cost used to be
 * @param {Number} path - path to value being updated
 */
const changePoTotalCost = (dispatch, costDiff, path) => {
	const poCost = storeUtilities.getValue([...path, 'totalCost'], 0);
	storeUtilities.updateValue(dispatch, [...path, 'totalCost'], poCost + costDiff, true);
};

/**
 * Update PO Cubes display and redux store value with change indicated by cubesDiff
 * @param {Object} dispatch - Redux Dispatch Object
 * @param {Number} cubesDiff - Difference in cubes from where PO cubes used to be
 * @param {Number} path - path to value being updated
 */
const changePoCubes = (dispatch, cubesDiff, path) => {
	const cubes = storeUtilities.getValue([...path, 'cubes'], 0);
	storeUtilities.updateValue(dispatch, [...path, 'cubes'], cubes + cubesDiff, true);
};

/**
* Get the difference in rowNode quantity caused by value change
* @param {Object} changedRowNode - ag-grid rowNode object, the row that was changed
* @param {Number} newValue - new value typed into input
*/
export const getRowQtyDiff = (changedRowNode, newValue) => {
	const qtyOld = numberUtilities.getNumberOrDefault(Math.floor(changedRowNode.data.QTY), 0);
	const qtyNew = numberUtilities.getNumberOrDefault(Math.floor(newValue), 0, (val) => { return val >= 0; });
	return qtyNew - qtyOld;
};

const changeRowNode = (dispatch, gridApi, changedRowNode, qtyDiff, costDiff, rootPath) => {
	const data = changedRowNode.data;
	const rowDataLine = storeUtilities.getValue([...rootPath, 'rowData', changedRowNode.id], {});
	data.QTY += qtyDiff;
	rowDataLine.QTY = data.QTY;
	data.LINE += costDiff;
	rowDataLine.LINE = data.LINE;
	storeUtilities.updateValue(dispatch, [...rootPath, 'rowData', changedRowNode.id], rowDataLine, true);
	changedRowNode.setData(data);
	gridApi.refreshCells({rowNodes: [changedRowNode], force: true});
};

export const getDeletedPOsCount = (screenId) => {
	const whsList =[storeUtilities.getValue([screenId, 'whsNum'], undefined)];
	let deletedCount = 0;
	whsList.forEach(whs => {
		const whsPOs = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], {});
		for(const value of Object.values(whsPOs)){
			if(value !== undefined && value.deleted === true){
				deletedCount++;
			}	
		}
	});
	return deletedCount;
};
export const getTotalPOsCount = (screenId) => {
	const whsList = [storeUtilities.getValue([screenId, 'whsNum'], undefined)];
	let count = 0;
	whsList.forEach(whs => {
		const whsPOs = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], {});
		const values = whsPOs !== undefined ? Object.values(whsPOs) : [];
		for(let i = 0; i < values.length; i++){
			count++;
		}
	});
	return count;
};
export const getPOCountsPerWhs = (screenId, whsList) => {
	let whsPOCounts = {};
	for(let whsListIndex = 0; whsListIndex < whsList.length; whsListIndex++){
		const whs = whsList[whsListIndex];
		const whsPOs = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', whs], undefined);
		let count = 0;
		if(whsPOs !== undefined){
			for(const value of Object.values(whsPOs)){
				if(value['rowData'] !== undefined){
					whsPOCounts[whs] = ++count;
				}
			}
		}
	}
	return whsPOCounts;
};
export const useCubes = (screenId) => {
	const selectedCategory = storeUtilities.getValue([screenId, 'merchGroup']);
	const useCubes = selectedCategory === undefined 
	|| selectedCategory===''
	|| !['Pillows'].includes(selectedCategory);
	return useCubes;
};
export const showOnOrderDialog = (dispatch, screenId, vendor) => {
	const whsList = sharedUtilities.getWhsList(true);
	const callback = () => {
		dispatch(showDialogAction(
			<OnOrderDialogContainer 
				screenId={screenId}
				vendor={vendor}
			/>,
			400, 
			800, 
			()=>{},
			true,
			undefined,
			true,
			false));
	};
	if(whsList === undefined){
		//this function grabs all warehouses... TODO: save this to cookies, and re-fetch this whenever the user logs in and his/her cookies are expired
		dispatch(rpcAction({args:{}, nodeRoute:nodeRoutes.IFRServiceNET, endpoint:'/Warehouse/Warehouse', callback:(data) => {
			if(data && Array.isArray(data)){
				storeUtilities.updateValue(dispatch, ['whsList'], data, true, true);
				getOnOrderList(dispatch, screenId, vendor, callback);
			}
		}, showLoadingMask:false}));
	}
	else {
		storeUtilities.updateValue(dispatch, ['whsList'], whsList, true, true);
		getOnOrderList(dispatch, screenId, vendor, callback);
	}
};
const getOnOrderList = (dispatch, screenId, vendor, callback) => {
	const args = { Vendor: vendor, NumberOfMonths: 9 };
	dispatch(rpcAction({
		args: args,
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Parts/GetOnOrders',
		method: 'GET',
		callback: (data) => {
			if(data && Array.isArray(data.onOrderLists) && data.onOrderLists.length > 0)
			{
				storeUtilities.updateValue(dispatch, [screenId, 'OnOrderGrid', 'rowData'], data.onOrderLists);
				if(callback){
					callback();
				}
			}
		}
	}));
};
export const setDatesBasedOnMaxPerWeek = (screenId, purchaseOrderSkus) => {
	let i = 0;
	const max = storeUtilities.getValue([screenId, 'maxPerWeek'], 0);
	const selectedCategory = storeUtilities.getValue([screenId, 'merchGroup'], '');
	const isBalancedOrdering = selectedCategory === 'Balanced Ordering';
	if(!Array.isArray(purchaseOrderSkus) || max <= 0){
		return purchaseOrderSkus;
	}
	const poSKUs = cloneDeep(purchaseOrderSkus);
	const pos = {};
	const weeklyPOs = {};
	for (i = 0; i < poSKUs.length; i++){
		const poSKU = poSKUs[i];
		const key = poSKU.PO;
		if(pos[key] === undefined){
			pos[key] = [];
		}
		pos[key].push(poSKU);
	}
	const poKeys = Object.keys(pos);
	const dateFormat = 'MM/DD/YYYY';
	for(i = 0; i < poKeys.length; i++){
		const po = pos[poKeys[i]];
		const shipMoment = isBalancedOrdering ? po[0].SHIPDATE : moment(po[0].SHIPDATE, dateFormat);
		const key = assembleKeyFromDate(shipMoment);
		if(weeklyPOs[key] === undefined){
			weeklyPOs[key] = [];
		}
		weeklyPOs[key].push(po);
	}

	const result = setPurchaseOrderDates(screenId, weeklyPOs, {}, max, dateFormat, isBalancedOrdering);
	if(result !== undefined){
		const newWeeklyPOs = result.result;
		const newPOSKUsList = [];
		const keyArray = Object.keys(newWeeklyPOs);
		keyArray.sort(sortKeys);
		for(i = 0; i < keyArray.length; i++){
			const key = keyArray[i];
			const weekPOs = newWeeklyPOs[key];
			weekPOs.forEach(po => {
				po.forEach(poSKU => {
					newPOSKUsList.push(poSKU);
				});
			});
		}
		return newPOSKUsList;
	}
	return purchaseOrderSkus;
};
const getQty = (screenId, pos) => {
	const selectedCategory = storeUtilities.getValue([screenId, 'merchGroup']);
	const isPillows = selectedCategory===undefined || selectedCategory==='' 
		? undefined 
		: 'Pillows' === selectedCategory;
	if(isPillows === undefined){
		return 0;
	}
	if(Array.isArray(pos)){
		if(isPillows === false){
			return pos.length;
		}
		else {
			let sumPillowQtys = 0;
			pos.forEach(po => {
				if(Array.isArray(po)){
					po.forEach(poSku => {
						sumPillowQtys += parseInt(poSku.QTY);
					});
				}
			});
			return sumPillowQtys;
		}
	}
	return 0;
};
const setPurchaseOrderDates = (screenId, remaining, result, max, dateFormat, isBalancedOrdering) => {
	const firstKey = getKey(remaining, 0);
	const nextKey = getKey(remaining, 1);
	const remainingCopy = cloneDeep(remaining) || {};
	const resultCopy = cloneDeep(result) || {};
	const firstKeyQty = getQty(screenId, remainingCopy[firstKey]);
	if(firstKeyQty <= max){
		if(nextKey === undefined){
			if(resultCopy[firstKey] === undefined && remainingCopy[firstKey] !== undefined){
				resultCopy[firstKey] = remainingCopy[firstKey];
			}
			return {leftover: {}, result: resultCopy};
		}
		else {
			if(remainingCopy[firstKey] !== undefined){
				const completed = remainingCopy[firstKey];
				if(completed !== undefined){
					resultCopy[firstKey] = completed;
				}
				delete remainingCopy[firstKey];
			}
			return setPurchaseOrderDates(screenId, remainingCopy, resultCopy, max, dateFormat, isBalancedOrdering);
		}
	}
	else {
		if(firstKeyQty > 0){
			const toMove = remainingCopy[firstKey].pop();
			const rest = remainingCopy[firstKey];
			if(Array.isArray(toMove) && toMove.length > 0){
				const arrivalMoment = isBalancedOrdering ? toMove[0].ARRIVALDATE : moment(toMove[0].ARRIVALDATE, dateFormat);
				const newArrivalMoment = arrivalMoment.clone().add(1, 'w');
				toMove.forEach(po => {
					po.ARRIVALDATE = isBalancedOrdering ? newArrivalMoment : newArrivalMoment.format(dateFormat);
				});

				const shipMoment = moment(toMove[0].SHIPDATE, dateFormat);
				const newShipMoment = shipMoment.clone().add(1, 'w');
				toMove.forEach(po => {
					po.SHIPDATE = isBalancedOrdering ? newShipMoment : newShipMoment.format(dateFormat);
				});

				remainingCopy[firstKey] = rest;
				const newKey = assembleKeyFromDate(newShipMoment);
				if(remainingCopy[newKey] === undefined){
					remainingCopy[newKey] = [];
				}
				remainingCopy[newKey].unshift(toMove);
				return setPurchaseOrderDates(screenId, remainingCopy, resultCopy, max, dateFormat, isBalancedOrdering);
			}
		}
	}
};
const getKey = (weeklyPOs, keyIndex) => {
	const keyArray = Object.keys(weeklyPOs);
	const keyArrayLength = keyArray.length;
	if(keyIndex > (keyArrayLength - 1)){
		return;
	}
	keyArray.sort(sortKeys);
	return keyArray[keyIndex];
};
const sortKeys = (key1, key2) => {
	const key1Arr = key1.split ? key1.split('|') : key1;
	const key2Arr = key2.split ? key2.split('|') : key2;
	const year1 = parseInt(key1Arr[0]);
	const year2 = parseInt(key2Arr[0]);
	const week1 = parseInt(key1Arr[1]);
	const week2 = parseInt(key2Arr[1]);
	if(year1 > year2){
		return 1;
	}
	else if(year1 < year2){
		return -1;
	}
	else {
		if(week1 > week2){
			return 1;
		}
		else if(week1 < week2){
			return -1;
		}
		else {
			return 0;
		}
	}
};
const assembleKeyFromDate = (mm) => {
	const key = mm.year() + '|' + mm.isoWeek();
	return key;
};
export const handleSpecialOrderPillowsSelected = (dispatch, screenId, maximizeCube, category) => {
	const OkCancelDialogContainer = React.lazy(() => import('../../../components/dialogs/OkCancelDialogContainer'));
	if(maximizeCube === true){
		dispatch(showDialogAction(
			<Suspense fallback={<div>Loading...</div>}>
				<OkCancelDialogContainer 
					screenId={ screenId + '_informationDialog'} 
					title={'Information'}
					message={'It is not possible to Maximize Cube when \'' + category + '\' is selected as the Category.'}
					hasCancelButton={false}
					handleOkClicked={()=>{
						storeUtilities.updateValue(dispatch, [screenId, 'maximizeCube'], false);
					}}
				>
				</OkCancelDialogContainer>
			</Suspense>, 
			200, 
			300, 
			()=>{}));
	}
};
export const getQtySum = (gridApi) => {
	let qtySum = 0;
	if(gridApi && gridApi.forEachNode){
		gridApi.forEachNode(node => {
			qtySum += numberUtilities.getNumberOrDefault(node.data.QTY, 0, (x) => { return x >= 0; });
		});
	}
	return qtySum;
};

export const setFlowURLFromAppConfig = (dispatch, path) => {
	dispatch(rpcAction({
		showLoadingMask: false,
		nodeRoute: nodeRoutes.FlowURL,
		callback: (data) => {
			if(data?.url !== undefined){
				storeUtilities.updateValue(dispatch, path, data.url, true, false);
			}
		}
	}));
};

export const setFlowAllURLFromAppConfig = (dispatch, path) => {
	dispatch(rpcAction({
		showLoadingMask: false,
		nodeRoute: nodeRoutes.FlowAllURL,
		callback: (data) => {
			if(data?.url !== undefined){
				storeUtilities.updateValue(dispatch, path, data.url, true, false);
			}
		}
	}));
};

export const setValueFromAppConfig = (dispatch, path, nodeRoute, key) => {
	dispatch(rpcAction({
		showLoadingMask: false,
		nodeRoute: nodeRoute,
		callback: (data) => {
			if(data?.[key] !== undefined){
				storeUtilities.updateValue(dispatch, path, data[key], true, false);
			}
		}
	}));
};

export const functionRunner = (fn) => {
	//wrapping any function call with this will make it overridable for jest unit tests
	return fn();
};
const handleOrderOrAAOChanged = (
	{
		dispatch, 
		path, 
		newValue, 
		orderLine, 
		altOrderLine, 
		altSkuType, 
		callback, 
		changedOrderLineField, 
		fnAdditionalOrderLineChanges,
		orderLinesRowData
	}) => {
	const ol = cloneDeep(orderLine);
	let rowData = undefined;
	if(Array.isArray(orderLinesRowData)){
		for(let rowIndex = 0; rowIndex < orderLinesRowData.length; rowIndex++){
			if(orderLinesRowData[rowIndex]['sku'] === ol.SKU){
				rowData = orderLinesRowData[rowIndex];
			}
		}
	}
	if(rowData === undefined){
		return;
	}
	ol[changedOrderLineField] = newValue;
	if(altSkuType === constants.altSkuType.SUBSTITUTION && altOrderLine !== undefined){
		ol['LTAVAIL'] = altOrderLine.LTAVAIL;
		ol['WeeklySales'] = altOrderLine.WeeklySales;
		ol['DailyMean'] = altOrderLine.DailyMean;
	}
	else if(altSkuType === constants.altSkuType.COMBINATION && altOrderLine !== undefined){
		ol['LTAVAIL'] = ol.LTAVAIL + altOrderLine.LTAVAIL;
		ol['WeeklySales'] = ol.WeeklySales + altOrderLine.WeeklySales;
		ol['DailyMean'] = ol.DailyMean + altOrderLine.DailyMean;
	}
	if(fnAdditionalOrderLineChanges){
		const changedOL = fnAdditionalOrderLineChanges(ol);
		Object.assign(ol, changedOL);
	}
	rowData['LTAVAIL'] = ol['LTAVAIL'];
	rowData['WEEKLYSALES'] = ol['WeeklySales'];
	rowData['DAILYMEAN'] = ol['DailyMean'];
	rowData['ORDERLINE'] = ol;
	
	//update values in store
	storeUtilities.updateValue(dispatch, [...path, 'OrderLinesRowData'], orderLinesRowData);
	if(callback){
		callback(ol);
	}
	return ol;
};
export const handleOrderChanged = ({dispatch, path, newValue, orderLine, altOrderLine, altSkuType, callback, orderLinesRowData}) => {
	const val = numberUtilities.getNumberOrDefault(newValue, 0); 
	const additionalOrderLineChanges = (orderLine) => {
		const ol = cloneDeep(orderLine);
		const newAAO = Math.floor(numberUtilities.getNumberOrDefault(ol.LTAVAIL) + val);
		ol['AvailableAfterOrder'] = newAAO;
		return ol;	
	};
	return handleOrderOrAAOChanged(
		{
			dispatch, 
			path, 
			newValue: val, 
			orderLine, 
			altOrderLine, 
			altSkuType, 
			callback, 
			changedOrderLineField: 'Order', 
			fnAdditionalOrderLineChanges: additionalOrderLineChanges,
			orderLinesRowData
		});
};

export const handleAAOChanged = ({dispatch, path, newValue, orderLine, altOrderLine, altSkuType, callback, orderLinesRowData}) => {
	const val = numberUtilities.getNumberOrDefault(newValue, 0); 
	const additionalOrderLineChanges = (orderLine) => {
		const ol = cloneDeep(orderLine);
		const newOrderValue = (val - numberUtilities.getNumberOrDefault(ol.LTAVAIL) < 0) 
			? 0 
			: (val - numberUtilities.getNumberOrDefault(ol.LTAVAIL));
		ol['Order'] = newOrderValue;
		return ol;
	};
	return handleOrderOrAAOChanged(
		{
			dispatch, 
			path, 
			newValue: val, 
			orderLine, 
			altOrderLine, 
			altSkuType, 
			callback, 
			changedOrderLineField: 'AvailableAfterOrder', 
			fnAdditionalOrderLineChanges: additionalOrderLineChanges,
			orderLinesRowData
		});
};
export const handleOrdersGridUpdated = ({gridApi, excludeRatios, changedRowNode}) => {
	if(excludeRatios === false){
		if(changedRowNode && changedRowNode?.data?.PRIMARYSKU === true){
			handlePrimaryRowOrderChanged(gridApi);
		}
		else {
			handleNonPrimaryRowOrderChanged(gridApi);
		}
	}
};
export const handlePrimaryRowOrderChanged = (gridApi) => {
	const primaryRowNode = getPrimaryNodeFromGrid(gridApi);
	if(primaryRowNode !== undefined){
		const primaryQty = numberUtilities.getNumberOrDefault(primaryRowNode.data.ORDERLINE.Order);
		const primaryDailyMean = numberUtilities.getNumberOrDefault(primaryRowNode.data.ORDERLINE.DailyMean);
		const primaryAvailableAfterOrder = primaryQty + numberUtilities.getNumberOrDefault(primaryRowNode.data.ORDERLINE.LTAVAIL);
		gridApi.forEachNode(node => {
			const data = node.data;
			const ltavail = numberUtilities.getNumberOrDefault(data.ORDERLINE.LTAVAIL);
			if(data.PRIMARYSKU === false){
				const orderLineSalesRatio = numberUtilities.getNumberOrDefault(data.ORDERLINE.SalesRatio);
				const newValue = (primaryAvailableAfterOrder * orderLineSalesRatio) - ltavail;
				data.ORDERLINE.Order = numberUtilities.getNumberOrDefault(newValue, 0, (val)=> { return val >= 0; });
				updateSingleOrderLineRatios(primaryRowNode.data.ORDERLINE, data.ORDERLINE, primaryAvailableAfterOrder, primaryDailyMean, gridApi);
				node.setDataValue('ORDERLINE', data.ORDERLINE);
				node.setDataValue('ORDER', data.ORDERLINE.Order);
				gridApi.refreshCells({rowNodes:[node], force:true});
			}
		});
	}
};
export const handleNonPrimaryRowOrderChanged = (gridApi) => {
	const primaryNode = getPrimaryNodeFromGrid(gridApi);//edited row is not primary, so grab primary
	const orderLines = getOrderLinesFromGrid(gridApi);
	const primaryRowIndex = getPrimaryRowIndexFromGrid(gridApi);
	if(primaryNode !== undefined && primaryNode.data !== undefined && primaryRowIndex !== -1){
		setPrimarySku(orderLines, primaryNode.data.ORDERLINE, gridApi);
	}
};
export const updateSingleOrderLineRatios = (primaryOrderLine, orderLine, primaryAvailableAfterOrder, primaryDailyMean, gridApi) => {
	const isPrimary = primaryOrderLine?.SKU === orderLine.SKU;
	if(!isPrimary){
		const lineAfterOrder = numberUtilities.getNumberOrDefault(orderLine.Order) + numberUtilities.getNumberOrDefault(orderLine.LTAVAIL);
		let ratio = 0;
		let salesRatio = 0;

		if(lineAfterOrder > 0 && primaryAvailableAfterOrder > 0){
			ratio = lineAfterOrder / primaryAvailableAfterOrder;
		}
		orderLine.Ratio = ratio;
		const dailyMean = numberUtilities.getNumberOrDefault(orderLine.DailyMean);
		if(dailyMean > 0 && primaryDailyMean > 0){
			salesRatio = dailyMean / primaryDailyMean;
		}
		orderLine.SalesRatio = salesRatio;
	}
	else{
		orderLine.SalesRatio = 1;
	}
	if(gridApi){
		const node = getNode(gridApi, orderLine.SKU);
		if(node){
			node.setDataValue('PRIMARYSKU', isPrimary);
			node.setDataValue('ORDERLINE', orderLine);
			gridApi.refreshCells({rowNodes:[node], force:true});
		}
	}
};
export const setPrimarySku = (orderLines, primaryOrderLine, gridApi) => {
	if(Array.isArray(orderLines) && orderLines.length > 0){
		const primaryQty = primaryOrderLine ? numberUtilities.getNumberOrDefault(primaryOrderLine?.Order) : 0;
		const primaryAvailableAfterOrder = primaryQty + numberUtilities.getNumberOrDefault(primaryOrderLine ? primaryOrderLine?.LTAVAIL : 0);
		const primaryDailyMean = numberUtilities.getNumberOrDefault(primaryOrderLine ? primaryOrderLine?.DailyMean : 0);
		for(let i = 0; i < orderLines.length; i++){
			updateSingleOrderLineRatios(primaryOrderLine, orderLines[i], primaryAvailableAfterOrder, primaryDailyMean, gridApi);
		}
	}
};
export const calculateAndUpdateTotalOrderQty = (dispatch, path, gridApi) => {
	let totalOrderQty = 0;
	gridApi.forEachNode((node)=>{
		if(parseFloat(node.data.ORDERLINE.Order) > 0)
			totalOrderQty += parseFloat(node.data.ORDERLINE.Order);
	});
	storeUtilities.updateValue(dispatch, [...path, 'totalOrderQty'], totalOrderQty);
};
export const handleRatioChanged = (gridApi, changedRowNode, newValue, excludeRatios, callback) => {
	const data = changedRowNode.data;
	if(excludeRatios === false){
		if(data.PRIMARYSKU === false){//edited row is not primary, so grab primary
			data.ORDERLINE.Ratio = numberUtilities.getNumberOrDefault(newValue,	0, (val)=> { return val >= 0; });
			const primaryNode = getPrimaryNodeFromGrid(gridApi);

			if(primaryNode !== undefined && primaryNode.data !== undefined && primaryNode.data.ORDERLINE !== undefined){
				const primaryQty = numberUtilities.getNumberOrDefault(primaryNode.data.ORDERLINE.Order);
				const primaryAvailableAfterOrder = primaryQty + numberUtilities.getNumberOrDefault(primaryNode.data.ORDERLINE.LTAVAIL);
				const ltavail = numberUtilities.getNumberOrDefault(data.ORDERLINE.LTAVAIL);
				data.ORDERLINE.Order = Math.floor((primaryAvailableAfterOrder * numberUtilities.getNumberOrDefault(data.ORDERLINE.Ratio)) - ltavail);
				setPrimarySku(getOrderLinesFromGrid(gridApi), primaryNode.data.ORDERLINE, gridApi);
			}
		}
	}
	if(callback){
		callback(data.ORDERLINE);
	}
};
export const getExcludedMomentRanges = (path, dateFormat) => {
	const excludedMomentRanges = storeUtilities.getValue(path, []);
	const excludeDates = [];
	if(Array.isArray(excludedMomentRanges)){
		excludedMomentRanges.forEach(range => {
			let curr = range.start.clone();
			while(curr.isBefore(range.end)){
				const formatted = curr.format(dateFormat);
				if(!excludeDates.includes(formatted)){
					excludeDates.push(formatted);
				}
				curr = curr.add(1, 'days');
			}
		});
	}
	return excludeDates;
};
export const getAltSubQty = (orderLine, altOrderLine) => {
	if(orderLine === undefined || altOrderLine === undefined){
		return 0;
	}
	else {
		return (altOrderLine.SafetyStock 
			+ altOrderLine.ReplenishmentStock 
			+ altOrderLine.DaysToNextOrder 
			+ altOrderLine.ADTS) 
			- orderLine.LTAVAIL;
	}
};
export const getAltComQty = (orderLine, altOrderLine) => {
	if(orderLine === undefined || altOrderLine === undefined){
		return 0;
	}
	else {
		return (altOrderLine.SafetyStock 	
			+ orderLine.SafetyStock 	
			+ altOrderLine.ReplenishmentStock 	
			+ orderLine.ReplenishmentStock 	
			+ altOrderLine.DaysToNextOrder 	
			+ orderLine.DaysToNextOrder 	
			+ altOrderLine.ADTS 
			+ orderLine.ADTS		
		) 	- (altOrderLine.LTAVAIL + orderLine.LTAVAIL);
	}
};
export const refreshSuggestedOrdersGrids = ({ordersGridApi, detailsGridApi, excludeRatios, changedRowNode}) => {
	handleOrdersGridUpdated({gridApi: ordersGridApi, excludeRatios: excludeRatios, changedRowNode});
	setTimeout(() => {
		if(ordersGridApi?.forEachNode){
			ordersGridApi.forEachNode(node => {
				ordersGridApi.refreshCells({rowNodes: [node], force: true});
			});
		}
		if(detailsGridApi?.forEachNode){
			detailsGridApi.forEachNode(node => {
				detailsGridApi.refreshCells({rowNodes: [node], force: true});
			});
		}
	}, 500);
};
export const getCreatePOResponseDialogElements = (data, identifier, actionTaken) => {
	const whsList = sharedUtilities.getWhsList(false);
	const successfulJSXElements = [];
	const unsuccessfulJSXElements = [];
	const whsPOs = {};
	let jsxElementIndex = 0;
	if(Array.isArray(data) && data.length > 0){
		data.sort((a, b) => {
			const whsA = a['WAREHOUSE'];
			const whsB = b['WAREHOUSE'];
			return whsA > whsB ? -1 : (whsB > whsA ? 1 : 0);
		}).forEach(row => {
			const whs = row['WAREHOUSE'];
			if(!(whs in whsPOs)){
				whsPOs[whs] = [];
			}
			whsPOs[whs].push(row);
		});
		const whsPOsArray = Object.entries(whsPOs);
		whsPOsArray.forEach(([warehouse, pos])=>{
			const successfulPOs = [];
			const unsuccessfulPOs = [];
			pos.forEach((row)=>{
				if(!row['SUCCESS']){
					unsuccessfulPOs.push(row);
				}
				else {
					successfulPOs.push(row);
				}
			});
			let whsName = undefined;
			const filteredList = whsList.filter(x => x.whsId === warehouse);
			if(filteredList.length === 1){
				whsName = filteredList[0].whsName !== undefined ? filteredList[0].whsName : '';
			}
			successfulPOs.forEach((po, index) => {
				if(successfulJSXElements.length === 0){
					const message = 'Purchase Orders ' + actionTaken;
					successfulJSXElements.push(<div key={jsxElementIndex++}><h4>{message}</h4></div>);
				}
				if(index === 0 && whsName !== undefined){
					successfulJSXElements.push(
						<div key={jsxElementIndex++} style={{textDecoration: 'underline'}}>
							<h5>{whsName}</h5>
						</div>
					);
				}
				successfulJSXElements.push(
					<div className={'text-muted'} key={jsxElementIndex++}>
						<Container fluid={true}>
							<Row>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									#{po.REQUESTEDPONUMBER}
								</Col>
								<Col xs={10} sm={10} md={10} lg={10} xl={10}>
									<span style={{fontWeight: 'bold'}}>{identifier + ' ' + po.PONUMBER}</span>
								</Col>
							</Row>
						</Container>
					</div>);

			});
			unsuccessfulPOs.forEach((po, index)=>{
				if(unsuccessfulJSXElements.length === 0){
					unsuccessfulJSXElements.push(<br key={jsxElementIndex++} />);
					unsuccessfulJSXElements.push(<br key={jsxElementIndex++} />);
					unsuccessfulJSXElements.push(<div key={jsxElementIndex++}><h5>The following errors occurred</h5></div>);
				}

				if(index === 0 && whsName !== undefined){
					unsuccessfulJSXElements.push(
						<div key={jsxElementIndex++} style={{textDecoration: 'underline'}}>
							<h5>{whsName}</h5>
						</div>
					);
				}
				const poNumberSpan = po?.PONUMBER?.trim && po.PONUMBER.trim() !== '' ? (<span style={{fontWeight: 'bold'}}>{identifier + ' ' + po.PONUMBER}</span>) : <></>;
				unsuccessfulJSXElements.push(
					<div key={jsxElementIndex++}>
						<Container fluid={true}>
							<Row>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									#{po.REQUESTEDPONUMBER}
								</Col>
								<Col xs={10} sm={10} md={10} lg={10} xl={10}>
									{poNumberSpan}<br />{po.STATUSCODE}
								</Col>
							</Row>
						</Container>
					</div>
				);
				if(index !== (unsuccessfulPOs.length - 1)){
					unsuccessfulJSXElements.push(<br key={jsxElementIndex++}/>);
				}
			});
		});
		return {
			successfulJSXElements,
			unsuccessfulJSXElements
		};
	}
	else {
		if(data !== undefined){
			sharedUtilities.showError(data.error ? data.error : 'An error has occured', data.detail ? data.detail : '', 'Error Creating Purchase Orders');
		}
	}
};
export const exportToExcelClicked = (dispatch, screenId, dataset) => {
	if(dataset.length <= 0){
		sharedUtilities.showError('No data to export', 'You must click Get Suggested Orders before clicking Export to Excel', 'ERROR');
		return;
	}
	dispatch(rpcAction({
		args: dataset,
		nodeRoute: nodeRoutes.DownloadExcel,
		method: 'POST',
		requestInBody: true,
		customResponseHandler: async (response) => {
			const now = moment().format('YYYY-MM-DD, h-mm.SSS A');
			const currentTab = storeUtilities.getValue([screenId, 'suggestedOrdersTabShown'], 'Orders');
			const blob = await response.blob();
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = (screenId === 'orderpartsscreen' ? 'Parts' : 'Products') + ' Suggested Orders ' + now + ' (' + currentTab + ' Tab).xlsx';
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove(); //afterwards we remove the element again    
		}
	}));
};