import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
	ButtonContainer,
	DataGridContainer2,
	TextButtonCellRenderer 
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import MultiValueCellRenderer from './MultiValueCellRenderer';
import * as sharedUtilites from '../../../utilities/sharedUtilities';

class ViewEditCreatePOsDialog extends Component {
	constructor(props){
		super(props);
		this.gridApi = undefined;
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	formatRowValue = (params) => {
		if(this.props.formatRowValue){
			return this.props.formatRowValue(params);
		}
	};
	getRowValue = (params) => {
		if(this.props.getRowValue){
			return this.props.getRowValue(params);
		}
	};
	getRowHeight = (params) => {
		return params.data.rowModel.length * 27;
	};
	onViewPOClicked = (node, gridApi) => {
		return this.props.onViewPOClicked 
			? this.props.onViewPOClicked(node, gridApi)
			: undefined;
	};
	onGridReady = (params) => {
		params.api.sizeColumnsToFit();
		this.gridApi = params.api;
	};
	onCreatePOsClicked = () => {
		if(this.props.onCreatePOsClicked){
			this.props.onCreatePOsClicked(this.gridApi);
		}
	};
	onCloseClicked = () => {
		if(this.props.onCloseClicked){
			this.props.onCloseClicked();
		}
	};
	onDeleteClicked = () => {
		if(this.props.onDeleteClicked){
			this.props.onDeleteClicked(this.gridApi);
		}
	};
	onRowSelected = (event) => {
		if(this.props.onRowSelected){
			this.props.onRowSelected(event);
		}
	};
	onCellClicked = (e) => {
		if(this.props.onCellClicked && e.colDef.colId !== 'view'){
			this.props.onCellClicked(e);
		}
	};
	handlekeyDown = (e) => {
		if(e.ctrlKey && e.which === 65) {
			e.preventDefault();
			this.gridApi.selectAll();
		}
	};
	onSelectAllClicked = () => {
		if(!this.gridApi){
			return;
		}
		this.gridApi.selectAll();
	};
	onDeselectAllClicked = () => {
		if(!this.gridApi){
			return;
		}
		this.gridApi.deselectAll();
	};
	render() {
		const gridName = 'QueuedPOsDataGrid';
		return (
			<Card 
				className={'h-100 w-100'} 
				style={{backgroundColor: 'white'}} 
				onKeyDown={this.handlekeyDown}>
				<Card.Header className={'dlgdraggable'}>
					<Row noGutters={true}>
						<Col>
							<h4>Queue Entries</h4>
						</Col>
						<Col>
							&nbsp;
						</Col>
						<Col>
							<div className={'d-flex ml-auto'} style={{paddingLeft:'4px'}}>
								<div className={'w-100 mr-2'}>
									<ButtonContainer 
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'btnSelectAll'}
										text={'Select All'}
										onClick={this.onSelectAllClicked}
										fill
										store={store}
									/>
								</div>
								<div className={'w-100'}>
									<ButtonContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'btnDeselectAll'}
										text={'Clear Selections'}
										onClick={this.onDeselectAllClicked}
										fill
										store={store}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Header>
				<div className={'h-100 w-100'}>
					<DataGridContainer2 className={'h-100 w-100'}
						screenId={this.props.screenId}
						panelId={this.props.panelId}
						fieldId={gridName}
						gridName={gridName}
						overridePath={[this.props.screenId, this.props.panelId, 'QueuedPOsDataGrid']}
						rowHeight={27}
						getRowHeight={this.getRowHeight}
						onGridReady={this.onGridReady}
						rowSelection={{ 
							mode: 'multiRow',
							checkboxes: false,
							headerCheckbox: false,
							enableClickSelection: false 
						}}
						onRowSelected={this.onRowSelected}
						onCellClicked={this.onCellClicked}
						defaultColDef={{
							valueFormatter: this.formatRowValue,
							valueGetter: this.getRowValue
						}}
						columnDefs={[
							{
								headerName: 'Queue ID',
								headerClass: 'ag-center-header',
								field: 'queueID',
								colId: 'queueID',
								sortable: true,
								width: 75,
								minWidth: 75,
								cellStyle: {textAlign: 'center'}
							},
							{
								headerName: 'Date Queued',
								field: 'dateQueued',
								colId: 'dateQueued',
								sortable: true,
								width: 150,
								minWidth: 150
							},
							{
								headerName: 'SKU',
								field: 'skus',
								colId: 'skus',
								sortable: false,
								width: 85,
								minWidth: 85,
								cellRenderer: MultiValueCellRenderer, 
								cellRendererParams:	{
									screenId: this.props.screenId,
									panelId: this.props.panelId
								},
							},
							{
								headerName: 'QTY',
								field: 'qty',
								colId: 'qty',
								sortable: false,
								width: 75,
								minWidth: 75,
								cellRenderer: MultiValueCellRenderer, 
								cellRendererParams:	{
									screenId: this.props.screenId,
									panelId: this.props.panelId
								},
								type: 'numericColumn'
							},
							{
								headerName: 'Cost',
								field: 'cost',
								colId: 'cost',
								sortable: false,
								width: 80,
								minWidth: 80,
								cellRenderer: MultiValueCellRenderer, 
								cellRendererParams:	{
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									valueFormatter: sharedUtilites.formatCurrency
								},
								type: 'numericColumn'
							},
							{
								headerName: 'PO Total',
								field: 'total',
								colId: 'total',
								sortable: true,
								width: 100,
								minWidth: 100,
								type: 'numericColumn'
							},
							{ 
								headerName: '', 		
								field: 'view',			
								colId: 'view',
								cellRenderer: TextButtonCellRenderer, 
								cellRendererParams:
								{
									gridName,
									screenId: this.props.screenId,
									panelId: this.props.panelId,
									fieldId: 'viewPO',
									onClick: this.onViewPOClicked,
									fill: true,
									height: '20px',
									text:'View/Edit Queued PO',
									style: {marginTop: '3px'},
									variant: 'light'
								},
								width:150,
								minWidth:150
							},
							{ 
								headerName: '', 
								field: 'rowModel', 
								colId: 'rowModel',
								hide: true
							}
						]}
						store={store}
					/>
				</div>
				<Card.Footer>
					<Row noGutters={true}>
						<Col className={'pr-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnClose'}
								text={'Close'}
								variant={'light'}
								onClick={this.onCloseClicked}
								fill
								store={store}
							/>
						</Col>
						<Col className={'pr-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnDeletePOs'}
								text={'Remove from Queue'}
								variant={'light'}
								onClick={this.onDeleteClicked}
								fill
								store={store}
								disabled={this.props.disableDeleteAndCreateButtons}
							/>
						</Col>
						<Col>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnCreatePOs'}
								text={'Create POs'}
								onClick={this.onCreatePOsClicked}
								fill
								store={store}
								disabled={this.props.disableDeleteAndCreateButtons}
							/>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		);
	}
}

ViewEditCreatePOsDialog.propTypes = {
	parentScreenId: PropTypes.string.isRequired,
	screenId: PropTypes.string.isRequired
};

export default ViewEditCreatePOsDialog;