import PropTypes from 'prop-types';
import React from 'react';
import withID from '../../../higherordercomponents/WithID';
const RefreshIcon = ({fill, height, width, id}) => {
	const h = height ? height : '18px';
	const w = width ? width : '18px';
	const f = fill ? fill : '#111111';
	return (
		<svg 
			id={id}
			fill='none' 
			height={h} 
			viewBox={'0 0 ' + parseInt(w) + ' ' + parseInt(h)} 
			width={w} 
			xmlns='http://www.w3.org/2000/svg'>
			<path d="m4 4v5h.58152m15.35658 2c-.4921-3.94631-3.8585-7-7.9381-7-3.35738 0-6.23171 2.06817-7.41848 5m0 0h4.41848m11 11v-5h-.5815m0 0c-1.1868 2.9318-4.0611 5-7.4185 5-4.07962 0-7.44601-3.0537-7.93811-7m15.35661 2h-4.4185" 
				stroke={f}
				strokeLinecap='round' 
				strokeLinejoin='round' 
				strokeWidth='2'
			/>
		</svg>
	);
};
RefreshIcon.propTypes = {
	fill: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	id: PropTypes.string.isRequired
};
export default withID(RefreshIcon);