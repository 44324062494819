import PropTypes from 'prop-types';
import React, { Component } from 'react';
import store from '../../configureStore';
import OkCancelDialogContainer from './OkCancelDialogContainer';
import {
	DataGridContainer
} from '@rtg/shared-react-components';
class POsCreatedDialog extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	render() {
		return (
			<OkCancelDialogContainer
				id={'purchaseOrdersCreatedDialog'}
				screenId={ this.props.screenId + '_informationDialog'} 
				title={'Information'}
				handleOkClicked={this.props.handleOkClicked}
				okButtonText={this.props.okButtonText}
				style={{paddingLeft: '0px', paddingRight: '0px'}}>
				<DataGridContainer
					screenId={this.props.screenId}
					panelId={this.props.panelId}
					fieldId={this.props.fieldId}
					enableCellTextSelection={true}
					ensureDomOrder={true}
					height={'250px'}
					forwardRef={React.createRef()}
					defaultColDef={{resizable:true}}
					columnDefs={
						[
							{
								headerName: 'Purchase Order', 
								field: 'purchaseOrderNumber',
								colId: 'purchaseOrderNumber',
								sortable: true
							},
							{
								headerName: 'Status',
								field: 'status',
								colId: 'status',
								sortable: true
							},
							{
								headerName: 'Errors',
								field: 'errorMessage',
								colId: 'errorMessage',
								sortable: true
							}
						]
					}
					rowData={this.props.rowData}
					onGridReady={(params) => { params.api.sizeColumnsToFit(); }}
					store={store}
				/>
			</OkCancelDialogContainer>
		);
	}
}

POsCreatedDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	rowData: PropTypes.array.isRequired,
	handleOkClicked: PropTypes.func.isRequired,
	okButtonText: PropTypes.string.isRequired
};

export default POsCreatedDialog;