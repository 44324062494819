import { nodeRoutes } from '../constants';
export const getQueryString = (obj, nodeRoute) => {
	//.NET way of creating a queryString...arrays will result in the following: var1: [1,2,3] => ?var1=1&var1=2&var1=3
	let returnValue = '';
	if(!nodeRoute?.value || obj === undefined){
		return returnValue;
	}
	if(nodeRoute.value === nodeRoutes.IFRServiceNET.value){
		returnValue = Object.keys(obj).length > 0 
			? Object.keys(obj).map(key => {
				if(Array.isArray(obj[key])){
					let result = '';
					for(let i = 0; i < obj[key].length; i++){
						result += key + '=' + window.encodeURIComponent(obj[key][i]);
						if(obj[key][i + 1] !== undefined){
							result += '&';
						}
					}
					return result;
				}
				return key + '=' + window.encodeURIComponent(obj[key]);
			}).join('&') 
			: '';
	}
	else {
		//standard way of creating a queryString...arrays will result in the following: var1: [1,2,3] => ?var1=[1,2,3]
		returnValue = Object.keys(obj).length > 0 
			? Object.keys(obj).map(key => {
				return key + '=' + window.encodeURIComponent(obj[key]);
			}).join('&') 
			: '';
	}
	return returnValue;
};
