import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import RemoveSkuDialog from './RemoveSkuDialog';
import { nodeRoutes } from '../../../../constants';
import rpcAction from '../../../../actions/rpcAction';
export const PANEL_ID = 'removeSkuDialog';
class RemoveSkuDialogContainer extends Component {
	render() {
		return (
			<RemoveSkuDialog
				screenId={this.props.screenId}
				sku={this.props.sku}
				onRemovalOptionChanged={this.props.onRemovalOptionChanged}
				onLoad={this.props.onLoad}
				removeSkuClicked={this.props.removeSkuClicked}
			/>
		);
	}
}
const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => {
	return {   
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'removalOption'], 'onlyThisWarehouse');
		},
		onRemovalOptionChanged: (value) => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'removalOption'], value);
		},
		removeSkuClicked: () => {
			const onlyThisWarehouseSelected = storeUtilities.getValue([props.screenId, 'removalOption'], 'allDivisionWarehouses');
			const whs = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
			let skus = [];
			skus.push(props.sku);
			const args = {
				store: whs,
				removeFromAllStoresOfDivision: onlyThisWarehouseSelected === 'allDivisionWarehouses' ? true : false,
				skus: skus
			};
			dispatch(rpcAction({
				args: args, 
				nodeRoute: nodeRoutes.IFRServiceNET, 
				method: 'PATCH',
				endpoint: '/Clearance/Skus/Disable', 
				showLoadingMask: false,
				callback: () => {
					dispatch(rpcAction({
						args: { store: whs }, 
						nodeRoute: nodeRoutes.IFRServiceNET, 
						endpoint: '/Clearance/Skus',
						callback: (data) => {
							if(data && Array.isArray(data))
							{
								storeUtilities.updateValue(dispatch, [props.screenId, 'clearanceSkuData'], data);
							}
						}
					}));
				}
			}));
		}
	};
};

RemoveSkuDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	sku: PropTypes.string.isRequired,
	onRemovalOptionChanged: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(RemoveSkuDialogContainer);

export default connectedComponent;
