import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import * as gridUtilities from '../../../../utilities/gridUtilities';
import { ButtonContainer, withTooltip, withPopover } from '@rtg/shared-react-components';
import '../../../widgets/css/NumberTextBoxCell.css';
import store from '../../../../configureStore';
class AltSkuCellRenderer extends Component {
	constructor(props){
		super(props);
		this.id = gridUtilities.getCellId(props.node.rowIndex, props.column.colId, props.gridName, false);
	}
	onLoadSkuClicked(){
		if(this.props.onLoadSkuClicked){
			this.props.onLoadSkuClicked({
				gridApi: this.props.api, 
				node: this.props.node, 
				value: this.props.value, 
				colId: this.props.colDef.colId, 
				rowIndex: this.props.node.rowIndex
			});
		}
	}
	render() {
		const style = {};
		if(this.props.hide){
			style.display = 'none';
		}
		const component = (
			<Container fluid={true} className={'w-100 m-0 p-0 pt-1'} style={style}>
				<Row className={'w-100 m-0 p-0'} noGutters={true} style={{marginTop:'2px'}}>
					<Col style={{paddingRight:'3px'}}>
						<Form.Control 
							id={this.id}
							data-testid={this.id}
							type={'number'}
							className={'form-control'} 
							style={{display: (this.props.hide || false ? 'none': 'block'), width:'100%'}}
							value={this.props.value}
							onChange={()=>{}}
							isInvalid={this.props.isInvalid}
							disabled={this.props.disabled}
							onFocus={this.props.onFocus}
						/>
					</Col>
					<Col>
						<ButtonContainer screenId={this.props.screenId} 
							panelId={this.props.panelId}
							fieldId={this.props.fieldId}
							overrideId={this.id + 'LoadSKU_Button'}
							store={store}
							text={'Load SKU'}
							disabled={this.props.disabled || this.props.value.length !== 8}
							onClick={this.onLoadSkuClicked.bind(this)}
						/>
					</Col>
				</Row>
			</Container>);
		return component;
	}
}
AltSkuCellRenderer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	value: PropTypes.any,
	getIsHidden: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	gridName: PropTypes.string.isRequired,
	tooltips: PropTypes.arrayOf(PropTypes.string).isRequired,
	tooltipPlacement: PropTypes.string,
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	loadSkuButtonDisabled: PropTypes.any
};

export default withTooltip(withPopover(AltSkuCellRenderer));