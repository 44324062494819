import { CALL_RPC_ACTION } from '../actionTypes';

/**
 * function callRpcAction
 * @deprecated as of January 24, 2023. Please use rpcAction instead. callRpcAction.js will be deleted in roughly 6 months.
 * @constructor
 * @param {object} args - JSON object or Immutable.Map representing the arguments to be sent to the cfc
 * @param {object} nodeRoute - route in the node back-end (app.js in ServerJS). Might be the path of the cfc (examples: 'ifr' or 'components/calcProdPos/Controller') or the Service Mapping of a REST API (like rest/productPO)
 * @param {string} endpoint - path of the function to be called in the cfc 
 * @param {function} fnAfterDataReceived - callback after function executes
 * @param {boolean} showLoadingMask - indicates whether or not to show the loading mask while the call is being made
 * @param {string} method - GET, POST (PUT and PATCH not supported currently...maybe in the future we'll add these...if needed)
 * @param {boolean} hideLoadingMaskOnComplete - set to allows multiple successive data calls to prevent the screen darkening and lightening for every individiual data call
 * @param {function} fnOnCatch - allows for additional error handling, if needed
 * @param {function} fnOnFinally - allows for any cleanup that must occur whether or not the call succeeds
 * @param {object} headers - override headers if application/json is not what is needed
 * @param {function} customResponseHandler - as name suggests...overrides response handling logic in api.js
 * @param {boolean} requestInBody - indicates whether or not to send the request in the body of the request (as opposed to the URL)
 * @param {boolean} retryOnFailure - indicates whether or not to retry the call if it fails
*/
const callRpcAction = (
	args, 
	nodeRoute, 
	endpoint, 
	fnAfterDataReceived, 
	showLoadingMask = true, 
	method, 
	hideLoadingMaskOnComplete=true, 
	fnOnCatch=()=>{}, 
	fnOnFinally=()=>{},
	headers,
	customResponseHandler,
	requestInBody,
	retryOnFailure=true
) => {
	console.warn('%ccallRpcAction.js is deprecated. Please use rpcAction.js', 'color:orange');
	return {
		type: CALL_RPC_ACTION,
		nodeRoute,
		endpoint,
		args,
		fnAfterDataReceived,
		showLoadingMask,
		method,
		hideLoadingMaskOnComplete,
		fnOnCatch,
		fnOnFinally,
		headers,
		customResponseHandler,
		requestInBody,
		retryOnFailure
	};
};
export default callRpcAction;