import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as orderUtilities from './OrderProductScreenUtilities';
import SuggestedOrdersDetailsTab from './SuggestedOrdersDetailsTab';
class SuggestedOrdersDetailsTabContainer extends Component {
	render() {
		return (
			<SuggestedOrdersDetailsTab {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
	return { 
		rowData: orderUtilities.getSuggestedOrdersDetailsJsonRowData(orderLinesRowData)
	};

};
const mapDispatchToProps = (dispatch, props) => {
	return {
		getIsXCellHidden: (cellProps) => {
			switch(cellProps.colDef.colId){
				case 'DiscontinuedSKU':
				case 'InactiveSKU':
				case 'ToBeDropped':
					return cellProps?.node?.data ? cellProps.node.data[cellProps.colDef.colId] === 'N' : true;
				default: 
					return false;
			}
		},
		postSortRows: (params) => {
			const currentTab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
			const rowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
			if(currentTab === 'Orders' || rowData.length <= 0){
				return;
			}
			const orderedRowData = [];
			if(params?.nodes?.forEach){
				params.nodes.forEach((node) => {
					if(node?.data?.ORDERLINE?.SKU !== undefined){
						const row = rowData.find(x => x.ORDERLINE.SKU === node.data.ORDERLINE.SKU);
						if(row !== undefined){
							orderedRowData.push(row);
						}
					}
				});
			}
			const ordersGridRef = props.getOrdersGridRef();
			if(ordersGridRef?.api !== undefined){
				const ordersGridColumnState = ordersGridRef.api.getColumnState();
				ordersGridColumnState.forEach((column) => {
					column.sort = null;
				});
				ordersGridRef.api.applyColumnState({state: ordersGridColumnState, defaultState: { sort: null }});
			}
			window.setTimeout(() => {
				storeUtilities.updateValue(dispatch, [props.screenId, 'OrderLinesRowData'], orderedRowData.length > 0 ? orderedRowData : rowData);
			}, 500);
		}
	};
};
SuggestedOrdersDetailsTabContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	forwardRef: PropTypes.any,
	height: PropTypes.number,
	getIsXCellHidden: PropTypes.func,
	getOrdersGridRef: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersDetailsTabContainer);