import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../actions/closeDialogAction';
import OkCancelDialog from './OkCancelDialog';

class OkCancelDialogContainer extends Component {
	render() {
		return (
			<OkCancelDialog {...this.prop}>
				{this.props.children}
			</OkCancelDialog>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		handleOkClicked: () => {
			if(props.handleOkClicked){
				props.handleOkClicked();
			}
			dispatch(closeDialogAction());
		},
		handleCancelClicked: () => {
			if(props.handleCancelClicked){
				props.handleCancelClicked();
			}
			dispatch(closeDialogAction());
		}
	};
};

OkCancelDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	id: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.any,
	children: PropTypes.any,
	hasCancelButton: PropTypes.bool,
	okButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	minHeight: PropTypes.number,
	minWidth: PropTypes.number,
	paddingLeft: PropTypes.number,
	paddingRight: PropTypes.number,
	handleOkClicked: PropTypes.func,
	handleCancelClicked: PropTypes.func,
	okButtonDisabled: PropTypes.bool,
	cancelButtonDisabled: PropTypes.bool
};

const connectedComponent = connect(null, mapDispatchToProps)(OkCancelDialog);

export default connectedComponent;