import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NoAccessToScreenPanel from '../../widgets/NoAccessToScreenPanel';
import PurchaseOrderQueueGridPanelContainer from './PurchaseOrderQueueGridPanelContainer';
import {
	ButtonContainer,
	DropdownContainer,
	LabelContainer
} from '@rtg/shared-react-components';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import store from '../../../configureStore';
import RefreshButton from '../../formelements/RefreshButton';

class PurchaseOrderQueueScreen extends Component {
	constructor(props){
		super(props);
		this.gridRefs = [];
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getRowData = (data) => {
		const rowData = [];
		if(Array.isArray(data)){
			let rowNumber = 1;
			data.forEach(row => {
				if(row?.deliverTo?.toUpperCase === undefined){
					return;
				}
				const matches = rowData.filter(d => d.sku === row.sku);
				if(matches.length === 0){
					const initialModel = { 
						poQueueRowModels: {},
						rowNumber,
						sku: row.sku, 
						firstCost: row.firstCost,
						description: row.description, 
						color: row.color, 
						family: row.family, 
						fob: row.fob
					};
					const matchingPOQueueRowModels = Object.keys(initialModel.poQueueRowModels).filter(key => {
						return key === row.queueID;
					});
					if(matchingPOQueueRowModels.length === 0){
						initialModel.poQueueRowModels[row.queueID] = [row];
					}
					else {
						throw new Error('Too many matching poQueueRowModels. Something is wrong with the logic.');
					}
					rowData.push(initialModel);
					rowNumber++;
				}
				else if(matches.length === 1){
					const match = matches[0];
					const matchingPOQueueRowModels = Object.keys(match.poQueueRowModels).filter(key => {
						return key === row.queueID;
					});
					if(matchingPOQueueRowModels.length === 0){
						match.poQueueRowModels[row.queueID] = [row];
					}
					else if(matchingPOQueueRowModels.length === 1){
						match.poQueueRowModels.push(row);
					}
					else {
						throw new Error('Too many matching poQueueRowModels. Something is wrong with the logic.');
					}
				}
			});
		}
		return rowData;
	};
	reloadQueue = () => {
		if(this.props.reloadQueue){
			this.props.reloadQueue();
		}
	};
	viewEditCreatePOsClicked = () => {
		if(this.props.viewEditCreatePOsClicked){
			this.props.viewEditCreatePOsClicked();
		}
	};
	handleVendorChanged = (value) => {
		if(this.props.handleVendorChanged){
			this.props.handleVendorChanged(value);
		}
	};
	render() {
		if(this.props.user === undefined 
			|| this.props.user.hasAdminAccess === undefined
			|| this.props.user.hasBuyerAccess === undefined
			|| (!this.props.user.hasAdminAccess() && !this.props.user.hasBuyerAccess())){
			return <NoAccessToScreenPanel />;
		}
		const vendorData = this.props.selectedVendor && Array.isArray(this.props.purchaseOrderQueueData) 
			? this.props.purchaseOrderQueueData.filter(x => x.vendor.toUpperCase && x.vendor.toUpperCase() === this.props.selectedVendor)
			: [];
		const vendorDataByAlias = {};
		if(Array.isArray(vendorData)){
			for(let i = 0; i < vendorData.length; i++){
				const keys = Object.keys(vendorDataByAlias);
				const vendor = vendorData[i]['vendor']?.toUpperCase ? vendorData[i]['vendor'].toUpperCase() : undefined;
				const alias = vendorData[i]['alias']?.toUpperCase ? vendorData[i]['alias'].toUpperCase() : undefined;
				if(vendor === undefined){
					continue;
				}
				const key = alias; 
				if(!keys.includes(key)){
					vendorDataByAlias[key] = {
						vendor,
						data: [vendorData[i]],
						alias
					};
				}
				else {
					vendorDataByAlias[key]['data'].push(vendorData[i]);
				}
			}
		}
		const queueGridPanelsToRender = [];
		const entries = Object.entries(vendorDataByAlias);
		for(let j = 0; j < entries.length; j++){
			const vendor = entries[j][1]['vendor'];
			const rowData = this.getRowData(entries[j][1]['data']);
			const alias = entries[j][1]['alias'];
			const panelStyle = j === 0 ? {marginTop:'5px', marginBottom:'5px'} : {marginTop:'0px', marginBottom:'5px'};
			queueGridPanelsToRender.push(
				<PurchaseOrderQueueGridPanelContainer 
					screenId={this.props.screenId}
					panelId={'purchaseOrderQueueGridPanel_' + vendor + '_' + j}
					rowData={rowData}
					alias={alias}
					vendorCode={vendor}
					key={j}
					index={j}
					panelStyle={panelStyle}
					onCellClicked={(params) => this.props.onCellClicked(params, this.gridRefs)}
					getGridRef={(ref => this.gridRefs.push(ref))}
				/>);
			if(j !== (entries.length - 1)){
				queueGridPanelsToRender.push(<hr key={Math.random()}/>);
			}
		}
		return (
			<Card className={'h-100'}>
				<Card.Header>
					<Row>
						<Col>
							<h3 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Purchase Order Queue</h3>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'d-flex'}>
							<DropdownContainer 
								screenId={this.props.screenId} 
								fieldId={'vendor'}
								label={'Vendor'}
								options={this.props.vendors}
								onChange={this.handleVendorChanged}
								mainComponentBootstrapSize={9}
								store={store}
								overridePath={[this.props.screenId, 'vendor']}
								hasBlankOption
								disabled={this.props.vendors.length === 0}
							/>
							<div className={'d-flex justify-content-center align-items-center'} style={{position:'relative', top: '-10px', left: '5px'}}>
								<RefreshButton 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'refreshButton'}
									onClick={this.reloadQueue}
									height={23}
									width={23}
									tooltips={['Reload Queue']}
								/>
							</div>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							&nbsp;
						</Col>
						<Col xs={3} sm={3} md={3} lg={3} xl={3}>
							<div style={{position:'relative', top: '-5px'}}>
								<div className={'d-flex'}>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedPOCountLabel'}
										value={'Selected PO Count: '}
										style={{fontSize: '16px', width:'140px'}}
										store={store}
									/>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedPOCount'}
										overridePath={[this.props.screenId, 'selectedPOCount']}
										type={'number'}
										useStore={true}
										style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
										store={store}
									/>
								</div>
								<div className={'d-flex'}>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'totalPOCountLabel'}
										value={'Total PO Count: '}
										style={{fontSize: '16px', fontWeight: 'bold', width:'140px'}}
										store={store}
									/>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'totalPOCount'}
										overridePath={[this.props.screenId, 'totalPOCount']}
										type={'number'}
										useStore={true}
										style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
										store={store}
									/>
								</div>
							</div>
						</Col>
						<Col xs={3} sm={3} md={3} lg={3} xl={3}>
							<div style={{position:'relative', top: '-5px'}}>
								<div className={'d-flex'}>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedPOCostLabel'}
										value={'Selected PO Cost: '}
										style={{fontSize: '16px', width:'140px'}}
										store={store}
									/>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'selectedPOCost'}
										overridePath={[this.props.screenId, 'selectedPOCost']}
										type={'number'}
										useStore={true}
										style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
										valueFormatter={(v) => sharedUtilities.formatCurrency(v)}
										store={store}
									/>
								</div>
								<div className={'d-flex'}>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'totalPOCostLabel'}
										value={'Total PO Cost: '}
										style={{fontSize: '16px', fontWeight: 'bold', width:'140px'}}
										store={store}
									/>
									<LabelContainer
										screenId={this.props.screenId}
										panelId={this.props.panelId}
										fieldId={'totalPOCost'}
										overridePath={[this.props.screenId, 'totalPOCost']}
										type={'number'}
										useStore={true}
										style={{width: '100px', textAlign: 'right', fontSize:'16px'}}
										valueFormatter={(v) => sharedUtilities.formatCurrency(v)}
										store={store}
									/>
								</div>
							</div>
						</Col>
						<div className={'ml-auto mr-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								fieldId={'btnCreatePOs'}
								text={'View/Edit/Create POs'}
								onClick={this.viewEditCreatePOsClicked}
								disabled={this.props.noRowsSelected || this.props.disableViewCreateEditPOsButton}
								store={store}
							/>
						</div>
					</Row>
				</Card.Header>
				<div style={{overflowX: 'hidden', overflowY:'auto'}}>
					{queueGridPanelsToRender}
				</div>
			</Card>
		);
	}
}

PurchaseOrderQueueScreen.propTypes = {
	screenId: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	purchaseOrderQueueData: PropTypes.array,
	onLoad: PropTypes.func,
	onCellClicked: PropTypes.func
};

export default PurchaseOrderQueueScreen;