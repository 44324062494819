import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import EditPurchaseOrderInstructionsDialogContainer from '../../dialogs/EditPurchaseOrderInstructionsDialogContainer';
import * as orderUtilities from './OrderProductScreenUtilities';
import SuggestedPOGridPanel from './SuggestedPOGridPanel';
import * as constants from '../../../constants';
class SuggestedPOGridPanelContainer extends Component {
	render() {
		return (
			<SuggestedPOGridPanel {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	let instructions = storeUtilities.getValue([...props.path, 'instructions'], undefined);
	instructions = instructions?.trim && instructions.trim() !== '' ? instructions.trim() : undefined;
	const rowData = storeUtilities.getValue([...props.path, 'rowData'], []);
	const poCost = storeUtilities.getValue([...props.path, 'totalCost'], 0);
	const cubes = storeUtilities.getValue([...props.path, 'cubes'], 0);
	const deleted = storeUtilities.getValue([...props.path, 'deleted'], false);
	const fob = rowData[0] !== undefined ? rowData[0].ORDERLINE.FOB : '';
	const group = storeUtilities.getValue([...props.path, 'group'], undefined);

	const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah'], false);

	const groupPOsBy = storeUtilities.getValue([props.screenId, 'groupPOsBy'], undefined);
	const whsList = sharedUtilities.getWhsList(false);
	const deliverToOptions = whsIsSavannah === true ? [{name: 'Savannah', value: 'SV'}] : Array.isArray(whsList) 
	&& whsList
		.filter(whs => { 
			return Array.isArray(props.divisions) ? props.divisions.includes(whs.division) : false;
		})
		.sort(sharedUtilities.whsDropdownComparator)
		.map(whs => {
			return {name: whs.whsName, value: whs.whsId};
		});	
	const cubesOverLimit = storeUtilities.getValue([...props.path, 'cubesOverLimit'], false);
	const showOverAllocationWarning = storeUtilities.getValue([...props.path, 'overAllocatedWarning'], false);
	const useLoadFactor = storeUtilities.getValue([props.screenId, 'useLoadFactor'], false);
	const shipDate = storeUtilities.getValue([...props.path, 'shipDate'], undefined);
	const arrivalDate = storeUtilities.getValue([...props.path, 'arrivalDate'], undefined);
	return {
		instructions,
		poCost,
		cubes,
		rowData,
		fob,
		deliverToOptions,
		path: props.path,
		cubesOverLimit,
		deleted,
		group,
		groupPOsBy,
		showOverAllocationWarning,
		useLoadFactor,
		shipDate,
		arrivalDate
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			compareShipToAndSetAllocationWarning(dispatch, props);
		},
		getColumnValue: (node) => {
			switch(node.colDef.colId){
				case 'NEEDBYDATE':
					return moment(node.data.NEEDBYDATE, constants.cfQueryDateFormat).toDate();
				default:
					return (node.colDef.colId in node.data) ? node.data[node.colDef.colId] : undefined;
			}
		},
		getFormattedColumnValue: (node) => {
			switch(node.colDef.colId){
				case 'NEEDBYDATE':
					return moment(node.data.NEEDBYDATE, constants.cfQueryDateFormat).format('MM/DD/YYYY');
				default:
					return (node.colDef.colId in node.data) ? node.data[node.colDef.colId] : undefined;
			}
		},
		editInstructionsClicked: (poNumber) => {	
			dispatch(showDialogAction(
				<EditPurchaseOrderInstructionsDialogContainer 
					poNumber={poNumber} 
					screenId={props.screenId} 
					panelId={props.panelId}
					parentPath={props.path}
					onDialogClosed={()=>{
						dispatch(closeDialogAction());
					}}/>, 
				325, 
				500, 
				() => {
					dispatch(closeDialogAction());
				}));
		},
		formatCurrency:(value) => {
			return sharedUtilities.formatCurrency(value);
		},
		onQtyChange: (gridApi, changedRowNode, newValue) => {
			const qtyDiff = numberUtilities.getNumberOrDefault(orderUtilities.getRowQtyDiff(changedRowNode, newValue), 0);
			const useLoadFactor = storeUtilities.getValue([props.screenId, 'useLoadFactor'], false);
			const cubesDiff = qtyDiff * numberUtilities.getNumberOrDefault(useLoadFactor === true ? changedRowNode.data.ORDERLINE.LoadFactor : changedRowNode.data.ORDERLINE.Cube, 0);
			const oldCubesValue = numberUtilities.getNumberOrDefault(storeUtilities.getValue([...props.path, 'cubes'], 0));
			const cubes = oldCubesValue + cubesDiff;
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const cubesLimit = storeUtilities.getValue([props.screenId, 'poCube'], 0);
			const cubesOverLimit = usingCubes === true ? cubes > cubesLimit : false;
			orderUtilities.changeQty(dispatch, gridApi, changedRowNode, newValue, props.onSuggestedPosTotalCostChange, props.path, useLoadFactor);
			storeUtilities.updateValue(dispatch, [...props.path, 'cubesOverLimit'], cubesOverLimit === true);
			if(props.checkAllQtyCells){
				props.checkAllQtyCells();
			}
		},
		isQtyCellInvalid: (params) => {
			const qtySum = orderUtilities.getQtySum(params.api);
			const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([props.screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const qtyOverLimit = usingCubes === false ? qtySum > maxPO : false;
			const usePDMPieceCountInUse = storeUtilities.getValue([props.screenId, 'usePDMPieceCountInUse'], false);
			const pieceCount = usePDMPieceCountInUse === true ? params.data.ORDERLINE.PDMPieceCount : params.data.ORDERLINE.PieceCount;
			const notDivisibleByPieceCount = pieceCount > 0 && (params.data.QTY % pieceCount) !== 0;
			return qtyOverLimit || notDivisibleByPieceCount;
		},
		qtyCellTooltips: (params) => {
			const tooltips = [];
			const qtySum = orderUtilities.getQtySum(params.api);
			const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([props.screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const qtyOverLimit = usingCubes === false ? qtySum > maxPO : false;
			const usePDMPieceCountInUse = storeUtilities.getValue([props.screenId, 'usePDMPieceCountInUse'], false);
			const pieceCount = usePDMPieceCountInUse === true ? params.data.ORDERLINE.PDMPieceCount : params.data.ORDERLINE.PieceCount;
			if(pieceCount > 0 && (params.data.QTY % pieceCount) !== 0){
				tooltips.push('Value entered is not divisible by the Piece Count of ' + pieceCount);
			}
			if(qtyOverLimit === true) {
				tooltips.push('Sum of values in QTY column is over limit');
			}
			return tooltips;
		},
		deleteSuggestedPO: () => {
			const currentPOTotal = storeUtilities.getValue([...props.path, 'totalCost'], 0);
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost - currentPOTotal, true);
			storeUtilities.updateValue(dispatch, [...props.path, 'deleted'], true, true);
		},
		undoDeleteSuggestedPO: () => {
			const currentPOTotal = storeUtilities.getValue([...props.path, 'totalCost'], 0);
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost + currentPOTotal, true);
			storeUtilities.updateValue(dispatch, [...props.path, 'deleted'], false, true);
		},
		onShipDateChange: () => {
			compareShipToAndSetAllocationWarning(dispatch, props);
		}

	};
};

const compareShipToAndSetAllocationWarning = (dispatch, props) => {
	const showOverAllocationWarning = compareShipToAllocations(props);
	storeUtilities.updateValue(dispatch, [...props.path, 'overAllocatedWarning'], showOverAllocationWarning);
};

const compareShipToAllocations = (props) => {
	const shipDate = storeUtilities.getValue([...props.path, 'shipDate'], undefined);
	const shipMonday = getMonday(shipDate);
	const allocationsData = storeUtilities.getValue([props.screenId, 'AllocationsData'], []);
	const tempShipMonday = moment(shipMonday).format('YYYY-MM-DD');
	const allocationRow = allocationsData.find(x => x.week === tempShipMonday);
	return (allocationRow && allocationRow.shipments > allocationRow.allocation);
};

const getMonday = (d) => {
	return moment(d).startOf('week').add(1, 'day').toDate();
};

SuggestedPOGridPanelContainer.propTypes = {
	onLoad: PropTypes.func.isRequired,
	screenId: PropTypes.string.isRequired,
	poNumber: PropTypes.number,
	deleteSuggestedPO: PropTypes.func,
	editInstructionsClicked: PropTypes.func,
	instructions: PropTypes.string,
	datePickerPosition: PropTypes.string, //either 'top' or 'bottom'
	onSuggestedPosTotalCostChange: PropTypes.func,
	deliverToOptions: PropTypes.array,
	path: PropTypes.array,
	scrollContainerRef: PropTypes.any,
	deleted: PropTypes.bool,
	whsNum: PropTypes.string,
	gridRef: PropTypes.any,
	getColumnValue: PropTypes.func,
	formatColumnValue: PropTypes.func,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	group: PropTypes.string,
	groupPOsBy: PropTypes.string,
	checkAllQtyCells: PropTypes.func,
	onShipDateChange: PropTypes.func,
	useLoadFactor: PropTypes.bool
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOGridPanelContainer);

export default connectedComponent;