import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import SuggestedPOGridPanelContainer from './SuggestedPOGridPanelContainer';
import MenuButton from '../../formelements/MenuButton';
import withID from '../../../higherordercomponents/WithID';
import {
	ButtonContainer,
	DropdownContainer,
	LabelContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
const SUGGESTED_POS_PANEL_HEIGHT = '405px';
class SuggestedPOsPanel extends Component {
	constructor(props){
		super(props);
		this.gridRefs = {};
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}

	createPurchaseOrders = () => {
		if (this.props.createPurchaseOrders){
			this.props.createPurchaseOrders();
		}
	};
	queuePurchaseOrders = () => {
		if(this.props.queuePurchaseOrders){
			this.props.queuePurchaseOrders();
		}
	};
	previewInFlow = () => {
		if(this.props.previewInFlow){
			this.props.previewInFlow();  
		}
	};
	formatCurrency = (params) => {
		if (this.props.formatCurrency){
			return this.props.formatCurrency(params.value);
		}
		return params.value;
	};
	onOrderClicked = () => {
		if(this.props.onOrderClicked){
			this.props.onOrderClicked();
		}
	};
	getSuggestedPOsPanels = () => {
		const panelStyle = {
			height: this.props.height,
			overflowY:'auto', 
			overflowX:'hidden',
			position:'relative',
			top:'0px'};
		var rowsToRender = [];
		for(var rowIndex = 0; rowIndex < this.getRowCount(); rowIndex++){
			rowsToRender.push(this.rowRenderer(rowIndex));
		}
		return (<div
			style={panelStyle}>{rowsToRender}
		</div>);
	};
	rowRenderer = (rowIndex) => {
		switch (this.props.viewPort) {
			case 'xs':
				return this.createRow(2, rowIndex);
			case 'sm':
			case 'md':
			case 'lg':
				return this.createRow(3, rowIndex);
			case 'xl':
				return this.createRow(4, rowIndex);
			default:
				return this.createRow(1);
		}
	};
	createRow = (numberOfPOsInRow, rowIndex=0) => {
		const posToRender = [];
		/** determine the starting po number we need for the row*/
		const firstPONumberInRow = (numberOfPOsInRow * rowIndex) + 1;
		for (let i = 0; i < numberOfPOsInRow; i++) {
			const poNum = (firstPONumberInRow + i);
			const paddingAndMargin = 'p-0 mr-' + (i == (numberOfPOsInRow - 1) ? '1':'0') + ' ml-' + (i === 0 ? '0' : '1') + ' mt-0 mb-0';
			if(poNum <= this.props.poCount){
				posToRender.push(
					<Col key={i}
						className={paddingAndMargin + ' SuggestedPO-border'}>
						<SuggestedPOGridPanelContainer
							screenId={this.props.screenId}
							panelId={'suggestedPOGridPanel'}
							whsNum={this.props.whsNum}
							poNumber={poNum}
							key={poNum}
							path={[this.props.screenId, 'SuggestedPOs', 'purchaseOrders', this.props.whsNum, poNum]}
							onSuggestedPosTotalCostChange={this.onSuggestedPosTotalCostChange}
							scrollContainerRef={this.tabContentRef !== undefined ? this.tabContentRef.current : undefined}
							gridRef={(r) => this.handleRef(r, poNum)}
							divisions={this.props.divisions}
							checkAllQtyCells={this.checkAllQtyCells}
						/>
					</Col>);
			}
			else {
				posToRender.push(
					<Col className={paddingAndMargin + ' SuggestedPOFiller-border'} key={'rowKey_' + rowIndex + '_positionIndexInRow_' + i}>
						<div>
							&nbsp;
						</div>
					</Col>);
			}
		}
		return (
			<div className={'row'} style={{width: '100%', marginLeft: '1px', marginBottom: '2px' }} key={rowIndex}>
				{posToRender}
			</div>
		);
	};
	onSuggestedPosTotalCostChange = (costDiff) => {
		if(this.props.onSuggestedPosTotalCostChange){  
			this.props.onSuggestedPosTotalCostChange(costDiff);  
		}
	};
	checkAllQtyCells = () => {
		if(this.props.checkAllQtyCells){
			this.props.checkAllQtyCells(Object.values(this.gridRefs));
		}
	};
	getRowCount = () => {
		if(this.props.whsNum === undefined){
			return 0;
		}
		let rowCount = this.props.poCount;

		if(rowCount === 0){
			return 0;
		}
		switch(this.props.viewPort){
			case 'xs':
				rowCount /= 2;
				break;
			case 'sm':
			case 'md':
			case 'lg':
				rowCount /= 3;
				break;
			case 'xl': 
				rowCount /= 4;
				break;
			default: 
				rowCount /=4;
		}
		return rowCount;
	};
	handleRef = (gridRef, poNum) => {
		this.gridRefs[poNum] = gridRef;
	};
	render() {
		const poCount = this.props.poCount - this.props.deletedPOsCount;
		let poCountString = '';
		if(poCount > 0){
			poCountString = '(' + poCount + ')';
		}
		return (
			<Card style={{overflow:'hidden', minHeight:'150px'}} id={this.props.id} data-testid={this.props.id}>
				<Card.Header>
					<Row className={'p-0 m-0'} noGutters={true}>
						<Col xs={1} sm={1} md={1} lg={1} xl={1}>
							<h5 className={'text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'}>
									Suggested POs&nbsp;{poCountString}
							</h5>
							<div className={'pb-1 text text-muted'}>
									Total Cost: <span id={this.props.screenId+'_totalCost'}>{this.formatCurrency({value:this.props.totalCost})}</span>
							</div>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlPOFlag'}
								label={'Flag PO As'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'CNY', name: 'CNY' }, { value: 'MGR', name: 'MGR' }]}
								hasBlankOption
								store={store} 
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlTerms'}
								label={'Terms'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'WIRE', name: 'WIRE' }, { value: 'NET', name: 'NET' }]}
								showRequiredAsterisk
								validators={[validators.required]}
								hasBlankOption 
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-5 pt-2'}>
							<DropdownContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'ddlShipVia'}
								label={'Ship Via'}
								labelStyle={{textAlign:'right'}}
								disabled={this.props.disablePanel}
								options={[{ value: 'TRUCK', name: 'TRUCK' }, { value: 'OCEAN', name: 'OCEAN' }, { value: 'OTHER', name: 'OTHER' }]}
								showRequiredAsterisk
								validators={[validators.required]}
								hasBlankOption 
								store={store}
							/>
						</Col>
						<Col xs={1} sm={1} md={1} lg={1} xl={1} className={'pl-5'}>
									&nbsp;
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1 pt-2'}>
							<ButtonContainer
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'btnCreatePOs'}
								text={'Create POs'}
								disabled={this.props.disablePanel || this.props.disableBtnCreatePOs}
								onClick={this.createPurchaseOrders.bind(this)}
								fill 
								store={store}
							/>
						</Col>
						<Col xs={2} sm={2} md={2} lg={2} xl={2} className={'pl-1 pt-2'}>
							<MenuButton
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'MoreButton'}
								mainButtonText={'More'}
								buttons={[
									{key:'queue', text:'Queue POs', handler: this.queuePurchaseOrders.bind(this), disabled: this.props.disablePanel || this.props.disableBtnCreatePOs},
									{key:'preview', text:'Preview in Flow', handler: this.previewInFlow.bind(this), disabled: this.props.disablePanel || this.props.disableBtnPreviewInFlow},
									{key:'onorder', text: 'On Order', handler: this.onOrderClicked.bind(this)}
								]}
							/>
						</Col>
					</Row>
				</Card.Header>
				<div style={{height: SUGGESTED_POS_PANEL_HEIGHT, overflow:'auto', backgroundColor:'gainsboro'}}>
					{this.getSuggestedPOsPanels()}
				</div>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col xs={12} sm={12} md={3} lg={3} xl={3} style={{ textAlign: 'left' }}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'recipients'}
									useStore
									store={store}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'sendToFaxQueue'}
									label={'Send to BluVista Fax Queue'}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'reqConfirmation'}
									label={'Request Email Confirmation'}
									width={'155px'}
									mainComponentBootstrapSize={1}
								/>
							</Col>
							<Col xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'confirmationEmailAddress'}
									label={'Confirmation Email Address'}
									width={'200px'}
									showRequiredAsterisk
									validators={[validators.required, validators.isValidEmail]}
									store={store} 
								/>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		);
	}
}

SuggestedPOsPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	disablePanel: PropTypes.bool,
	rowData: PropTypes.array,
	createPurchaseOrders: PropTypes.func,
	queuePurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	disableBtnPreviewInFlow: PropTypes.bool,
	formatCurrency: PropTypes.func,
	totalCost: PropTypes.number,
	onLoad: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	datePickerPopupLeft: PropTypes.number,
	windowSize: PropTypes.object,
	onOrderClicked: PropTypes.func,
	totalPOsCount: PropTypes.number,
	deletedPOsCount: PropTypes.number
};
export default withID(SuggestedPOsPanel);