import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as numberUtilities from '../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import EditPurchaseOrderInstructionsDialogContainer from '../../dialogs/EditPurchaseOrderInstructionsDialogContainer';
import * as orderUtilities from './OrderPartScreenUtilities';
import SuggestedPOGridPanel from './SuggestedPOGridPanel';
class SuggestedPOGridPanelContainer extends Component {
	render() {
		return (
			<SuggestedPOGridPanel {...this.props}/>
		);
	}
}
const mapStateToProps = (state, props) => {
	let instructions = storeUtilities.getValue([...props.path, 'instructions'], undefined);
	instructions = instructions !== undefined && instructions.trim !== undefined ? instructions.trim() : undefined;
	const rowData = storeUtilities.getValue([...props.path, 'rowData' ], []);
	const poCost = storeUtilities.getValue([...props.path, 'totalCost' ], 0);
	const cubes = storeUtilities.getValue([...props.path, 'cubes' ], 0);
	const deleted = storeUtilities.getValue([...props.path, 'deleted'], false);
	const fob = '';
	const whsList = sharedUtilities.getWhsList(false);
	const deliverToOptions = Array.isArray(whsList) 
	&& whsList
		.filter(whs => { 
			return Array.isArray(props.divisions) ? props.divisions.includes(whs.division) : false;
		})
		.sort(sharedUtilities.whsDropdownComparator)
		.map(whs => {
			return {name: whs.whsName, value: whs.whsId};
		});	
	const path = props.path;	
	const cubesOverLimit = storeUtilities.getValue([...props.path, 'cubesOverLimit'], false);
	const shipDate = storeUtilities.getValue([...props.path, 'shipDate'], undefined);
	const arrivalDate = storeUtilities.getValue([...props.path, 'arrivalDate'], undefined);
	return {
		instructions,
		poCost,
		cubes,
		rowData,
		fob,
		deliverToOptions,
		path,
		cubesOverLimit,
		deleted,
		shipDate,
		arrivalDate
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const whsNum = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
			if(whsNum !== undefined){
				storeUtilities.initializeValue(dispatch, [...props.path, 'deliverTo'], whsNum);
			}
		},
		getColumnValue: (node) => {
			switch(node.colDef.field){
				case 'NEEDBYDATE':
					return moment(node.data.NEEDBYDATE, 'MM/DD/YYYY').format('L');
				default:
					return null;
			}
		},
		editInstructionsClicked: (poNumber) => {	
			dispatch(showDialogAction(
				<EditPurchaseOrderInstructionsDialogContainer 
					poNumber={poNumber} 
					screenId={props.screenId} 
					panelId={props.panelId}
					dialogId={'partsEditInstructionsDialog'}
					parentPath={props.path}
					path={props.path}
					onDialogClosed={()=>{
						dispatch(closeDialogAction());
					}}/>, 
				325, 
				500, 
				() => {
					dispatch(closeDialogAction());
				}));
		},
		formatCurrency:(value) => {
			return sharedUtilities.formatCurrency(value);
		},
		onQtyChange: (gridApi, changedRowNode, newValue) => {
			const qtyDiff = numberUtilities.getNumberOrDefault(orderUtilities.getRowQtyDiff(changedRowNode, newValue), 0);
			const cubesDiff = qtyDiff * numberUtilities.getNumberOrDefault(changedRowNode.data.CUBES, 0);
			const oldCubesValue = numberUtilities.getNumberOrDefault(storeUtilities.getValue([...props.path, 'cubes'], 0));
			const cubes = oldCubesValue + cubesDiff;
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const cubesLimit = storeUtilities.getValue([props.screenId, 'poCube'], 0);
			const cubesOverLimit = usingCubes === true ? cubes > cubesLimit : false;
			orderUtilities.changeQty(dispatch, gridApi, changedRowNode, newValue, props.onSuggestedPosTotalCostChange, props.path);
			storeUtilities.updateValue(dispatch, [...props.path, 'cubesOverLimit'], cubesOverLimit === true);
		},
		isQtyCellInvalid: (params) => {
			const qtySum = getQtySum(params.api);
			const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([props.screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const qtyOverLimit = usingCubes === false ? qtySum > maxPO : false;
			return qtyOverLimit;
		},
		qtyCellTooltips: (params) => {
			const qtySum = getQtySum(params.api);
			const maxPO = numberUtilities.getNumberOrDefault(storeUtilities.getValue([props.screenId, 'maxPO'], 0), 0, (x) => { return x >= 0; });
			const usingCubes = orderUtilities.useCubes(props.screenId);
			const qtyOverLimit = usingCubes === false ? qtySum > maxPO : false;
			return qtyOverLimit === true ? ['Sum of values in QTY column is over limit'] : [];
		},
		deleteSuggestedPO: () => {
			const currentPOTotal = storeUtilities.getValue([...props.path, 'totalCost'], 0);
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost - currentPOTotal, true);
			storeUtilities.updateValue(dispatch, [...props.path, 'deleted'], true, true);
		},
		undoDeleteSuggestedPO: () => {
			const currentPOTotal = storeUtilities.getValue([...props.path, 'totalCost'], 0);
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost + currentPOTotal, true);
			storeUtilities.updateValue(dispatch, [...props.path, 'deleted'], false, true);
		}
	};
};

const getQtySum = (gridApi) => {
	let qtySum = 0;
	gridApi.forEachNode(node => {
		qtySum += numberUtilities.getNumberOrDefault(node.data.QTY, 0, (x) => { return x >= 0; });
	});
	return qtySum;
};

SuggestedPOGridPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	poNumber: PropTypes.number,
	deleteSuggestedPO: PropTypes.func,
	editInstructionsClicked: PropTypes.func,
	instructions: PropTypes.string,
	datePickerPosition: PropTypes.string, //either 'top' or 'bottom'
	onSuggestedPosTotalCostChange: PropTypes.func,
	deliverToOptions: PropTypes.array,
	path: PropTypes.array,
	scrollContainerRef: PropTypes.any,
	onBeforeLoad: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOGridPanelContainer);

export default connectedComponent;