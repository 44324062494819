import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TextAreaContainer from '../formelements/TextAreaContainer';
import {
	ButtonContainer
} from '@rtg/shared-react-components';
import store from '../../configureStore';

class EditPurchaseOrderInstructionsDialog extends Component {
	constructor(props){
		super(props);
		this.getPath = this.getPath.bind(this);
	}
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
	}
	getPath(fieldKey){
		if(fieldKey){
			return [...this.props.path, fieldKey];
		}
		else{
			return this.props.path;
		}
	}
	handleCancelClicked(){
		if(this.props.handleCancelClicked)
			this.props.handleCancelClicked(() => {
				if(this.props.onDialogClosed){
					this.props.onDialogClosed(true);
				}
			});
	}
	handleOkClicked(){
		if(this.props.handleOkClicked){
			this.props.handleOkClicked(() => {
				if(this.props.onDialogClosed){
					this.props.onDialogClosed(false);
				}
			});
		}
	}
	render() {
		return (
			<Card className={'h-100'}>
				<Card.Header className={'dlgdraggable'}>
					<Row className={'mx-2'}><h4>{this.props.isQueuedPO ? this.props.title : ('PO #' + this.props.poNumber.toString())}</h4></Row>
				</Card.Header>
				<Container fluid={true} className={'h-100 px-1 py-0'}>
					<Row className={'mb-2'}>
						<Col>
							<TextAreaContainer 
								store={store}
								screenId={this.props.screenId}
								panelId={this.props.panelId}
								fieldId={'instructions'}
								overridePath={this.getPath('instructions')}
								maxHeight={'200px'}
								label={'Instructions'}
								mainComponentBootstrapSize={12}
							></TextAreaContainer>
						</Col>
					</Row>
				</Container>
				<Card.Footer>
					<Container fluid={true}>
						<Row noGutters={true}>
							<Col xs={6} sm={6} md={6} lg={6} xl={6}>&nbsp;</Col>
							<Col xs={6} sm={6} md={6} lg={6} xl={6}>
								<Row>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<ButtonContainer 
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId='poInstructionsCancelButton' 
											text={'Cancel'} 
											className={'btn-light'} 
											onClick={this.handleCancelClicked.bind(this)}
											fill 
											store={store}
										/>
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<ButtonContainer 
											screenId={this.props.screenId}
											panelId={this.props.panelId}
											fieldId='poInstructionsOKButton' 
											text={'OK'} 
											onClick={this.handleOkClicked.bind(this)}
											fill 
											store={store}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		);
	}
}
EditPurchaseOrderInstructionsDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleCancelClicked: PropTypes.func,
	handleOkClicked: PropTypes.func,
	poNumber: PropTypes.number.isRequired,
	onDialogClosed: PropTypes.func,
	path: PropTypes.array.isRequired,
	isQueuedPO: PropTypes.any,
	title: PropTypes.string,
	onLoad: PropTypes.func
};

export default EditPurchaseOrderInstructionsDialog;
